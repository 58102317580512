// ---------------------------------------------------------------------
// <copyright file=timestamp-converter.ts company=WALC Inc.
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import parseISO from 'date-fns/parseISO';

/**
 * iso形式のタイムスタンプをDate型に変換するメソッド
 * dateパイプだけだとisoの複数の形式には対応できないので、このメソッドを使用する。
 * dateパイプはnullやundefinedも許容するので、引数がnull or undefinedの時はnullを返す。
 * @param isoDate
 * @returns
 */
export function parseDate(isoDate: string | null | undefined): Date | null | undefined {
  // まず入力が正常か判断する。異常なものはnullに変換される
  const confirmedDate = safeDateFromISO(isoDate);
  // 異常系はnullを返す
  return confirmedDate;
}

/**
 * Dateに変換したいISO文字列が正常か確認し、正常の場合はDateオブジェクトにして返す、異常ならnullを返す。
 * parseDateを使わないHTMlもあるので、この形で利用する
 * @param date
 */
export function safeDateFromISO(date: string | null): Date | null {
  // dateが正常か判定するのはやや面倒
  // Dateクラスをうまく使う必要がある

  const dateObj = new Date(parseISO(date));
  return Number.isNaN(dateObj.getTime()) ? null : dateObj;
}

/**
 * Dateに変換したいparseISO済みの文字列が正常か確認し、正常の場合はDateオブジェクトにして返す、異常ならnullを返す。
 * parseDateを使わないHTMlもあるので、この形で利用する
 * @param date
 */
export function safeDateFromParsedStr(date: string | null): Date | null {
  // dateが正常か判定するのはやや面倒
  // Dateクラスをうまく使う必要がある

  const dateObj = new Date(date);
  return Number.isNaN(dateObj.getTime()) ? null : dateObj;
}
