// ---------------------------------------------------------------------
// <copyright file=summary-images-container.component.ts company=WALC Inc.
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, Input, OnInit } from '@angular/core';
import { ConditionSummaryImage } from 'src/app/model/formatted-summary-image-info';
import { RouteProcessService } from 'src/app/service/route-process.service';
import { notFoundImageSmURL } from 'src/app/static/image';

/**
 * 軸種(送り軸、主軸、回転軸)の画像リストを受け取り表示するコンテナコンポーネント。
 * AllAssetsDiganosisに配置する
 */
@Component({
  selector: 'app-summary-images-container',
  templateUrl: './summary-images-container.component.html',
  styleUrls: ['../../style/image-style.css', './summary-images-container.component.css'],
})
export class SummaryImagesContainerComponent implements OnInit {
  @Input() conditionSummaryImages: ConditionSummaryImage[];
  @Input() colorbarURL: string; // FormattedImageInfoElemetでもらってもいいが、URLしか使わない
  @Input() title: string;
  @Input() isLoading: boolean;

  constructor(private routeProcessService: RouteProcessService) {}

  ngOnInit(): void {}

  /**
   * 画面上で画像をクリックした際に実行されるメソッド
   * @param conditionSummaryImage 軸名と画像URLをまとめた変数
   */
  public selectImage(conditionSummaryImage: ConditionSummaryImage): void {
    this.routeProcessService.navigateSingleAssetDiagnosis(conditionSummaryImage.assetKey);
  }

  /**
   * 表示画像のCSSクラスを返すメソッド
   * not-foundかどうかで判断する。
   * @param URL スタイルを指定したい画像のURL
   * @returns CSSクラスのstring
   */
  public displayImageStyle(URL: string): string {
    if (URL === notFoundImageSmURL) {
      return 'img-not-found-sm';
    } else {
      return 'summary-img-found-100';
    }
  }
}
