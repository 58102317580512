// ---------------------------------------------------------------------
// <copyright file=|`"account.component.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Account } from 'src/app/model/account';
import { FloorService } from 'src/app/service/floor.service';
import { RouteProcessService } from 'src/app/service/route-process.service';

/**
 * 契約済みのアカウント(事業所)の一覧を表示するコンポーネント\
 * アカウント一覧は管理者しか見てはいけないので、account.guard.tsが適用されている
 */
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css', '../../style/account-plant-style.css'],
})
export class AccountComponent implements OnInit {
  // 画面に表示するaccountList
  public accountList: Account[] = undefined;

  // onDestroy時にsubscribe解除をするためのオブジェクト。subscribe前にpipe内で takeUntil(this.unsubscribe$) とすれば良い。
  private unsubscribe$ = new Subject();

  // ローディングバー表示用のフラグ。
  public isLoading: boolean = false; // 初期状態は非表示

  constructor(
    private floorService: FloorService,
    private routeProcessService: RouteProcessService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true; // ローディングバー表示

    // APIからアカウントリストを取得する
    this.floorService
      .fetchAccountList()
      .pipe(
        takeUntil(this.unsubscribe$), // onDestroy時に購読を解除するトリガー
      )
      .subscribe(
        (accountList: Account[]) => {
          this.accountList = accountList;
          this.isLoading = false; // ローディングバー非表示
        },
        (error) => {
          console.log(error);
          this.isLoading = false; // ローディングバー非表示
          this.routeProcessService.navigateError();
        },
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  /**
   * 画面でアカウントを選択した際に叩かれる、/plantへ遷移するメソッド
   * @param account 選択されたアカウント
   */
  public onClickSelectAccount(account: Account): void {
    this.routeProcessService.navigatePlantList(account);
  }
}
