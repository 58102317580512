// ---------------------------------------------------------------------
// <copyright file=|`"http.service.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Injectable } from '@angular/core';
// import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Observable, from } from 'rxjs';
// import { mergeMap } from 'rxjs/operators';
// import { Auth } from 'aws-amplify';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';

const CognitoIDPool = 'CognitoIdentityServiceProvider';

/**
 * APIとの通信をするクラス。
 * セッション情報はログイン時にcognitoによって自動でlocalStorageに保存されており、このクラスがそれを使用する。
 */
@Injectable({
  providedIn: 'root',
})
export class HttpServiceMock {
  // APIとの通信をするクラス。
  // セッション情報はlocalStorageから取り出す。あまりいじる部分はない。
  private expireToken: number;
  set ExpireToken(time: number) {
    this.expireToken = time;
  }
  private cognitoPool = environment.userPoolWebClientId;
  private apiUrl = environment.apiurl;
  // main:    https://api.kotanapp.com
  // dev:     https://api.dev.kotanapp.com
  // feature: https://api.feature.kotanapp.com
  // local:   http://localhost:3000

  get getNowTimeUNIX(): number {
    return Date.now() / 1000;
  }
  get userID(): string {
    return localStorage.getItem(`${CognitoIDPool}.${this.cognitoPool}.LastAuthUser`);
  }

  get idToken(): string {
    const key = `${CognitoIDPool}.${this.cognitoPool}.${this.userID}.idToken`;
    return localStorage.getItem(key);
  }

  get accessToken(): string {
    const key = `${CognitoIDPool}.${this.cognitoPool}.${this.userID}.accessToken`;
    return localStorage.getItem(key);
  }

  get usedSSO(): boolean {
    const key = `${CognitoIDPool}.${this.cognitoPool}.${this.userID}.amplify-signin-with-hostedUI`;
    return localStorage.getItem(key) == 'true';
  }

  // private httpGet<T>(idToken: string, path: string): Observable<T> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       Authorization: idToken,
  //     }),
  //   };
  //   const response = this.http.get<T>(this.apiUrl + path, httpOptions).pipe();
  //   return response;
  // }

  // // IDトークンをヘッダーにつけてAPIにPOSTする
  // private httpPost<T>(idToken: string, path: string, body: T): Observable<T> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       Authorization: idToken,
  //     }),
  //   };
  //   const response = this.http.post<T>(this.apiUrl + path, body, httpOptions);
  //   return response;
  // }
  public httpGetRequest<T>(path: string): Observable<T> {
    return of(null);
  }

  public httpPostRequest<T>(path: string, body: T): Observable<T> {
    return of(null);
  }

  constructor() {} // private http: HttpClient
}
