// ---------------------------------------------------------------------
// <copyright file="position-adjusted-anomaly-graph.component.ts" company="WALC Inc."
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Machine } from 'src/app/model/machine';
import { MachineService, HttpImageURL } from 'src/app/service/machine.service';
import { notFoundImageURL } from 'src/app/static/image';
import { tooltipImgPath } from './tooltip-props.json';
import { TranslateService } from '@ngx-translate/core';

/**
 * 指定した機械、軸、軸の位置(position)に対応する、異常度の時間変化グラフを表示するコンポーネント\
 * SingleAssetDiagnosisコンポーネント上に置かれる。
 */
@Component({
  selector: 'app-position-adjusted-anomaly-graph',
  templateUrl: './position-adjusted-anomaly-graph.component.html',
  styleUrls: ['../../style/image-style.css', './position-adjusted-anomaly-graph.component.css'],
})
export class PositionAdjustedAnomalyGraphComponent implements OnInit, OnChanges {
  @Input() selectedMachine: Machine;
  @Input() selectedAssetKey: string;
  @Input() selectedPosition: string;

  public positionLabel: string = 'Position'; // positionは軸によって time angleに変わる。可変なので注意。

  public displayURL: string = notFoundImageURL; // 表示する画像のURL
  public errorMessage: string = undefined;

  // Infoのtooltipの表示内容を読み込む
  public tooltipMessages: string[] = [];
  public tooltipImgPath: string = tooltipImgPath;

  // onDestroy時にsubscribe解除をするためのオブジェクト。subscribe前にpipe内で takeUntil(this.unsubscribe$) とすれば良い。
  private unsubscribe$ = new Subject();

  // ローディングスピナー表示用のフラグ
  public isLoading: boolean = true; // 初期状態で表示させ、取得できたら止める

  constructor(
    private machineService: MachineService,
    private translateService: TranslateService,
  ) {}

  /**
   * AllSampleAnomalyImageコンポーネント上でのpositionの変化を、SingleAssetDiganosisコンポーネント経由で検知し、グラフの取得を行う
   */
  ngOnChanges(): void {
    // 3項目のうち欠けているものがあったら処理をスキップ
    // 初期化時に順次読み込まれるので、欠けが発生する可能性がある
    if (this.selectedMachine && this.selectedAssetKey && (this.selectedPosition !== null || undefined)) {
      this.setImageURL(this.selectedMachine?.MachineID, this.selectedAssetKey, this.selectedPosition);
      // translateサービス経由でpositionLabelを取得、setする
      this.setPositionLabel(this.selectedAssetKey, this.selectedMachine);
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  /**
   * ポジションラベル(position, time, angle)を切り替える
   */
  private setPositionLabel(assetKey: string, machine: Machine): void {
    // translateサービスにアクセスするキーの作成
    const translateKey = this.machineService.getPositonKeyforTranslate(assetKey, machine);

    // ポジションラベルを更新する
    // 言語切り替え時は自動で更新される。machineやasssetKey変更時はonChange経由で叩かれる。
    this.translateService
      .stream(translateKey)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (positionLabel: string) => {
          this.positionLabel = positionLabel;
        },
        (error) => {
          console.log(error);
          this.errorMessage = error.message;
        },
      );
  }

  /**
   * 引数に対応する異常度の時間変化グラフを、APIから取得し、displayURLに格納するメソッド
   * @param machineID 機番
   * @param assetKey 軸名
   * @param position AllSampleAnomalyImageコンポーネントで選択されたポジション
   */
  private setImageURL(machineID: string, assetKey: string, position: string): void {
    this.isLoading = true; // ローディングスピナー表示

    this.machineService
      .fetchPositionAdjustedAnomalyGraph(machineID, assetKey, position)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: HttpImageURL) => {
          this.displayURL = response.URL || notFoundImageURL;
          // 正しく処理できたのでerrorMessageを削除する
          this.errorMessage = undefined;
          // ローディングスピナーを非表示に
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          // エラーメッセージを画面に表示するために格納
          this.errorMessage = error.message;
          this.displayURL = notFoundImageURL;
          // ローディングスピナーを非表示に
          this.isLoading = false;
        },
      );
  }

  /**
   * 表示画像のCSSクラスを返すメソッド
   * not-foundかどうかで判断する。
   * @returns CSSクラスのstring
   */
  public displayImageStyle(): string {
    if (this.displayURL === notFoundImageURL) {
      return 'img-not-found';
    } else {
      return 'img-found-100';
    }
  }
}
