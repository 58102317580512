// ---------------------------------------------------------------------
// <copyright file=admin-top.component.ts company=WALC Inc.
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit } from '@angular/core';
import { RouteProcessService } from 'src/app/service/route-process.service';

/**
 * 管理者のトップ画面
 */
@Component({
  selector: 'app-admin-top',
  templateUrl: './admin-top.component.html',
  styleUrls: ['./admin-top.component.css', '../../style/account-plant-style.css'],
})
export class AdminTopComponent implements OnInit {
  // roleガードがあるので、認可処理等は必要ない

  // 遷移先画面のpath : 画面に表示する文字列 のオブジェクト
  public routeMap = {
    account: '機械の状態画面',
    'machine-comment': 'コメント登録画面',
  };

  constructor(private routeProcessService: RouteProcessService) {}

  ngOnInit(): void {}

  /**
   * 選択したボタンに基づき、ルーティングするメソッド
   * @param path this.routeMapのkey
   */
  public navigate(path: string) {
    this.routeProcessService.navigateAdminSelectedPath(path);
  }
}
