// ---------------------------------------------------------------------
// <copyright file=timestamp-position-slidebar.component.ts company=WALC Inc.
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { parseDate } from 'src/app/model/timestamp-converter';
import { Machine } from 'src/app/model/machine';
import { MachineService, HttpTimePosition } from 'src/app/service/machine.service';
import { MatSelectChange } from '@angular/material/select';
import { MatSliderChange } from '@angular/material/slider';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-timestamp-position-slidebar',
  templateUrl: './timestamp-position-slidebar.component.html',
  styleUrls: ['./timestamp-position-slidebar.component.css'],
})
export class TimestampPositionSlidebarComponent implements OnInit {
  // onDestroy時にsubscribe解除をするためのオブジェクト。subscribe前にpipe内で takeUntil(this.unsubscribe$) とすれば良い。
  private unsubscribe$ = new Subject();

  public timestampList: string[] = undefined;
  public selectedTimestamp: string = undefined; // APIから取得するまで初期化を行うことができない
  public selectedPosition: number = 0;
  public positionStep: number = undefined; // 軸のデータ取得幅(単位は%)
  public positionList: number[] = undefined;
  public sliderLabel: string = 'Position'; // positionスライダーは軸によって time angleに変わる。

  // positionのinputに表示する値。
  // selectedPositionと同期し続けると、表示がこまめに変わりすぎて見づらいので分けて用意し、必要な時に同期する。
  public positionInput: number = 0;

  // TranslateServiceから取得する値
  public localeID: string = '';

  // SingleAssetDiagnosisコンポーネントから受け取るプロパティ
  @Input() selectedMachine: Machine;
  @Input() selectedAssetKey: string;

  // SingleAssetDiagnosisコンポーネントに送るプロパティ
  @Output() timestampEvent = new EventEmitter<string>();
  @Output() timestampListEvent = new EventEmitter<string[]>(); // all-sample-anomalyに送る必要がある
  @Output() positionEvent = new EventEmitter<number>();
  @Output() positionStepEvent = new EventEmitter<number>();
  @Output() positionListEvent = new EventEmitter<number[]>(); // all-sample-anomalyに送る必要がある

  // latestTimestampをこのコンポーネントからemitする必要が出てきた。本当はlatest-imageコンポーネントが取得すべき
  @Output() latestTimestampEvent = new EventEmitter<string>();

  constructor(
    public machineService: MachineService,
    private translateService: TranslateService,
  ) {}

  ngOnChanges(): void {
    // selctedMachineとselectedAssetKey両方ある時のみ、タイムスタンプとポジション幅を取得する
    if (this.selectedMachine && this.selectedAssetKey) {
      this.setTimestampListandPositionList(this.selectedMachine?.MachineID, this.selectedAssetKey);
      this.setSliderLabel(this.selectedAssetKey, this.selectedMachine);
    }
  }

  ngOnInit(): void {
    // localeIDを読み取る。タイムスタンプの表示制御に使う
    // stereamなので言語の変化に応じて自動で更新される
    this.translateService
      .stream('localeID')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (localeID: string) => {
          this.localeID = localeID;
        },
        // この分岐はユニットテスト範囲外
        (error) => {
          console.log(error);
        },
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  /**
   * スライダーにつけるポジションラベル(position, time, angle)を切り替える
   * @param assetKey
   * @param machine
   */
  private setSliderLabel(assetKey: string, machine: Machine): void {
    // translateサービスにアクセスするキーの作成
    const translateKey = this.machineService.getPositonKeyforTranslate(assetKey, machine);

    // ポジションラベルを更新する
    // 言語切り替え時は自動で更新される。machineやasssetKey変更時はonChange経由で叩かれる。
    this.translateService
      .stream(translateKey)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (positionLabel: string) => {
          this.sliderLabel = positionLabel;
        },
        // この分岐はユニットテスト範囲外
        (error) => {
          console.log(error);
        },
      );
  }

  /**
   * 引数に対応する診断時間のタイムスタンプリストとポジション幅をAPIから取得し格納、ポジションリストをポジション幅から作成し格納するメソッド\
   * selectedMachine.machineIDとselectedAssetKeyを引数に指定する
   * @param machineID 機番
   * @param assetKey 軸名
   */
  private setTimestampListandPositionList(machineID: string, assetKey: string): void {
    this.machineService
      .fetchTimestampListandPosition(machineID, assetKey)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: HttpTimePosition) => {
          this.timestampList = response.TimestampList;
          this.positionStep = response.PositionStep;
          this.positionList = this.generatePositionList(response.PositionStep);

          const latestTimestamp: string = this.timestampList ? this.timestampList[this.timestampList.length - 1] : '';
          this.selectedTimestamp = latestTimestamp; // 初期値をとりあえず最新で設定しておく

          // timestampListの末尾(=latestTimestamp)、timestampList, positionListを親コンポにemitする
          this.latestTimestampEvent.emit(latestTimestamp);
          this.timestampListEvent.emit(this.timestampList);
          this.positionStepEvent.emit(this.positionStep);
          this.positionListEvent.emit(this.positionList);
          this.positionEvent.emit(this.selectedPosition);
          this.timestampEvent.emit(this.selectedTimestamp);
        },
        // この分岐はユニットテスト範囲外
        (error) => {
          console.log(error);
        },
      );
  }

  /**
   * 選択された診断時刻(Timestamp)を親のSingleAssetDiagnosisコンポーネントにemitするメソッド
   * @param event テンプレートでTimestampを選択した際のイベント変数
   */
  public selectTimestamp(event: MatSelectChange): void {
    this.selectedTimestamp = event.value;
    this.timestampEvent.emit(event.value);
  }

  /**
   * 選択された軸の位置(position)を親のSingleAssetDiagnosisコンポーネントにemitするメソッド
   */
  public selectPosition(event: MatSliderChange): void {
    this.selectedPosition = event.value;
    this.positionInput = event.value;
    this.positionEvent.emit(this.selectedPosition);
  }

  /**
   * position選択をinputでした時のトリガー\
   */
  public inputPosition(position: string): void {
    // 空文字の場合はいまのpositionを使用する。
    let positionNum: number = position !== '' ? parseInt(position) : this.selectedPosition;

    // positionListにない値を入れた場合、一番近いものを取り出す。
    if (!this.positionList.includes(positionNum)) {
      positionNum = this.findNearestValue(positionNum, this.positionList);
    }

    // 格納はselectPositionで行う
    this.positionInput = positionNum;
    this.selectedPosition = positionNum;
    // 親へemit
    this.positionEvent.emit(this.selectedPosition);
  }

  /**
   * 引数の数値に最も近い値を、引数の配列から取り出す。
   * positionをinputから受け取った時、無効な値が入るのを防ぐために行う。
   * @param inputValue
   * @param numList
   * @returns
   */
  private findNearestValue(inputValue: number, numList: number[]): number {
    let nearest = numList[0];
    let diff = Math.abs(inputValue - nearest);

    for (let i = 1; i < numList.length; i++) {
      const currentDiff = Math.abs(inputValue - numList[i]);
      if (currentDiff < diff) {
        nearest = numList[i];
        diff = currentDiff;
      }
    }

    return nearest;
  }

  /**
   * APIから取得した軸のデータ取得幅(positionStep)をもとに、軸位置のリストを作成し返すメソッド
   * 数字が有効じゃない場合の処理を決める必要がある。
   * @param positionStep ポジション(画像のX軸)の幅(単位は%)
   * @returns 選択可能なポジションのリスト
   */
  private generatePositionList(positionStep: number): number[] {
    const positionList: number[] = [];
    for (let i = 0; i <= 100; i += positionStep) {
      positionList.push(i);
    }
    return positionList;
  }

  /**
   * APIから取得したstrのtimestamp(ISO標準形式)をDate型に変えるメソッド\
   * 表示用に変えるだけ。
   * @param timestamp strのタイムスタンプ
   * @returns Date型のタイムスタンプ
   */

  // パイプの機能を使うだけなので、テスト対象外とする
  public dateFormat(timestamp: string): Date {
    return parseDate(timestamp);
  }
}
