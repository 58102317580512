// ---------------------------------------------------------------------
// <copyright file=|`"floor.service.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Account } from 'src/app/model/account';
import { Plant } from 'src/app/model/plant';

/**
 * Accountに関するデータ、Plantに関するデータを管理するサービス
 */
@Injectable({
  providedIn: 'root',
})
export class FloorServiceMock {
  constructor() {}

  /**
   * アカウントリストAPIを叩き、整形したObservableを返すメソッド
   * @returns Account[]のObservable
   */
  public fetchAccountList(): Observable<Account[]> {
    const account: Account = {
      AccountName: 'DMGMORI',
      AccountID: '000001',
    };

    const account2: Account = {
      AccountName: 'hoge',
      AccountID: '000002',
    };

    return of([account, account2]);
  }

  /**
   * アカウントAPIを叩き、整形したObservableを返すメソッド
   * @returns AccountのObservable
   */
  public fetchAccount(): Observable<Account> {
    const account: Account = {
      AccountName: 'DMGMORI',
      AccountID: '000001',
    };

    return of(account);
  }

  /**
   * プラントリストAPIを叩き、整形したObservableを返すメソッド
   * @param accountID アカウントID。IDの権限管理はサーバ側で行う
   * @returns Plant[]のObservable
   */
  public fetchPlantList(accountID: string): Observable<Plant[]> {
    const plant: Plant = {
      PlantName: 'Hoge',
      PlantID: '001',
      AccountID: accountID,
    };

    const plant2: Plant = {
      PlantName: 'Hoge',
      PlantID: '001',
      AccountID: accountID,
    };

    return of([plant, plant2]);
  }
}
