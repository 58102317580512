// ---------------------------------------------------------------------
// <copyright file="all-assets-diagnosis.component.ts" company="WALC Inc."
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Machine } from 'src/app/model/machine';
import { AnomalyStatus } from 'src/app/model/anomaly-unit';
import { MachineService } from 'src/app/service/machine.service';
import { RouteProcessService } from 'src/app/service/route-process.service';
import { HttpImageInfoList, ImageInfo } from 'src/app/service/machine.service';
import { notFoundImageSmURL, notFoundImageURL } from 'src/app/static/image';
import { StorageService } from 'src/app/service/storage.service';
import { CommonProperties } from 'src/app/model/common-properties';
import { AllAssemblyUnitImages, ConditionSummaryImage } from 'src/app/model/formatted-summary-image-info';
import { MachineInfoParent } from 'src/app/model/machine-info-parent';
import { TranslateService } from '@ngx-translate/core';

/**
 * 選択した機械の全軸の過去の診断結果の画像を一覧で表示するコンポーネント
 */
@Component({
  selector: 'app-all-assets-diagnosis',
  templateUrl: './all-assets-diagnosis.component.html',
  styleUrls: ['../../style/image-style.css', './all-assets-diagnosis.component.css'],
})
export class AllAssetsDiagnosisComponent implements OnInit {
  // storageServiceから取得するプロパティ。初期化、子コンポーネントへ渡すデータのまとめに使う
  public commonProperties: CommonProperties = undefined;

  // 静的な値のオブジェクト。設定言語で取得値が変わる
  public staticProperties: any = undefined;

  // 初期化時にAPIから取得する(できれば使いまわしたい？)
  public machineList: Machine[] = undefined;

  // 画像用変数
  public allAssemblyUnitImages: AllAssemblyUnitImages = undefined; // 表示用に成形された、軸名と画像URLを格納した変数。このファイルの下部参照。
  public notFoundURL: string = notFoundImageURL;

  // 画面に表示するcolorbar画像保存用のオブジェクト
  public colorbar: ImageInfo = undefined;

  // 「軸構成と軸の動き」の画像を格納するオブジェクト
  public axisMotion: ImageInfo = undefined;

  // machine-info-headerに渡す親コンポーネント識別子
  public parent = MachineInfoParent.allAssets;

  // onDestroy時にsubscribe解除をするためのオブジェクト。subscribe前にpipe内で takeUntil(this.unsubscribe$) とすれば良い。
  private unsubscribe$ = new Subject();

  // ローディングスピナー表示用のフラグ
  public isLoading: boolean = false; // 初期状態は非表示

  constructor(
    private machineService: MachineService,
    private routeProcessService: RouteProcessService,
    private storageService: StorageService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true; // ローディングバー表示

    // storageServiceからcommonPropertiesを取得
    this.commonProperties = this.storageService.getCommonProperties();

    // storageServiceに存在しない値はnullとなる
    // account, plant, machine, assetのいずれかがnullだったら適切に画面遷移する
    if (
      !this.commonProperties.account ||
      !this.commonProperties.plant ||
      !this.commonProperties.machine ||
      !this.commonProperties.assetKey
    ) {
      this.routeProcessService.navigateError();
    }

    // machineListを取得するためのbody取得
    const httpBody = this.storageService.getBodyforMachineList(); // エラーハンドリングを決める

    // machineService経由で整形済みのmachineListを取得する
    this.machineService
      .fetchMachineList(httpBody)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (machineList: Machine[]) => {
          this.machineList = machineList;
        },
        (error) => {
          console.log(error);
          this.routeProcessService.navigatePlantList();
        },
      );

    // タイトルやポップアップなどの静的文字列を読み取る
    // stereamなので言語の変化に応じて自動で更新される
    // 型が概ね決まったら定義するといい
    // 画像取得をトリガーしたいのでtsに書いている
    this.translateService
      .stream('allAssetsDiagnosis')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (staticProperties: any) => {
          this.staticProperties = staticProperties;

          // 画像の取得はここで行う。
          // 言語切り替え時に毎回走らせたいのでこうした。
          // 初回も走るので問題ない。subscribeネストはまあしゃあないでしょう
          this.fetchImage(this.commonProperties.machine);
        },
        (error) => {
          console.log(error);
        },
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  /**
   * machine-info-headerのmachine-list-sidebarから、selectedMachineの変更を検知し、画面の切り替えを行う。
   * commonProperties.assetKeyとselectedMachineの組み合わせ次第では表示が不可能な場合があるため、
   * 「ルーティング」を行うか「フィールドの更新による画面の更新」を行うかの分岐が発生する。
   * それをroutingサービスがまとめて行い、ルーティングが実行されたかをこのコンポーネントが受け取り、後続の処理をする。
   * @param selectedMachine 選択された機械
   */
  public receiveMachine(selectedMachine: Machine): void {
    // routingの処理の段階でstorageへの保存が行われる。
    const routed: boolean = this.routeProcessService.navigateSameAssetKeyMachine(
      selectedMachine,
      this.commonProperties.assetKey,
      this.parent,
    );

    // routingされなかった場合はcommonPropertiesを書き換えることで画面が切り替わる。
    if (!routed) {
      this.commonProperties.machine = selectedMachine;
      // 画像APIを叩く
      this.fetchImage(selectedMachine);
    }
  }

  /**
   * machine-info-headerのanomaly-iconから、selectedAssetKeyの変更を検知し、格納するメソッド
   * @param selectedAssetKey 選択された軸名
   */
  public receiveAssetKey(selectedAssetKey: string): void {
    this.storageService.setAsset(selectedAssetKey);
    this.commonProperties.assetKey = selectedAssetKey;
  }

  /**
   * 画像の再取得をするメソッド
   */
  private fetchImage(machine: Machine): void {
    // fetch中は必ずisLoadingをtrueにする。ローディングスピナー表示はhtml側で判断する
    this.isLoading = true;
    // 画像の取得を行う
    this.machineService
      .fetchAllAssetsDiagnosisImages(machine.MachineID)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: HttpImageInfoList) => {
          // 取得したimageURLMapを表示用に成形する

          // memo:
          // 説明用画像表示に使うImageInfoオブジェクトは response.ImageInfoListのもの
          // 診断画像表示はそれをシャローコピーしたimageInfoListのものなので、互いに影響は受けていない

          // colorbarとaxisMotionの別処理を先にやる
          this.setExplanationImages(response.ImageInfoList);
          const imageInfoList: ImageInfo[] = this.excludeExplanationImages(response.ImageInfoList);

          this.allAssemblyUnitImages = this.formatImageMapforDiaplay(imageInfoList);

          this.isLoading = false; // ローディングバー非表示
        },
        (error) => {
          console.log(error);
          this.isLoading = false; // ローディングバー非表示
          this.allAssemblyUnitImages = null;
          this.colorbar = null;
        },
      );
  }

  /**
   * 説明用画像(colorbarやAxisMotion)を表示用プロパティに入れる
   * @param imageInfoList
   * @returns
   */
  private setExplanationImages(imageInfoList: ImageInfo[]): void {
    // forループし、説明用画像(colorbarとAxisMotion)の時は格納処理をする
    imageInfoList.forEach((imageInfo) => {
      switch (imageInfo.AssetKey) {
        case 'colorbar':
          this.colorbar = imageInfo;
          break;
        case 'AxisMotion':
          this.axisMotion = imageInfo;
          break;
        default:
          break;
      }
    });
  }

  /**
   * 説明用画像(colorbarなど)を削除したImageInfoListを返す
   * @param imageInfoList
   * @returns
   */
  private excludeExplanationImages(imageInfoList: ImageInfo[]): ImageInfo[] {
    // imageInfoListから説明用画像を削除し、返す
    const filteredImages: ImageInfo[] = imageInfoList.filter((imageInfo) => {
      return !(imageInfo.AssetKey === 'colorbar' || imageInfo.AssetKey === 'AxisMotion');
    });

    return filteredImages;
  }

  /**
   * APIから取得したimageInfoListを表示用に成形するメソッド\
   * 軸タイプごとに分類する処理を行う。
   * @param imageInfoList 「軸名, URL, 軸タイプをまとめたImageInfoクラスのインスタンス」のリスト
   * @returns formattedImageInfoクラスの変数。
   */
  private formatImageMapforDiaplay(imageInfoList: ImageInfo[]): AllAssemblyUnitImages {
    // レスポンス格納用のformattedImageInfoインスタンスを作成
    const response = new AllAssemblyUnitImages();

    // 引数のimageInfoListが空の場合処理しない
    if (!imageInfoList.length) return response;

    // imageInfoListでforEachし、AssetTypeごとにresponseに格納する
    imageInfoList.forEach((imageInfo) => {
      // まずURLがundefined, null, 空文字ならnotoFoundに書き換える
      imageInfo.URL = imageInfo.URL ? imageInfo.URL : notFoundImageURL;

      // まず、一つの画像をと付随データが入ったFormattedImageInfoElementの中身を作る
      const responseElement = new ConditionSummaryImage();
      responseElement.assetKey = imageInfo.AssetKey;
      responseElement.assemblyUnit = imageInfo.AssemblyUnit;
      responseElement.URL = imageInfo.URL ? imageInfo.URL : notFoundImageSmURL;
      responseElement.displayName = imageInfo.DisplayName;

      // cssスタイルに必要な情報を取得する。colorbarの時はこのスタイルを使わないが、デフォルトのスタイル文字列の格納をしている
      responseElement.colorStyle = this.colorStyle(imageInfo.AssetKey);

      // imageInfoのassetTypeによって、適切な格納先にassetKeyURLを入れる
      // 格納先はresponseオブジェクトが担う
      response.pushSummaryImageElement(responseElement);
    });

    return response;
  }

  private colorStyle(assetKey: string): string {
    // LatestStatusをAnomalyStatus型(enum)に変換しているが、これはMachineクラスに持たせるべき
    const status: AnomalyStatus = this.commonProperties.machine.Assets[assetKey]?.LatestStatus;

    // AnomalyStatusからcssスタイルに変換するMap
    const colorMap = new Map<AnomalyStatus, string>([
      [AnomalyStatus.normal, 'color-normal'],
      [AnomalyStatus.caution, 'color-caution'],
      [AnomalyStatus.alert, 'color-alert'],
    ]);

    // colorMapのキーがないときのdefault値
    const colorDefault: string = '';
    // スタイルを引き出す。
    const colorStyle = colorMap.has(status) ? (colorMap.get(status) as string) : colorDefault;
    return colorStyle;
  }
}
