// ---------------------------------------------------------------------
// <copyright file="user-info.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { adminRole } from '../static/admin-role';

export interface UserInfo {
  AccountID: string;
  UserID: string;
  Name: string;
  Role: string;
}

export class User implements UserInfo {
  public AccountID: string;
  public UserID: string;
  public Name: string;
  public Role: string;
  public isAdmin: boolean;
  public Provider: string;

  constructor() {
    this.AccountID = '';
    this.UserID = '';
    this.Name = '';
    this.Role = '';
    this.isAdmin = false;
    this.Provider = '';
  }

  /**
   * Auth.currentAuthenticatedUser.attributes を引数にUser情報を格納する。
   * @param userAttributes
   */
  public setUser(userAttributes: any) {
    this.AccountID = userAttributes['custom:accountID']; // これは絶対存在する
    this.Role = userAttributes['custom:role'] ? userAttributes['custom:role'] : ''; // ない場合はSSOと判断
    this.Name = userAttributes['name'] ? userAttributes['name'] : 'Guest User'; // SSOが複数になった場合は判定処理を追加
    this.UserID = userAttributes['email'] ? userAttributes['email'] : ''; // SSOの場合は存在しない
    this.isAdmin = adminRole.includes(userAttributes['custom:role']);

    // TODO: myDMGMORI連携に向け、Providerの格納と、accountIDのマッピングを行う

    // Providerの有無と値判定 -> 特定のProviderならaccountIDの変換処理 -> 格納
  }

  public clearUser(): void {
    this.AccountID = '';
    this.UserID = '';
    this.Name = '';
    this.Role = '';
    this.isAdmin = false;
    this.Provider = '';
  }
}
