<div class="d-flex flex-row justify-content-around align-items-center position-sticky w-100 custom-container">
  <!-- timestampのプルダウンとラベルの箱 -->
  <div class="ts-container d-flex flex-column justify-content-start align-items-start">
    <!-- timestampのラベル -->
    <span class="pulldown-label text-label">{{ 'timestamp' | translate }}</span>
    <!-- timestampのプルダウン -->
    <mat-form-field class="">
      <mat-select
        id="ts-select"
        class="panel"
        [(ngModel)]="selectedTimestamp"
        (selectionChange)="selectTimestamp($event)"
      >
        <!-- タイムスタンプは表示用に整形している -->
        <!-- javascriptの仕様で、dateFormat時にPCのタイムゾーンに変換されてしまう。dateパイプの第二引数で無理やり戻している -->
        <mat-option *ngFor="let time of timestampList" [value]="time">{{
          dateFormat(time) | date: 'medium' : 'UTC+' + time.slice(-4) : localeID
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="d-flex flex-column justify-content-start align-items-start position-bar-container">
    <div class="d-flex justify-content-start align-items-center">
      <span class="text-label">{{ sliderLabel }}:</span>
      <!-- <span class="text-position"> {{ selectedPosition }}%</span> -->
      <!-- <span class="text-label">%</span> -->

      <!-- positionのinput -->
      <input
        matInput
        #position
        class="no-spin position-input"
        type="number"
        name="positionInput"
        min="{{ positionList ? positionList[0] : 0 }}"
        max="{{ positionList ? positionList.slice(-1)[0] : 100 }}"
        step="{{ positionStep ? positionStep : 5 }}"
        [(ngModel)]="positionInput"
        (keyup.enter)="inputPosition(position.value)"
      />

      <span class="text-unit">%</span>
      <!-- ここまで実験用 -->
    </div>

    <!-- バー本体 -->
    <div class="position-bar">
      <span class="position-text-left">0%</span>
      <mat-slider
        [min]="positionList ? positionList[0] : 0"
        [max]="positionList ? positionList.slice(-1)[0] : 100"
        [step]="positionStep ? positionStep : 5"
        [(ngModel)]="selectedPosition"
        (change)="selectPosition($event)"
      ></mat-slider>
      <span class="position-text-right">100%</span>
    </div>
  </div>
</div>
