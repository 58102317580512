// ---------------------------------------------------------------------
// <copyright file="latest-anomaly-image.component.ts" company="WALC Inc."
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { parseDate } from 'src/app/model/timestamp-converter';
import { MachineService } from 'src/app/service/machine.service';
import { Machine } from 'src/app/model/machine';
import { HttpImageURL } from 'src/app/service/machine.service';
import { notFoundImageURL } from 'src/app/static/image';
import { tooltipImgPath } from './tooltip-props.json';
import { TranslateService } from '@ngx-translate/core';

/**
 * 指定した機械、軸の最新の異常度画像を表示するコンポーネント\
 * SingleAssetDiagnosisコンポーネント上に置かれる。
 */
@Component({
  selector: 'app-latest-anomaly-image',
  templateUrl: './latest-anomaly-image.component.html',
  styleUrls: ['../../style/image-style.css', './latest-anomaly-image.component.css'],
})
export class LatestAnomalyImageComponent implements OnInit {
  @Input() selectedMachine: Machine;
  @Input() selectedAssetKey: string;
  @Input() latestTimestamp: string; // ISO基本形式なので注意

  public displayURL: string = undefined; //表示する画像のURL
  public displayTimestamp: Date = undefined; // 画面表示用のタイムスタンプ
  public errorMessage: string = undefined;

  // TranslateServiceから取得する値たち
  public localeID: string = '';
  public tooltipMessages: string[] = [];
  public tooltipImgPath: string = tooltipImgPath;

  // onDestroy時にsubscribe解除をするためのオブジェクト。subscribe前にpipe内で takeUntil(this.unsubscribe$) とすれば良い。
  private unsubscribe$ = new Subject();

  // ローディングスピナー表示用のフラグ
  public isLoading: boolean = true; // 初期状態で表示させ、取得できたら止める

  constructor(
    private machineService: MachineService,
    private translateService: TranslateService,
  ) {}

  ngOnChanges(): void {
    // これは常に実行されて問題ない
    this.displayTimestamp = parseDate(this.latestTimestamp);
    // Machine、AssetKeyが変化しても画像取得は行う
    // 2つが揃っていたら実行する。初期化時に順次Inputされるため、揃わない瞬間がある
    if (this.selectedMachine && this.selectedAssetKey) {
      this.setImageURL(this.selectedMachine?.MachineID, this.selectedAssetKey);
    }
  }

  // コンポーネント初期化時点でも画像取得を行う(たぶんいらない)
  ngOnInit(): void {
    // localeIDをngx-tranlateから読み取る
    // stereamなので言語の変化に応じて自動で更新される
    this.translateService
      .stream('localeID')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (localeID: string) => {
          this.localeID = localeID;
        },
        (error) => {
          console.log(error);
        },
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  /**
   * 引数に対応する最新異常度画像を、APIから取得し、displayURLに格納するメソッド
   * @param machineID 機番
   * @param assetKey 軸名
   */
  private setImageURL(machineID: string, assetKey: string): void {
    this.isLoading = true; // ローディングスピナー表示

    this.machineService
      .fetchLatestImage(machineID, assetKey)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: HttpImageURL) => {
          this.displayURL = response.URL || notFoundImageURL;
          // 正常に処理できたのでerrorMessageを削除する
          this.errorMessage = undefined;
          // ローディングスピナーを非表示に
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          // エラーメッセージを画面に表示するために格納
          this.errorMessage = error.message;
          this.displayURL = notFoundImageURL;
          // ローディングスピナーを非表示に
          this.isLoading = false;
        },
      );
  }

  /**
   * 表示画像のCSSクラスを返すメソッド
   * not-foundかどうかで判断する。
   * @returns CSSクラスのstring
   */
  public displayImageStyle(): string {
    if (this.displayURL === notFoundImageURL) {
      return 'img-not-found';
    } else {
      return 'img-found-90';
    }
  }
}
