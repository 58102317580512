<div class="d-flex flex-row justify-content-center">
  <div class="d-flex flex-column justify-content-center margin-20">
    <!-- include の箱 -->
    <div class="d-flex flex-row justify-content-center align-items-center margin-select">
      <label class="label-size">Include</label>
      <input class="custom-form" [(ngModel)]="includeWord" placeholder="Word, No." />
    </div>

    <!-- exclude の箱 -->
    <div class="d-flex flex-row justify-content-center align-items-center margin-select">
      <label class="label-size">Exclude</label>
      <input class="custom-form" [(ngModel)]="excludeWord" placeholder="Word, No." />
    </div>
  </div>

  <!-- apply clearボタンの箱 -->
  <div class="d-flex flex-column justify-content-center">
    <button class="custom-btn" (click)="filter()">Apply</button>
    <button class="custom-btn" (click)="clear()">Clear</button>
  </div>
</div>

<!-- include, excludeしたword、Noはfilter領域の下に置く -->
<div class="d-flex flex-column justify-content-center padding-result">
  <div class="d-flex flex-row filterd-words-box margin-result">
    <div class="text-center result-text-size result-label-size">Included words:</div>
    <div class="text-center result-text-size">
      {{ includeFilter.keywords | json }}
    </div>
  </div>

  <div class="d-flex flex-row filterd-words-box margin-result">
    <div class="text-center result-text-size result-label-size">Excluded words:</div>
    <div class="text-center result-text-size">
      {{ excludeFilter.keywords | json }}
    </div>
  </div>
</div>
