<body>
  <!-- <app-toolbar></app-toolbar> -->

  <!-- 画面の幅制御のためコンテナに入れる -->
  <div class="main-content">
    <router-outlet></router-outlet>
  </div>
  <!-- <footer>
    <app-footer></app-footer>
  </footer> -->
</body>
