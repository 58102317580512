// ---------------------------------------------------------------------
// <copyright file=form-validator.ts company=WALC Inc.
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * formのvalidationをまとめる
 * クラスにするほどのものがないので、一旦関数を羅列する
 */

/**
 * formのpasswordとpassword(Re Input)が一致していないかを判定する関数。
 * @param controlName
 * @param matchingControlName
 * @returns
 */
export function passwordMatchValidator(controlName: string, matchingControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: any } | null => {
    const passwordControl = formGroup.get(controlName);
    const confirmPasswordControl = formGroup.get(matchingControlName);

    if (!passwordControl || !confirmPasswordControl) {
      return null;
    }

    if (passwordControl.value !== confirmPasswordControl.value) {
      return { passwordMismatch: true };
    }

    return null;
  };
}
