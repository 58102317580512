// ---------------------------------------------------------------------
// <copyright file="app.component.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * ルートコンポーネント
 * 初期化、リロード時の処理全般を担う。
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(private translate: TranslateService) {
    // 使用言語の設定
    translate.setDefaultLang('ja');
    // これはcognitoサービスを使って初期値を決めてもいい
    const lang: string = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ja';
    translate.use(lang);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
