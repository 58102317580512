// ---------------------------------------------------------------------
// <copyright file=submit-success.component.ts company=WALC Inc.
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit } from '@angular/core';
import { RouteProcessService } from 'src/app/service/route-process.service';

/**
 * resetPassword, changePasswordでパスワード変更のsubmitの成功した時に表示するコンポーネント
 */
@Component({
  selector: 'app-submit-success',
  templateUrl: './submit-success.component.html',
  styleUrls: ['./submit-success.component.css'],
})
export class SubmitSuccessComponent implements OnInit {
  constructor(private routeProcessService: RouteProcessService) {}

  ngOnInit(): void {}

  public onClickNavigateLogin(): void {
    this.routeProcessService.navigateLogin();
  }
}
