<app-floor-header
  class="position-relative"
  [selectedAccount]="commonProperties.account"
  [selectedPlant]="commonProperties.plant"
></app-floor-header>

<app-machine-info-header
  [machineList]="machineList"
  [selectedMachine]="commonProperties.machine"
  [selectedAssetKey]="commonProperties.assetKey"
  (machineEvent)="receiveMachine($event)"
  (assetKeyEvent)="receiveAssetKey($event)"
></app-machine-info-header>

<app-timestamp-position-slidebar
  class="margin-slidebar"
  [selectedMachine]="commonProperties.machine"
  [selectedAssetKey]="commonProperties.assetKey"
  (timestampEvent)="receiveTimestamp($event)"
  (timestampListEvent)="receiveTimestampList($event)"
  (positionEvent)="receivePosition($event)"
  (positionStepEvent)="receivePositionStep($event)"
  (positionListEvent)="receivePositionList($event)"
  (latestTimestampEvent)="receiveLatestTimestamp($event)"
>
</app-timestamp-position-slidebar>

<!-- <div class="d-flex flex-column justify-content-center align-items-center padding-component-container wide-d-align narrow-d-align"> -->
<div class="padding-component-container wide-d-align narrow-d-align">
  <!-- レスポンシブ化の配置の都合で最初2つとそれ以降でコンテナを分ける -->
  <div class="d-flex flex-column column-container">
    <app-latest-anomaly-image
      class="margin-component custom-row custom-child-component"
      [selectedMachine]="commonProperties.machine"
      [selectedAssetKey]="commonProperties.assetKey"
      [latestTimestamp]="latestTimestamp"
    >
    </app-latest-anomaly-image>

    <app-all-sample-anomaly-image
      class="margin-component custom-row custom-child-component"
      [selectedMachine]="commonProperties.machine"
      [selectedAssetKey]="commonProperties.assetKey"
      [selectedTimestamp]="selectedTimestamp"
      [selectedPosition]="selectedPosition"
      [positionStep]="positionStep"
      [timestampList]="timestampList"
      [positionList]="positionList"
    >
    </app-all-sample-anomaly-image>
  </div>

  <!-- レスポンシブ化の配置の都合で最初2つとそれ以降でコンテナを分ける -->
  <div class="d-flex flex-column column-container">
    <app-past-anomaly-image
      class="margin-component custom-row custom-child-component row-min-height-sm"
      [selectedMachine]="commonProperties.machine"
      [selectedAssetKey]="commonProperties.assetKey"
      [selectedTimestamp]="selectedTimestamp"
    >
    </app-past-anomaly-image>

    <app-position-adjusted-anomaly-graph
      class="margin-component custom-row custom-child-component row-min-height"
      [selectedMachine]="commonProperties.machine"
      [selectedAssetKey]="commonProperties.assetKey"
      [selectedPosition]="selectedPositionStr"
    >
    </app-position-adjusted-anomaly-graph>

    <app-raw-current-image
      class="margin-component custom-row custom-child-component row-min-height"
      [selectedMachine]="commonProperties.machine"
      [selectedAssetKey]="commonProperties.assetKey"
      [selectedTimestamp]="selectedTimestamp"
    >
    </app-raw-current-image>

    <app-frequency-characteristic-image
      class="margin-component custom-row custom-child-component row-min-height"
      [selectedMachine]="commonProperties.machine"
      [selectedAssetKey]="commonProperties.assetKey"
      [selectedTimestamp]="selectedTimestamp"
      [selectedPosition]="selectedPositionStr"
    >
    </app-frequency-characteristic-image>
  </div>
</div>
