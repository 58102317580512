<!-- bootstrap標準のスタイルがあまり必要ないので、独自のcssをつけているものが多い -->

<mat-toolbar class="toolbar" role="heading">
  <!-- 左端に配置する戻るボタン -->
  <button
    id="back-btn"
    mat-button
    class="d-flex justify-content-center align-items-center cursor-pointer"
    [disabled]="isDisabledPreviousButton()"
    [class.disabled]="isDisabledPreviousButton()"
    (click)="onClickPreviousPage()"
    *ngIf="cognito.loggedInStream | async"
  >
    <img class="btn-img" src="/assets/icon/toolbar_back.svg" height="60" />
  </button>

  <!-- 画面幅が1024px以上の時に表示するアカウント名と工場名 -->
  <!-- bootstrapでもいいが、直接メディアクエリ書いてもいい　 -->
  <div class="wide-display-none floor-container">
    <span class="floor-text text-margin-write" *ngIf="selectedAccount">{{ selectedAccount?.AccountName }}</span>
    <span class="floor-text text-margin-write" *ngIf="selectedPlant"> : </span>
    <span class="floor-text" *ngIf="selectedPlant">{{ selectedPlant?.PlantName }}</span>
  </div>

  <ng-container>
    <!-- 右端に配置するボタンたち -->
    <div class="d-flex flex-row ms-auto align-items-center h-100">
      <!-- ユーザ名 -->
      <ng-container *ngIf="cognito.loggedInStream | async">
        <span class="btn-common align-self-center name-text">{{ user?.Name }}</span>
      </ng-container>

      <!-- 言語切り替えボタン -->
      <ng-container>
        <app-language-button class="btn-common lang-btn"></app-language-button>
      </ng-container>

      <!-- アカウントボタン 管理者のみ表示 -->
      <ng-container *ngIf="canChangeAccount()">
        <button mat-button class="btn-common cursor-pointer narrow-hidden">
          <img class="btn-img" src="/assets/icon/toolbar_account.svg " height="60" (click)="onClickAccountList()" />
        </button>
      </ng-container>

      <!-- plant選択ボタンを押した時 hiddenしたい時があるのでng-containerを使用 -->
      <ng-container *ngIf="isVisiblePlantButton()">
        <button mat-button id="plant-btn" class="btn-common cursor-pointer narrow-hidden">
          <img class="btn-img" src="/assets/icon/toolbar_factory.svg" height="60" (click)="onClickplantList()" />
        </button>
      </ng-container>
      <!-- ここまでplant選択ボタン -->

      <!-- ログアウトボタン -->
      <ng-container *ngIf="cognito.loggedInStream | async">
        <button mat-button id="logout-btn" class="btn-common cursor-pointer">
          <img class="btn-img" src="/assets/icon/toolbar_logout.svg" height="50" (click)="onClickLogout()" />
        </button>
      </ng-container>
      <!-- ここまでログアウトボタン -->
    </div>
  </ng-container>
</mat-toolbar>

<!-- ローディングバー、ログアウト処理中に出す -->
<!-- 親からトリガーをもらうものではないので、ここに書いている。 -->
<app-progress-bar class="progress-bar" [isLoading]="nowSignOutProcess" *ngIf="nowSignOutProcess"></app-progress-bar>
