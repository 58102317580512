// ---------------------------------------------------------------------
// <copyright file="single-asset-diagnosis.component.ts" company="WALC Inc."
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonProperties } from 'src/app/model/common-properties';
import { Machine } from 'src/app/model/machine';
import { MachineInfoParent } from 'src/app/model/machine-info-parent';
import { MachineService } from 'src/app/service/machine.service';
import { RouteProcessService } from 'src/app/service/route-process.service';
import { StorageService } from 'src/app/service/storage.service';

/**
 * 選択した機械、選択した軸の診断結果詳細を表示するコンポーネント\
 * 5つの画像表示用の子コンポーネントを持つ
 */
@Component({
  selector: 'app-single-asset-diagnosis',
  templateUrl: './single-asset-diagnosis.component.html',
  styleUrls: ['./single-asset-diagnosis.component.css'],
})
export class SingleAssetDiagnosisComponent implements OnInit {
  // storageServiceから取得するプロパティ。初期化、子コンポーネントへ渡すデータのまとめに使う
  public commonProperties: CommonProperties = undefined;

  // 初期化時にAPIから取得する(できれば使いまわしたい？)
  public machineList: Machine[] = undefined;

  // 以下はtimestamp-position-slidebarからemitで受け取り、他の子コンポーネントへ渡す
  public selectedTimestamp: string = undefined;
  public latestTimestamp: string = undefined;
  public selectedPosition: number = undefined;
  public positionStep: number = undefined;
  public timestampList: string[] = undefined;
  public positionList: number[] = undefined;

  // position関係はstrで渡したいことも多いので保持する
  public selectedPositionStr: string;
  public positionListStr: string[];

  // machine-info-headerに渡す親コンポーネント識別子
  public parent = MachineInfoParent.singleAsset;
  // onDestroy時にsubscribe解除をするためのオブジェクト。subscribe前にpipe内で takeUntil(this.unsubscribe$) とすれば良い。
  private unsubscribe$ = new Subject();

  constructor(
    private machineService: MachineService,
    private storageService: StorageService,
    private routeProcessService: RouteProcessService,
  ) {}

  ngOnInit(): void {
    // storageServiceから初期化項目を取得する
    this.commonProperties = this.storageService.getCommonProperties();

    // storageServiceに存在しない値はnullとなる
    // account, plant, machine, assetのいずれかがnullだったら適切に画面遷移する
    if (
      !this.commonProperties.account ||
      !this.commonProperties.plant ||
      !this.commonProperties.machine ||
      !this.commonProperties.assetKey
    ) {
      this.routeProcessService.navigateError();
    }

    // machineListを取得するためのbody取得
    const httpBody = this.storageService.getBodyforMachineList(); // エラーハンドリングを決める

    // machineService経由で整形済みのmachineListを取得する
    this.machineService
      .fetchMachineList(httpBody)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (machineList: Machine[]) => {
          this.machineList = machineList;
        },
        (error) => {
          console.log(error);
          this.routeProcessService.navigatePlantList();
        },
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  /**
   * machine-info-headerのmachine-list-sidebarから、selectedMachineの変更を検知し、画面の切り替えを行う。
   * commonProperties.assetKeyとselectedMachineの組み合わせ次第では表示が不可能な場合があるため、
   * 「ルーティング」を行うか「フィールドの更新による画面の更新」を行うかの分岐が発生する。
   * それをroutingサービスがまとめて行い、ルーティングが実行されたかをこのコンポーネントが受け取り、後続の処理をする。
   * @param selectedMachine 選択された機械
   */
  public receiveMachine(selectedMachine: Machine): void {
    // routingの処理の段階でstorageへの保存が行われる。
    const routed: boolean = this.routeProcessService.navigateSameAssetKeyMachine(
      selectedMachine,
      this.commonProperties.assetKey,
      this.parent,
    );

    // routingされなかった場合はcommonPropertiesを書き換えることで画面が切り替わる。
    if (!routed) this.commonProperties.machine = selectedMachine;
  }

  /**
   * machine-info-headerのanomaly-iconから、selectedAssetKeyの変更を検知し、格納するメソッド
   * @param selectedAssetKey 選択された軸名
   */
  public receiveAssetKey(selectedAssetKey: string): void {
    this.storageService.setAsset(selectedAssetKey);
    this.commonProperties.assetKey = selectedAssetKey;
  }

  /**
   * 選択したTimeStampをtimestamp-position-slidebarコンポーネントから取得するメソッド。他の4つの子コンポーネントのngOnChangeが動く。
   * @param selectedTimestamp timestamp-position-slidebarコンポーネントで選択したタイムスタンプ
   */
  public receiveTimestamp(selectedTimestamp: string): void {
    this.selectedTimestamp = selectedTimestamp;
  }

  /**
   * TimeStampListをtimestamp-posititon-slidebarコンポーネントから取得するメソッド。他の4つの子コンポーネントのngOnChangeが動く。
   * @param timestampList timestamp-posititon-slidebarコンポーネントで選択したタイムスタンプ
   */
  public receiveTimestampList(timestampList: string[]): void {
    this.timestampList = timestampList;
  }

  /**
   * 選択したPositionをtimestamp-position-slidebarコンポーネントから取得するメソッド。他の4つの子コンポーネントのngOnChangeが動く。
   * @param selectedPosition timestamp-position-slidebarコンポーネントで選択したポジション
   */
  public receivePosition(selectedPosition: number): void {
    this.selectedPosition = selectedPosition;
    this.selectedPositionStr = selectedPosition.toString();
  }

  /**
   * positionStepをtimestamp-position-slidebarコンポーネントから取得するメソッド。他の4つの子コンポーネントのngOnChangeが動く。
   * @param positionStep timestamp-position-slidebarコンポーネントで選択したポジション
   */
  public receivePositionStep(positionStep: number): void {
    this.positionStep = positionStep;
  }

  /**
   * positionListをtimestamp-position-slidebarコンポーネントから取得するメソッド。他の4つの子コンポーネントのngOnChangeが動く。
   * @param positionList timestamp-position-slidebarコンポーネントで選択したポジション
   */
  public receivePositionList(positionList: number[]): void {
    this.positionList = positionList;
    this.positionListStr = positionList.map((position) => position.toString());
  }

  /**
   * 最新のTimeStampをtimestamp-position-toolbarコンポーネントから取得するメソッド。他の4つの子コンポーネントのngOnChangeが動く。
   * @param latestTimestamp timestamp-position-toolbarコンポーネントで選択したタイムスタンプ
   */
  public receiveLatestTimestamp(latestTimestamp: string): void {
    this.latestTimestamp = latestTimestamp;
  }
}
