<!-- toolbarとフロアヘッダをまとめる箱。ローディングバーの位置調整の関係で箱が必要 -->
<div class="position-sticky d-flex flex-column flex-nowrap">
  <!-- toolbar -->
  <app-toolbar [selectedAccount]="selectedAccount" [selectedPlant]="selectedPlant"> </app-toolbar>

  <!-- floor-header -->
  <div class="floor-header w-100">
    <mat-toolbar class="navbar position-relative d-flex flex-row flex-nowrap" role="heading">
      <img class="walc-logo" src="/assets/icon/logo.png" (click)="onClickMenu()" />
      <div class="d-flex flex-column">
        <span id="account-text" class="text-wrap">{{ selectedAccount?.AccountName }} : </span>
        <span id="plant-text" class="text-wrap">{{ selectedPlant?.PlantName }}</span>
      </div>

      <!-- 文字列の折り返し制御の関係で、階層がやや複雑になる -->
      <button id="user-btn" class="d-inline-block ms-auto user-btn" mat-button [matMenuTriggerFor]="userMenu">
        <div class="d-flex flex-row justify-content-center align-items-center">
          <div id="user-text" class="user-text text-wrap">
            {{ user?.Name }}
          </div>
          <mat-icon>account_circle</mat-icon>
        </div>
      </button>

      <!-- mat-menuのcssはstyle.cssで指定する。d-flexにしてあるので自由に内部をいじれる -->
      <mat-menu id="user-menu" class="border-radius-panel" #userMenu="matMenu">
        <!-- <div class="padding-mat-item text-white font-size-mat-item">アカウントID : {{ user?.accountID }}</div> -->
        <div class="padding-mat-item text-white font-size-mat-item">ユーザ名 : {{ user?.Name }}</div>
        <hr class="text-white" />
        <!-- この要素は中央配置したいのでdivで囲む -->
        <div class="d-flex justify-content-center">
          <button
            mat-menu-item
            id="logout-btn"
            class="d-flex justify-content-center align-items-center"
            (click)="onClickLogout()"
          >
            ログアウト
          </button>
        </div>
      </mat-menu>
    </mat-toolbar>
  </div>

  <!-- ローディングバー -->
  <!-- 親となるdivがstickyなので、そこを基準点にできる -->
  <app-progress-bar class="progress-bar" [isLoading]="isLoading" *ngIf="isLoading"></app-progress-bar>
</div>
