// ---------------------------------------------------------------------
// <copyright file=machine-info-header.component.ts company=WALC Inc.
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Machine } from 'src/app/model/machine';

/**
 * SingleAssetDiagnosisなどの詳細画面のヘッダコンポーネント。
 * anomaly-iconやsidebarと親コンポーネントの連携をするブリッジなので、あまり機能を持たない
 */
@Component({
  selector: 'app-machine-info-header',
  templateUrl: './machine-info-header.component.html',
  styleUrls: ['./machine-info-header.component.css'],
})
export class MachineInfoHeaderComponent implements OnInit {
  @Input() machineList: Machine[];
  @Input() selectedMachine: Machine;
  @Input() selectedAssetKey: string;

  // 親(機械詳細やalarmコンポーネント)にselectedMachineの変化を伝える。変化はmachine-list-sidebarからemitされる。
  @Output() machineEvent = new EventEmitter<Machine>();

  // 親(機械詳細やalarmコンポーネント)にassetKeyの変化を伝える。変化はanomaly-iconからemitされる。
  @Output() assetKeyEvent = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  public receiveMachine(machine: Machine): void {
    this.machineEvent.emit(machine);
  }

  public receiveAssetKey(assetKey: string): void {
    this.assetKeyEvent.emit(assetKey);
  }
}
