<app-toolbar class="toolbar-only"></app-toolbar>
<!-- ローディングバー、通信中に出す -->
<app-progress-bar class="progress-bar" [isLoading]="nowSubmit" *ngIf="nowSubmit"></app-progress-bar>

<div class="d-flex flex-column justify-content-center align-items-center login-margin">
  <ng-container *ngIf="username === undefined; else submitContent">
    <form
      class="d-flex flex-column justify-content-center align-items-center w-100"
      (ngSubmit)="onSubmitForgetPassword(emailForm.value)"
      [formGroup]="emailForm"
    >
      <div class="text-center custom-form-title">{{ 'resetPassword.title' | translate }}</div>

      <div class="form-group">
        <label class="custom-form-label" for="userName">{{ 'resetPassword.userName' | translate }}</label>
        <input
          class="form-control"
          type="text"
          name="userName"
          id="userName"
          formControlName="username"
          placeholder="{{ 'resetPassword.placeHolder' | translate }}"
        />
      </div>
      <button class="btn-block" type="submit" [class.disabled]="emailForm.invalid">
        {{ 'resetPassword.receiveCode' | translate }}
      </button>

      <div class="link-text">
        <span class="login" (click)="onClickLogin()">{{ 'resetPassword.returnToLogin' | translate }}</span>
      </div>
    </form>
  </ng-container>

  <ng-template #submitContent>
    <form
      class="d-flex flex-column justify-content-center align-items-center w-100"
      (ngSubmit)="onSubmitResetPassword(resetPasswordForm.value)"
      [formGroup]="resetPasswordForm"
    >
      <div class="text-center custom-form-title">{{ 'resetPassword.title' | translate }}</div>

      <div class="form-group">
        <label class="custom-form-label" for="code">{{ 'resetPassword.code' | translate }}</label>
        <input
          class="form-control"
          type="text"
          name="code"
          id="code"
          formControlName="code"
          autocomplete="new-password"
          placeholder="{{ 'resetPassword.placeHolder' | translate }}"
        />
      </div>
      <div class="form-group">
        <label class="custom-form-label" for="password">{{ 'resetPassword.newPassword' | translate }}</label>
        <input
          class="form-control"
          type="password"
          name="password"
          id="password"
          formControlName="password"
          autocomplete="new-password"
          placeholder="{{ 'resetPassword.placeHolder' | translate }}"
        />
      </div>
      <div class="form-group">
        <label class="custom-form-label" for="confirmPassword">{{ 'resetPassword.reNewPassword' | translate }}</label>
        <input
          class="form-control"
          type="password"
          name="confirmPassword"
          id="confirmPassword"
          formControlName="confirmPassword"
          autocomplete="new-password"
          placeholder="{{ 'resetPassword.placeHolder' | translate }}"
        />
        <!-- バリデーションアラート -->
        <!-- 表示領域は確保しておく -->
        <div class="val-alert-container">
          <p class="form-alert" *ngIf="isConfirmPasswordFilled() && resetPasswordForm.errors?.passwordMismatch">
            {{ 'resetPassword.passwordMissmatch' | translate }}
          </p>
        </div>
      </div>

      <!-- エラーメッセージがあれば表示する -->
      <div class="text-center alert" *ngIf="errorMessage">{{ errorMessage }}</div>

      <button class="btn-block" type="submit" [class.disabled]="resetPasswordForm.invalid">
        {{ 'resetPassword.resetPassword' | translate }}
      </button>
      <div class="link-text">
        <span class="login" (click)="onClickLogin()">{{ 'resetPassword.returnToLogin' | translate }}</span>
      </div>
    </form>
  </ng-template>
</div>
