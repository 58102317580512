// ---------------------------------------------------------------------
// <copyright file="machine-alarm.components.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Alarm, HttpAlarm } from '../../model/alarm';
import { KeywordFinder } from '../../model/keywordFinder';
import { Machine } from 'src/app/model/machine';
import { RouteProcessService } from 'src/app/service/route-process.service';
import { StorageService } from 'src/app/service/storage.service';
import { MachineService } from '../../service/machine.service';
import { CommonProperties } from 'src/app/model/common-properties';
import { MachineInfoParent } from 'src/app/model/machine-info-parent';

/**
 * 選択した機械のAlarmを表示するコンポーネント
 */
@Component({
  selector: 'app-machine-alarm',
  templateUrl: './machine-alarm.component.html',
  styleUrls: ['./machine-alarm.component.css'],
})
export class MachineAlarmComponent implements OnInit {
  // storageServiceから取得するプロパティ。初期化、子コンポーネントへ渡すデータのまとめに使う
  public commonProperties: CommonProperties = undefined;

  // 初期化時にAPIから取得する(できれば使いまわしたい？)
  public machineList: Machine[] = undefined;

  public machineAlarms: Alarm[] = undefined;
  public filteredAlarms: Alarm[] = undefined;
  public result: boolean = undefined;
  public filterWord: string = undefined;

  public year: string = undefined;
  public month: string = undefined;
  public readonly yearList: string[] = this.createYearList(2018); // 開始年の定義どうしよう。
  public readonly monthList: string[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

  private exMsgFilter = new KeywordFinder();
  private inMsgFilter = new KeywordFinder();
  private exNoFilter = new KeywordFinder();
  private inNoFilter = new KeywordFinder();

  // machine-info-headerに渡す親コンポーネント識別子
  public parent = MachineInfoParent.alarm;

  // onDestroy時にsubscribe解除をするためのオブジェクト。subscribe前にpipe内で takeUntil(this.unsubscribe$) とすれば良い。
  private unsubscribe$ = new Subject();

  constructor(
    private machineService: MachineService,
    private storageService: StorageService,
    private routeProcessService: RouteProcessService,
  ) {}

  ngOnInit(): void {
    // storageServiceから初期化項目を取得する
    this.commonProperties = this.storageService.getCommonProperties();

    // storageServiceに存在しない値はnullとなる
    // account, plant, machine, assetのいずれかがnullだったら適切に画面遷移する
    if (
      !this.commonProperties.account ||
      !this.commonProperties.plant ||
      !this.commonProperties.machine ||
      !this.commonProperties.assetKey
    ) {
      this.routeProcessService.navigateError();
    }

    // machineListを取得するためのbody取得
    const httpBody = this.storageService.getBodyforMachineList(); // エラーハンドリングを決める

    // machineService経由で整形済みのmachineListを取得する
    this.machineService
      .fetchMachineList(httpBody)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (machineList: Machine[]) => {
          this.machineList = machineList;
        },
        (error) => {
          console.log(error);
          this.routeProcessService.navigatePlantList();
        },
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  /**
   * ある年を起点とし、現在の年までのリストを作成するメソッド
   * @param startYear 起点となる年
   * @returns yearList [2019, 2020, 2021, 2022, 2023] など
   */
  private createYearList(startYear: number): string[] {
    // 現在の年を取得
    const currentYear = new Date().getFullYear();
    // 結果格納用リスト
    const yearList: string[] = [];
    // 引数の起点年から現在年までのリストを作成
    for (let year = startYear; year <= currentYear; year++) {
      yearList.push(year.toString());
    }
    return yearList;
  }

  /**
   * machine-info-headerのmachine-list-sidebarから、selectedMachineの変更を検知し、画面の切り替えを行う。
   * commonProperties.assetKeyとselectedMachineの組み合わせ次第では表示が不可能な場合があるため、
   * 「ルーティング」を行うか「フィールドの更新による画面の更新」を行うかの分岐が発生する。
   * それをroutingサービスがまとめて行い、ルーティングが実行されたかをこのコンポーネントが受け取り、後続の処理をする。
   * @param selectedMachine 選択された機械
   */
  public receiveMachine(selectedMachine: Machine): void {
    // routingの処理の段階でstorageへの保存が行われる。
    const routed: boolean = this.routeProcessService.navigateSameAssetKeyMachine(
      selectedMachine,
      this.commonProperties.assetKey,
      this.parent,
    );

    // routingされなかった場合はcommonPropertiesを書き換えることで画面が切り替わる。
    if (!routed) this.commonProperties.machine = selectedMachine;
  }

  /**
   * machine-info-headerのanomaly-iconから、selectedAssetKeyの変更を検知し、格納するメソッド
   * @param selectedAssetKey 選択された軸名
   */
  public receiveAssetKey(selectedAssetKey: string): void {
    this.storageService.setAsset(selectedAssetKey);
    this.commonProperties.assetKey = selectedAssetKey;
  }

  /**
   * 機番を元にAPIからアラームを取得するメソッド
   */
  public setupMachineAlarm(): void {
    if (this.year && this.month) {
      // 期間を指定する場合
      this.machineService
        .fetchAlarm(this.commonProperties.machine.MachineID, this.year, this.month)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response: HttpAlarm) => {
            this.machineAlarms = response.Alarms;
            this.filteredAlarms = response.Alarms;
            this.result = response.Result;
          },
          (error) => {
            console.log(error);
          },
        );
    } else {
      this.machineService
        .fetchAlarm(this.commonProperties.machine.MachineID)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response: HttpAlarm) => {
            this.machineAlarms = response.Alarms;
            this.filteredAlarms = response.Alarms;
            this.result = response.Result;
          },
          (error) => {
            console.log(error);
          },
        );
    }
  }

  /**
   * フィルターするキーワードを受け取るメソッド
   * @param eventData HTMLから受け取るイベントデータ
   */
  public receiveFilter(eventData: [KeywordFinder, KeywordFinder]): void {
    this.inNoFilter = eventData[0];
    this.exNoFilter = eventData[1];
    this.applyfilter();
  }

  /**
   * filterを適用するメソッド
   */
  private applyfilter(): void {
    if (this.inNoFilter.keywords.length !== 0) {
      // includefilterの有無で条件分け
      if (this.inMsgFilter.keywords.length !== 0) {
        this.filteredAlarms = this.machineAlarms.filter(
          (Alarm) =>
            this.inNoFilter.findKeywords(Alarm.AlarmNo) &&
            !this.exNoFilter.findKeywords(Alarm.AlarmNo) &&
            this.inMsgFilter.findKeywords(Alarm.AlarmMessage) &&
            !this.exMsgFilter.findKeywords(Alarm.AlarmMessage),
        );
      } else {
        this.filteredAlarms = this.machineAlarms.filter(
          (Alarm) =>
            this.inNoFilter.findKeywords(Alarm.AlarmNo) &&
            !this.exNoFilter.findKeywords(Alarm.AlarmNo) &&
            !this.exMsgFilter.findKeywords(Alarm.AlarmMessage),
        );
      }
    } else {
      if (this.inMsgFilter.keywords.length !== 0) {
        this.filteredAlarms = this.machineAlarms.filter(
          (Alarm) =>
            !this.exNoFilter.findKeywords(Alarm.AlarmNo) &&
            this.inMsgFilter.findKeywords(Alarm.AlarmMessage) &&
            !this.exMsgFilter.findKeywords(Alarm.AlarmMessage),
        );
      } else {
        this.filteredAlarms = this.machineAlarms.filter(
          (Alarm) => !this.exNoFilter.findKeywords(Alarm.AlarmNo) && !this.exMsgFilter.findKeywords(Alarm.AlarmMessage),
        );
      }
    }
  }

  /**
   * Noでソートするメソッド
   * @param isDescend 降順かどうか
   */
  public sortNo(isDescend: boolean): void {
    if (isDescend) {
      // tureのとき降順
      this.machineAlarms = this.machineAlarms.sort(this.sortByNo);
      this.filteredAlarms = this.filteredAlarms.sort(this.sortByNo);
    } else {
      this.machineAlarms = this.machineAlarms.sort(this.sortByNo).reverse();
      this.filteredAlarms = this.filteredAlarms.sort(this.sortByNo).reverse();
    }
  }

  /**
   * 時間でソートするメソッド
   * @param isDescend 降順かどうか
   */
  public sortdate(isDescend: boolean): void {
    if (isDescend) {
      // tureのとき降順
      this.machineAlarms = this.machineAlarms.sort(this.sortBydate);
      this.filteredAlarms = this.filteredAlarms.sort(this.sortBydate);
    } else {
      this.machineAlarms = this.machineAlarms.sort(this.sortBydate).reverse();
      this.filteredAlarms = this.filteredAlarms.sort(this.sortBydate).reverse();
    }
  }

  /**
   * Noソートする際の内部処理
   * 何をしているかわかっていないので、要確認
   */
  private sortByNo = (a: Alarm, b: Alarm) => {
    const x = a.AlarmNo;
    const y = b.AlarmNo;
    return x < y ? 1 : -1;
  };

  /**
   * 時間でソートする際の内部処理
   * 何をしているかわかっていないので、要確認
   */
  private sortBydate = (a: Alarm, b: Alarm) => {
    const x = a.Time;
    const y = b.Time;
    return x < y ? 1 : -1;
  };
}
