<ul class="pagination align text-size">
  <button class="page-link button text-size" [disabled]="previousPageStyle()" (click)="previousPage()">&lt;</button>

  <button [class]="beginPage()" (click)="pager(1)">1</button>

  <div [class]="beginPage()">...</div>

  <button [class]="selectedPage(page - 1)" (click)="previousPage()">
    {{ page - 1 }}
  </button>

  <button [class]="selectedPage(page)">{{ page }}</button>

  <button [class]="selectedPage(page + 1)" (click)="nextPage()">
    {{ page + 1 }}
  </button>

  <div [class]="endPage()">...</div>

  <button [class]="endPage()" (click)="pager(maxPage())">
    {{ maxPage() }}
  </button>

  <button class="page-link button text-size" [disabled]="nextPageStyle()" (click)="nextPage()">&gt;</button>
</ul>
