// ---------------------------------------------------------------------
// <copyright file="anomaly-icon.component.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RouteProcessService } from 'src/app/service/route-process.service';
import { Machine } from '../../model/machine';
import { AnomalyUnit, AssetScore, AnomalyStatus } from '../../model/anomaly-unit';

/**
 * 軸名を格納したアイコンのコンポーネント\
 * 機械一覧画面, 診断詳細画面で表示する。
 * アイコンに必要なmachine, status, selectedAssetKey, modeは親コンポーネントからInputで取得する必要がある。
 */
@Component({
  selector: 'app-anomaly-icon',
  templateUrl: './anomaly-icon.component.html',
  styleUrls: ['./anomaly-icon.component.css'],
})
export class AnomalyIconComponent implements OnInit {
  private _machine: Machine = undefined; // Inputで取得したmachineを格納するプライベート変数。
  public scores: AnomalyUnit = undefined; //  軸名で分類されたアイコン情報

  // Machine型の値を格納し、その中に含まれるAssetsからAnomalyUnitを作成するsetter
  @Input()
  set machine(machine: Machine) {
    this._machine = machine;
    // machineの中のstatusが存在する場合、this.scoresに格納
    if (machine?.Assets) {
      this.scores = new AnomalyUnit(machine.Assets);
    }
  }

  // _machineへのアクセス用のgetter
  get machine(): Machine {
    return this._machine;
  }

  // 親コンポーネントの種類(MachineListなら"list"、MachineInfoなら"info")
  @Input() mode: string;

  // 選択された軸名。mode=infoの時のみ渡される
  @Input() selectedAssetKey: string;

  // 親(この場合machineInfoコンポーネント)にasetKeyの変化を伝える
  @Output() assetKeyEvent = new EventEmitter<string>();

  constructor(private routeProcessService: RouteProcessService) {}

  ngOnInit(): void {}

  /**
   * アイコンクリック時にルーティングをするメソッド\
   * machineServiceのselectedMachine, selectedAssetKey(共に親コンポーネントが購読している)を更新し、適切なコンポーネントへルーティングする\
   * modeによって挙動が変わる
   * @param assetScore 軸名とステータスをまとめた変数
   */
  public clickAsset(assetScore: AssetScore): void {
    if (assetScore.assetKey === this.selectedAssetKey) {
      return;
    }

    // routing時にmachineServiceのselectedMachineをいちいち更新すると、毎回OnInitが走る。
    // 現状、問題ないが、ゆくゆくは処理の重さの原因になるかも
    this.routeMachineInfoChildren(assetScore.assetKey, this.machine);
  }

  /**
   * ルーティング処理の実態メソッド
   * @param assetKey AssetScoreクラス内のasset名を表す文字列. SP1, X1_1, Alarm, Allなど
   */
  private routeMachineInfoChildren(assetKey: string, machine: Machine): void {
    // 親(single-asset, all-assets, alarmなど)のassetKeyも変化させる
    this.assetKeyEvent.emit(assetKey);
    // 先にルーティングする。画面が変わる場合はroutingProcessServiceがstorageも書き換える
    switch (assetKey) {
      case 'All':
        this.routeProcessService.navigateAllAssetDiagnosis(machine);
        break;
      case 'Alarm':
        this.routeProcessService.navigateMachineAlarm(machine);
        break;
      default:
        // 注意、singleasset->singleassetの遷移時はngOnInitは叩かれず、属性の更新だけ行われる。
        this.routeProcessService.navigateSingleAssetDiagnosis(assetKey, machine);
        break;
    }
  }

  ////////////////////// 以降はアイコンのスタイルを決めるためのメソッド /////////////////////

  /**
   * 最外のコンテナのスタイルのclassを返すメソッド
   * @returns 中央揃えか左揃えかを表す文字列。Inputのmodeの値で変わる。
   */
  public styleContainer(): string {
    // modeをcssスタイルに変換するマッピング用のオブジェクトリテラル
    const containerStyleMap = {
      list: 'd-flex flex-row justify-content-start cstm-box',
      info: 'd-flex flex-row justify-content-center',
    };

    // this.modeに合わせ、中央配置か左配置かを決める
    const containerStyle = containerStyleMap[this.mode];
    return containerStyle;
  }

  /**
   * assemblyUnitの全体の箱のスタイルを返す
   * @returns
   */
  public styleUnit(): string {
    // modeをcssスタイルに変換するマッピング用のオブジェクトリテラル
    const unitTitleStyleMap = {
      list: 'd-flex flex-column unit-sm',
      info: 'd-flex flex-column unit-lr',
    };

    // this.modeに合わせ、中央配置か左配置かを決める
    const containerStyle = unitTitleStyleMap[this.mode];
    return containerStyle;
  }

  /**
   * assemblyUnitのタイトルの表示スタイルを返す。
   * this.modeごとにマージン幅が異なるのでtsで変化させている
   * @returns
   */
  public styleUnitTitle(): string {
    // modeをcssスタイルに変換するマッピング用のオブジェクトリテラル
    const unitTitleStyleMap = {
      list: 'unit-title-sm',
      info: 'unit-title-lr',
    };

    // this.modeに合わせ、中央配置か左配置かを決める
    const containerStyle = unitTitleStyleMap[this.mode];
    return containerStyle;
  }

  /**
   * aassetBox(大系列、XtopとXbottomをまとめた箱)のスタイルを返す
   * this.modeごとにマージン幅が異なるのでtsで変化させている
   * @returns
   */
  public styleAssetBox(): string {
    // modeをcssスタイルに変換するマッピング用のオブジェクトリテラル
    const unitTitleStyleMap = {
      list: 'd-flex flex-row asset-box-sm',
      info: 'd-flex flex-row asset-box-lr',
    };

    // this.modeに合わせ、中央配置か左配置かを決める
    const containerStyle = unitTitleStyleMap[this.mode];
    return containerStyle;
  }

  /**
   * assetの大系列(shortKeyとも言う。SP, X, Alarmなど)ごとのboxの形状のclassを返すメソッド
   * boxの中に小系列(longKeyとも言う。SP1, X1_1, Y2_1など)のanomalyIconを格納していく。
   * 大系列が同じ小系列は、同じbox内に格納される。
   * @param boxNum 「assetの大系列」の中の「小系列」の個数
   * @returns 「assetの大系列」ごとのboxの形状のclassを表す文字列
   */
  public styleAnomalyIconBox(boxNum: number): string {
    let boxStyle = ''; // 最終的に返すスタイル変数の宣言

    // boxNumによってスタイルが変わるため、キーを変える
    // 2023/10時点では2分岐なので if else で書く
    let keyFromBoxNum: string = '';
    if (boxNum >= 3) keyFromBoxNum = 'large';
    else keyFromBoxNum = 'small';

    // 画面の種類(2023/10時点ではlist、info)と引き数のboxNum(1assetの小系列の数)で分岐する
    // 機械一覧画面
    const mapList = {
      large: 'd-flex flex-wrap border-radius-box margin-sm',
      small: 'd-flex flex-column border-radius-box margin-sm',
    };
    // 機械詳細画面(SingleAsset, AllAssets, Alarm)
    const mapInfo = {
      large: 'd-flex flex-wrap border-radius-box anomaly-icon-box-shadow margin-lr',
      small: 'd-flex flex-column border-radius-box anomaly-icon-box-shadow margin-lr',
    };

    // まとめる
    const mapIconBox = {
      list: mapList,
      info: mapInfo,
      default: '',
    };

    //////////////////  ここからスタイル判定処理  //////////////////
    boxStyle = this.mode in mapIconBox && keyFromBoxNum ? mapIconBox[this.mode][keyFromBoxNum] : mapIconBox.default;

    return boxStyle;
  }

  /**
   * assetの小系列(SP1, X1_1, Y2_1など)のiconのスタイル(形状、色)のclassを返すメソッド
   * 小系列のiconはanomalyIconと命名する
   * @param num 「assetの大系列」の中の「小系列(SP1, X1_1, Y2_1など)」の個数
   * @param assetScore 「assetの小系列」の異常度スコア
   * @returns 「assetの小系列」のiconのスタイル(形状、色)のclassを表す文字列
   */
  public styleAnomalyIcon(num: number, assetScore: AssetScore): string {
    const styleAnomalyIcon: string = this.styleAnomalyIconShape(num) + ' ' + this.styleAnomalyIconColor(assetScore);
    return styleAnomalyIcon;
  }

  /**
   * assetの小系列(SP1, X1_1, Y2_1など)のanomalyIconの形状のclassを返すメソッド
   * @param num 「assetの大系列」の中の「小系列(SP1, X1_1, Y2_1など)」の個数
   * @returns 「assetの小系列」のanomalyIconの形状のclassを表す文字列
   */
  public styleAnomalyIconShape(num: number): string {
    // modeごとのスタイル定義
    // 引数のnumの値(をstringにしたもの)がそのままキーになる
    const infoStyle = {
      '1': 'anomaly-icon-info border-radius-num-1 anomaly-icon-shape-1-info',
      '2': 'anomaly-icon-info border-radius-num-2 anomaly-icon-shape-2-info',
      '3': 'anomaly-icon-info border-radius-num-3 anomaly-icon-shape-3-info',
      '4': 'anomaly-icon-info border-radius-num-4 anomaly-icon-shape-4-info',
    };

    const listStyle = {
      '1': 'anomaly-icon-list border-radius-num-1 anomaly-icon-shape-1-default',
      '2': 'anomaly-icon-list border-radius-num-2 anomaly-icon-shape-2-default',
      '3': 'anomaly-icon-list border-radius-num-3 anomaly-icon-shape-3-default',
      '4': 'anomaly-icon-list border-radius-num-4 anomaly-icon-shape-4-default',
    };

    // modeごとに定義したスタイルをまとめる
    const iconShapeMap = {
      list: listStyle,
      info: infoStyle,
      default: '',
    };

    //////////////////  ここからスタイル判定処理  //////////////////

    // iconShapeMapアクセス用にキーを作る必要がある。引数のnumをstringにする。
    const numStr = num.toString();
    const iconShape =
      this.mode in iconShapeMap && numStr in iconShapeMap[this.mode] // キーの存在チェック
        ? iconShapeMap[this.mode][numStr]
        : iconShapeMap.default;

    return iconShape;
  }

  /**
   * assetの小系列(SP1, X1_1, Y2_1など)のanomalyIconの色のclassを返すメソッド
   * @param asserScore 「assetの大系列」の中の「小系列(SP1, X1_1, Y2_1など)」の個数
   * @returns 「assetの小系列」のanomalyIconの色のclassを表す文字列
   */
  public styleAnomalyIconColor(assetScore: AssetScore): string {
    let color = ''; // 最終的に返すcolorスタイルの宣言

    // assetKeyがselectedかどうか、画面は何かでスタイルが決まる。そのマップを作る
    // statusやiconの種類が増えると変更が必要になる。
    // 詳細画面(SingleAsset, AllAssets, Alarm)かつselected

    // 変数名の規則 -> 画面(defaultあり) 色 補足事項
    const mapSelectedInfo = new Map([
      [AnomalyStatus.normal, 'info-blue-selected'],
      [AnomalyStatus.caution, 'info-yellow-selected'],
      [AnomalyStatus.alert, 'info-red-selected'],
      [AnomalyStatus.alarm, 'info-black-selected'],
      [AnomalyStatus.all, 'info-blue-selected'],
    ]);
    // 詳細画面(SingleAsset, AllAssets, Alarm)かつnotSelected
    const mapNotSelectedInfo = new Map([
      [AnomalyStatus.normal, 'info-blue'],
      [AnomalyStatus.caution, 'info-yellow'],
      [AnomalyStatus.alert, 'info-red'],
      [AnomalyStatus.alarm, 'info-gray'],
      [AnomalyStatus.all, 'info-blue'],
    ]);
    // 機械一覧画面(MachineList)かつselected
    // 今の所使っていないが今後使う可能性あり。
    const mapSelectedList = new Map([
      [AnomalyStatus.normal, 'default-blue-selected'],
      [AnomalyStatus.caution, 'default-yellow-selected'],
      [AnomalyStatus.alert, 'default-red-selected'],
      [AnomalyStatus.alarm, 'default-black-selected'],
      [AnomalyStatus.all, 'default-blue-selected'],
    ]);
    // 機械一覧画面(MachineList)かつnotSelected
    const mapNotSelectedList = new Map([
      [AnomalyStatus.normal, 'default-blue'],
      [AnomalyStatus.caution, 'default-yellow'],
      [AnomalyStatus.alert, 'default-red'],
      [AnomalyStatus.alarm, 'default-gray'],
      [AnomalyStatus.all, 'default-blue'],
    ]);

    // 画面ごとのスタイルマップをまとめる。
    // AnomalyIconを使う画面が増えない限りは変更はいらない
    // selectedの場合
    const mapSelected = {
      info: mapSelectedInfo,
      list: mapSelectedList,
    };
    // notSelectedの場合
    const mapNotSelected = {
      info: mapNotSelectedInfo,
      list: mapNotSelectedList,
    };

    // 対応するキーがない場合
    const colorDefault: string = '';

    //////////////////  ここからスタイル判定処理  //////////////////

    // selectedかどうかで判定した後、キーの存在チェックし、適切なスタイルを代入する
    if (assetScore.assetKey === this.selectedAssetKey) {
      color =
        this.mode in mapSelected && mapSelected[this.mode].has(assetScore.status) // キーの存在チェック
          ? (mapSelected[this.mode].get(assetScore.status) as string)
          : colorDefault;
    } else {
      color =
        this.mode in mapNotSelected && mapNotSelected[this.mode].has(assetScore.status) // キーの存在チェック
          ? (mapNotSelected[this.mode].get(assetScore.status) as string)
          : colorDefault;
    }

    return color;
  }

  /**
   * mode=list時にdisplayNameの文字サイズを変えるメソッド
   * デフォルトのdisplayNameのスタイルを上書きするために使う
   * @param assetScore
   */
  public styleDisplayName(assetScore: AssetScore): string {
    // list以外では上書きしない
    if (this.mode !== 'list') return '';

    const displayName: string = assetScore.displayName;
    // 文字数によってfontサイズを変える
    if (displayName.length >= 4) return 'diplay-name-sm';
    else return '';
  }
}
