// ---------------------------------------------------------------------
// <copyright file=post-validation.component.ts company=WALC Inc.
// (C) 2024 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/service/modal.service';

/**
 * 機械コメントの投稿時の確認画面コンポーネント
 */
@Component({
  selector: 'app-post-validation',
  templateUrl: './post-validation.component.html',
  styleUrls: ['./post-validation.component.css'],
})
export class PostValidationComponent implements OnInit {
  message: string = undefined;

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    this.message = this.modalService.getMessage();
  }

  onClick(selectResult: boolean) {
    // ボタンがクリックされたときの処理
    this.modalService.sendResult(selectResult);
  }
}
