<!-- タイトル -->
<p class="d-flex flex-row justify-content-start align-items-start title-margin">
  <!-- <span  class="font-title">Frequency Characteristic Analysis &#40;FFT&#41;</span> -->
  <span class="font-title">{{ 'frequencyCharacteristic.title' | translate }}</span>

  <!-- infoボタン -->
  <mat-icon
    appImgTooltip
    message="{{ 'frequencyCharacteristic.tooltipMessages' | translate }}"
    [path]="tooltipImgPath"
    [position]="'below'"
    >info
  </mat-icon>

  <span class="time-stamp ms-auto">
    {{ displayTimestamp | date: 'medium' : 'UTC+' + selectedTimestamp?.slice(-4) : localeID }}
    <br />
    {{ positionLabel }}: {{ selectedPosition }}%
  </span>
</p>
<!-- 画像コンテンツ -->
<div class="d-flex flex-column justify-content-center align-items-center" *ngIf="!isLoading">
  <img src="{{ displayURL }}" [class]="displayImageStyle()" />
</div>

<!-- 通信中に表示するアイコン -->
<app-progress-spinner [isLoading]="isLoading" class="mt-spinner-lr" *ngIf="isLoading"></app-progress-spinner>

<!-- エラーメッセージ表示用の箱 -->
<p class="text-center" *ngIf="errorMessage">{{ errorMessage }}</p>
