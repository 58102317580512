import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoService } from './cognito.service';
import { Machine } from '../model/machine';
import { Plant } from '../model/plant';
import { StorageService } from './storage.service';
import { Account } from '../model/account';
import { MachineInfoParent } from '../model/machine-info-parent';
import { AnomalyStatus } from '../model/anomaly-unit';

/**
 * ルーティング時の処理をまとめるサービス
 *
 */
@Injectable({
  providedIn: 'root',
  deps: [CognitoService, StorageService],
})
export class RouteProcessService {
  constructor(
    private router: Router,
    private cognitoService: CognitoService,
    private storageService: StorageService,
  ) {}

  /**
   * ログアウト時のメソッド
   */
  public logout() {
    this.cognitoService.signOut();
  }

  /**
   * ログアウト時のメソッド
   */
  public navigateTop() {
    this.router.navigate(['/']);
  }

  /**
   * login画面へ遷移するメソッド。
   * 非ログイン状態でSignOutしてもエラーにはならないので、
   * ログイン状態の可否に関わらずCognitoのSignOut()を叩くよう統一する。
   */
  public navigateLogin() {
    this.cognitoService.signOut();
  }

  /**
   * error画面へ遷移するメソッド。
   */
  public navigateError() {
    this.router.navigate(['error']);
  }

  /**
   * success画面へ遷移するメソッド。change-password, reset-passwordが使う
   */
  public navigateSuccess() {
    this.router.navigate(['success']);
  }

  /**
   * change-password画面へ遷移するメソッド。login, reset-passwordが使う
   */
  public navigateChangePassword(user: any) {
    this.storageService.user = user;
    this.router.navigate(['change-password']);
  }

  /**
   * reset-password画面へ遷移するメソッド。change-password, loginが使う
   */
  public navigateResetPassword() {
    this.router.navigate(['reset-password']);
  }

  /**
   * 管理者top画面へ遷移するメソッド
   */
  public navigateAdminTop() {
    this.router.navigate(['admin-top']);
  }

  /**
   * pathの文字列に従って遷移するメソッド。必要かは不明。
   * 管理者画面からaccount, machine-commentの遷移に使用。
   * コンポーネントにガードがあるので、権限確認はここではしない。
   */
  public navigateAdminSelectedPath(path: string) {
    this.router.navigate([path]);
  }

  /**
   * 管理者top画面へ遷移するメソッド
   */
  public navigateMachineComment() {
    this.router.navigate(['machine-comment']);
  }

  /**
   * アカウントリスト画面へのルーティングメソッド
   * 権限確認いるかな？どうせガードされるが
   */
  public navigateAccountList() {
    this.router.navigate(['/account']);
  }

  /**
   * 工場リスト画面へのルーティングメソッド
   */
  public navigatePlantList(account?: Account) {
    if (account) this.storageService.setAccount(account);
    this.router.navigate(['/plant']);
  }

  /**
   * 機械リスト画面へのルーティングメソッド
   * 引数のplantに値がある場合は、そのplantの機械リスト画面へ、ない場合はfloorServiceのselectedPlantの機械リスト画面へ遷移する
   * @param plant 機械リストを表示したい工場のPlant型オブジェクト
   */
  public navigateMachineList(plant?: Plant) {
    if (plant) this.storageService.setPlant(plant);
    this.router.navigate(['/dashboard']);
  }

  /**
   * 選択された機械、軸の詳細画面へのルーティングメソッド
   * 引数のmachineの値があるときはその機械のassetKeyの詳細画面へ、ない場合はmachineServiceのselectedMachineのassetKeyの詳細画面へ遷移する
   */
  public navigateSingleAssetDiagnosis(assetKey: string, machine?: Machine): void {
    this.storageService.setAsset(assetKey);
    if (machine) this.storageService.setMachine(machine);
    this.router.navigate(['/singleasset']);
  }

  /**
   * 選択された機械の全軸の診断結果画面へのルーティング
   * 引数のmachineの値があるときはその機械の全軸画面へ、ない場合はmachineServiceのselectedMachineの全軸画面へ遷移する
   */
  public navigateAllAssetDiagnosis(machine?: Machine): void {
    this.storageService.setAsset('All');
    if (machine) this.storageService.setMachine(machine);
    this.router.navigate(['/allassets']);
  }

  /**
   * 選択された機械のアラーム画面へのルーティング
   * 引数のmachineの値があるときはその機械のアラーム画面へ、ない場合はmachineServiceのselectedMachineのアラーム画面へ遷移する
   */
  public navigateMachineAlarm(machine?: Machine): void {
    this.storageService.setAsset('Alarm');
    if (machine) this.storageService.setMachine(machine);
    this.router.navigate(['/alarm']);
  }

  /**
   * 同一assetKeyでmachineだけ変えたい時の遷移メソッド。
   * 必ずSingleAsset, AllAssets, Alarmから叩くこと。
   * machine-list-sidebarが複雑化したため、ここにまとめることにした。
   * single->singleなど同画面の時はorutingはignoreされるので、親コンポーネントの画面の書き換えのみ行うこと
   * このメソッドは必ず親コンポーネントから呼ぶこと
   * @param machine 遷移先の機械
   * @param assetKey 遷移先のassetKey
   * @param parent 遷移元の親コンポーネント
   * @returns routed ルーティングが行われたかどうか。
   */
  public navigateSameAssetKeyMachine(machine: Machine, assetKey: string, parent: MachineInfoParent): boolean {
    switch (parent) {
      // 現在の画面がsingleAssets(詳細画面)
      case MachineInfoParent.singleAsset:
        // assetKeyが引数のmachineにない場合はAllに遷移する
        if (!Object.keys(machine.Assets).includes(assetKey)) {
          this.navigateAllAssetDiagnosis(machine);

          return true;
        }

        this.navigateSingleAssetDiagnosis(assetKey, machine);
        return false;
      // 現在の画面が要約画面(allAssets)
      case MachineInfoParent.allAssets:
        // allAssetsの場合は必ずallAssetsに遷移する
        this.navigateAllAssetDiagnosis(machine);
        return false;
      // 現在の画面がアラーム(alarm)
      case MachineInfoParent.alarm:
        // alarmの場合は、machine.Assets.Alarm.LatestStatusがtrueかfalseかで変わる
        if (!machine.Assets['Alarm'] || machine.Assets['Alarm'].LatestStatus == AnomalyStatus.alarmFalse) {
          this.navigateAllAssetDiagnosis(machine);
          return true;
        }

        this.navigateMachineAlarm(machine);
        return false;
      // 現在の画面がその他(default)
      default:
        this.navigateAllAssetDiagnosis(machine);
        return false;
    }
  }

  /**
   * APIエラー時などに遷移する広告画面？エラー画面?へ遷移するメソッド
   */
  public navigateAdvertisement(): void {
    this.router.navigate(['/advertisement']);
  }
}
