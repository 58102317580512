<!-- タイトル -->
<p class="d-flex flex-row justify-content-start align-items-center title-margin">
  <!-- 英語版 -->
  <!-- <span  class="font-title">Position Adjusted Condition Timeline at {{selectedPosition}}%</span> -->
  <!-- 日本語版 -->
  <span class="font-title"
    >{{ 'positionAdjustedAnomaly.title' | translate }} {{ positionLabel }} {{ selectedPosition }}%
  </span>

  <!-- infoボタン -->
  <mat-icon
    appImgTooltip
    message="{{ 'positionAdjustedAnomaly.tooltipMessages' | translate }}"
    [path]="tooltipImgPath"
    [position]="'below'"
    >info
  </mat-icon>

  <!-- <span class="time-stamp">
    {{positionLabel}}: {{selectedPosition}}%
  </span> -->
</p>

<!-- 画像コンテンツ -->
<div class="d-flex flex-column justify-content-center align-items-center" *ngIf="!isLoading">
  <img src="{{ displayURL }}" [class]="displayImageStyle()" />
</div>

<!-- 通信中に表示するアイコン -->
<app-progress-spinner [isLoading]="isLoading" class="mt-spinner-lr" *ngIf="isLoading"></app-progress-spinner>

<!-- エラーメッセージ表示用の箱 -->
<p class="text-center" *ngIf="errorMessage">{{ errorMessage }}</p>
