// ---------------------------------------------------------------------
// <copyright file=advertisement.component.ts company=WALC Inc.
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit } from '@angular/core';
import { RouteProcessService } from 'src/app/service/route-process.service';

/**
 * 非契約者がログインした際の広告用コンポーネント。myDMGMORIへのログアウト遷移を行う。
 * AccountコンポーネントやPlantコンポーネントでなんらかのエラーが出た時のみここに遷移する。
 */
@Component({
  selector: 'app-advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.css'],
})
export class AdvertisementComponent implements OnInit {
  constructor(private routeProcessService: RouteProcessService) {}

  ngOnInit(): void {}

  public onClickLogout(): void {
    this.routeProcessService.logout();
  }
}
