// ---------------------------------------------------------------------
// <copyright file="all-sample-anomaly-image.component.ts" company="WALC Inc."
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit, Input } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MachineService, HttpImageURLs } from 'src/app/service/machine.service';
import { Machine } from 'src/app/model/machine';
import { notFoundImageURL } from 'src/app/static/image';
import { tooltipImgPath } from './tooltip-props.json';

/**
 * 単一軸の全診断サンプル画像を表示するコンポーネント\
 * SingleAssetDiagnosisコンポーネント上に置かれる。
 */
@Component({
  selector: 'app-all-sample-anomaly-image',
  templateUrl: './all-sample-anomaly-image.component.html',
  styleUrls: ['../../style/image-style.css', './all-sample-anomaly-image.component.css'],
})
export class AllSampleAnomalyImageComponent implements OnInit {
  // SingleAssetDiagnosisコンポーネントから受け取るプロパティ
  @Input() selectedMachine: Machine;
  @Input() selectedAssetKey: string;
  @Input() selectedTimestamp: string;
  @Input() timestampList: string[];
  @Input() selectedPosition: number;
  @Input() positionStep: number;
  @Input() positionList: number[];

  public displayURL: string = undefined; //表示する画像のURL
  public colorbarURL: string = undefined; //カラーバーのURL
  public notFoundURL: string = notFoundImageURL; // テンプレートで使えるようにpublicで持たせている
  public isDisplayLine: boolean = false; // 縦線横線を表示するかのトリガー

  // mat-selectのplaceholder制御用変数
  public selectedOption: string | null = null;

  // Infoのtooltipの表示内容を読み込む
  // public tooltipMessages: string[] = tooltipMessages;
  public tooltipMessages: string[] = [];
  public tooltipImgPath: string = tooltipImgPath;

  // onDestroy時にsubscribe解除をするためのオブジェクト。subscribe前にpipe内で takeUntil(this.unsubscribe$) とすれば良い。
  private unsubscribe$ = new Subject();

  // ローディングスピナー表示用のフラグ
  public isLoading: boolean = false; // 初期状態は非表示

  public errorMessage: string = undefined;

  constructor(private machineService: MachineService) {}

  ngOnChanges(changes: SimpleChanges): void {
    // selectedMachineかselectedAssetKeyが変わった時のみ実行する
    if (changes.selectedMachine || changes.selectedAssetKey) {
      // selctedMachineとselectedAssetKey両方ある時のみ画像取得する
      if (this.selectedMachine && this.selectedAssetKey) {
        this.setImageURL(this.selectedMachine.MachineID, this.selectedAssetKey);
      }
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next(); // unsubscribe$に値を発行するとtakeUntilが起動し、購読を止める
  }

  /**
   * 引数に対応する最新異常度画像を、APIから取得するメソッド
   * selectedMachine.machineIDとselectedAssetKeyを引数に指定する
   * @param machineID 機番
   * @param assetKey 軸名
   */
  private setImageURL(machineID: string, assetKey: string): void {
    this.isLoading = true; // ローディングスピナー表示

    this.machineService
      .fetchAllTimeDiagnosisImage(machineID, assetKey)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: HttpImageURLs) => {
          this.displayURL = response.URLs ? response.URLs[0] : notFoundImageURL;
          this.colorbarURL = response.URLs ? response.URLs[1] : null; // カラーバーのみ取得できなかった場合、URLsには空文字が入るのでindex errorにはならない
          this.isLoading = false; // ローディングスピナー非表示
          // 表示の順番をある程度制御したいので、少し遅らせてからLine表示を許可する
          setTimeout(() => {
            this.isDisplayLine = true;
          }, 1500); // 1.5 秒の遅延を追加
        },
        (error) => {
          console.log(error);
          this.isDisplayLine = false;
          this.errorMessage = error.message;
          this.displayURL = notFoundImageURL;
          this.isLoading = false; // ローディングスピナー非表示
        },
      );
  }

  /**
   * this.selectedTimestampのtimestampList内でのindexを探索し、その値をもとに画像に重ねる横線の位置を決めるメソッド\
   * 画像の横幅のpxは固定とする。縦幅はtimestampが増えるたびに、一定pxだけ下に伸びると考えること
   * @returns 横線のCSSスタイル
   */
  public horizontalLineStyleTop(): number {
    // 初期位置はpxで決める
    const pxStart: number = 41;
    const pxStep: number = 17.39;

    // timestampのindexと、timestampListのlengthを元に、topから何px下げればいいかを計算する
    const index: number = this.timestampList?.findIndex((element: string) => element == this.selectedTimestamp);
    const pxStyleTop: number = pxStart + index * pxStep;

    return pxStyleTop;
  }

  /**
   * this.selectedPositionの値から、画像に重ねる縦線の位置を決めるメソッド
   * @returns 縦線のCSSスタイル
   */
  public vertivalLineStyleLeft(): number {
    const pxStart: number = 155;
    const pxEnd: number = 538.5;
    const pxStep: number = (this.positionStep * (pxEnd - pxStart)) / 100;

    // selectedPositionのindexと、positionListのlengthを元に、leftから何px左に置けばいいかを計算する
    const index: number = this.positionList?.findIndex((element: number) => element == this.selectedPosition); // 型チェックまでいれるかどうか
    const styleLeftValue = pxStart + index * pxStep;
    return styleLeftValue;
  }

  /**
   * 画像に重ねる縦線のtop位置と高さを決めるメソッド
   * @returns 縦線のCSSスタイル
   */
  public vertivalLineStyleHeight(): number {
    const pxStep: number = 17.3181;
    const styleHeight = this.timestampList?.length * pxStep;
    return styleHeight;
  }

  /**
   * 表示画像のCSSクラスを返すメソッド
   * not-foundかどうかで判断する。
   * セレクタは style/image-style.cssの中のものを指定
   * @returns CSSクラスのstring
   */
  public displayImageStyle(): string {
    if (this.displayURL === notFoundImageURL) {
      return 'img-not-found';
    } else {
      return 'img-found-100';
    }
  }
}
