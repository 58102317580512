<app-toolbar class="toolbar-only"></app-toolbar>
<!-- ローディングバー -->
<app-progress-bar class="progress-bar" [isLoading]="isLoading" *ngIf="isLoading"></app-progress-bar>

<!-- アカウントリスト格納用のコンテナ -->
<div class="d-flex flex-column justify-content-center align-items-center">
  <!-- タイトル(見分けるため) -->
  <span class="title-text">{{ 'account.title' | translate }}</span>

  <!-- リストの各項目 -->
  <div class="row" *ngFor="let account of accountList">
    <button class="btn-block" (click)="onClickSelectAccount(account)">
      {{ account.AccountName }}
    </button>
  </div>
</div>
