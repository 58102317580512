// ---------------------------------------------------------------------
// <copyright file="cognito.service.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { Auth } from 'aws-amplify';
import { User } from '../model/user-info';
import { HttpService } from './http.service';
import { adminRole } from '../static/admin-role';

/**
 * Cognitoとのやりとり、ユーザ情報の取得や管理をするクラス。
 */
@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  //////////////////// 注意 ///////////////////////////
  /**
   * ユーザ情報の取得はuserObservableメソッドを使った非同期処理を基本とすること。
   * ユーザ情報を使うモジュールは属性がnullでもエラーしないようなハンドリングをすること
   */
  ///////////////////////////////////////////////////

  // ログイン状態を保持するObservale
  // async pipeでログイン状態を確認したい時用。
  // 直接書き換えないこと。setAuthenticatedとsetNotAuthenticatedを使うこと
  private loggedInSubject = new BehaviorSubject<boolean>(false); // コンストラクタで初期化すること
  public readonly loggedInStream = this.loggedInSubject.asObservable(); // 外部から参照するobservable

  constructor(
    private httpService: HttpService,
    private router: Router,
  ) {}

  /**
   * user情報のObservableを返す。
   * 元々使ってたsetUserInfoはXavierを使っていたが、不便なので、Authを使う。
   * LocalStorageと違い毎回Cognitoにアクセスするので問題ない。
   *
   * cognitoサービスにUserデータを保存するとリロード時などに不整合を起こすので、
   * ユーザ情報が必要なコンポーネントは毎回onInitでこれを叩くこと
   */
  public userObservable(): Observable<User> {
    return from(Auth.currentAuthenticatedUser()).pipe(
      map((result) => {
        // コンポーネントに返すuserオブジェクト作成
        const user = new User();
        user.setUser(result.attributes);

        // ログイン状態の不整合を解決する
        this.setAuthenticated();
        return user;
      }),
      catchError((error) => {
        console.log(error);
        // ログイン状態の不整合を解決する
        this.setNotAuthenticated();
        // ログアウトするかどうかは画面側で決める。
        return of(null);
      }),
    );
  }

  /////////////////// ログインログアウトの状態変更 ///////////////////////////

  /**
   * ログアウト -> ログイン時の状態変化をまとめる。
   * loginしているかどうかのstreamを更新するだけなので、プライバシー情報にpublicメソッドを適用しているわけではない。
   */
  public setAuthenticated(): void {
    this.loggedInSubject.next(true);
  }

  // ログイン -> ログアウト時の状態変化をまとめる
  public setNotAuthenticated(): void {
    this.loggedInSubject.next(false);
  }

  /////////////////////////////// 便利なObservableを返す系 //////////////////////////////

  /**
   * ログイン状態のObservableを返すメソッド
   * コンポーネントが扱いやすくするためにObservableに変換している。
   * 外部サービスがログイン状態を取得するなどsubscribeが使いづらい場合は Auth.currentAuthenticatedUser().then() でPromiseのまま処理できるべき。
   *
   * async pipeで取ると無限実行されるので、コンポーネント側に中間observable入れること！
   * @returns
   */
  public isAuthenticated(): Observable<boolean> {
    return from(Auth.currentAuthenticatedUser()).pipe(
      map((result) => {
        this.httpService.ExpireToken = result.signInUserSession.idToken.payload.exp;
        if (result.challengeName === undefined) {
          if (!this.loggedInSubject.getValue()) this.setAuthenticated(); // ログイン状態の不整合を解消する処理
          return true;
        } else {
          if (this.loggedInSubject.getValue()) this.setNotAuthenticated(); // ログイン状態の不整合を解消する処理
          return false;
        }
      }),
      catchError((error) => {
        console.log(error);
        this.setNotAuthenticated();
        return of(false);
      }),
    );
  }

  /**
   * role guard用
   * Observablerで非同期でroleを確認するメソッド。
   * guardは非同期で処理しないと、urlpathに直接アクセスした際にcognitoServiceで不整合が起きてしまう。
   *
   * async pipeで取ると無限実行されるので、コンポーネント側に中間observable入れること！
   * @returns
   */
  public isAdminObservable(): Observable<boolean> {
    return from(Auth.currentAuthenticatedUser()).pipe(
      map((result) => {
        if (adminRole.includes(result.attributes['custom:role'])) {
          return true;
        } else {
          return false;
        }
      }),
      // ログインしていない場合はこっちに来る
      catchError((error) => {
        console.log(error);
        this.setNotAuthenticated();
        return of(false);
      }),
    );
  }

  /////////////////// 画面処理をcognitoに転送する系 //////////////////////////

  // Send confirmation code to user's email
  public forgetPassword(username: string): Observable<any> {
    return from(Auth.forgotPassword(username));
  }

  // Collect confirmation code and new password, then
  public forgetPasswordSubmit(username: string, code: string, newPassword: string): Observable<any> {
    return from(Auth.forgotPasswordSubmit(username, code, newPassword));
  }

  // 認証コードの確認
  public verifyUserAttributeSubmit(user, verificationCode): Observable<any> {
    return from(Auth.verifyUserAttributeSubmit(user, 'email', verificationCode));
  }

  // 認証コードの再送信
  public verifyUserAttribute(user): void {
    from(Auth.verifyUserAttribute(user, 'email'));
  }

  // 初回ログイン時のパスワードとユーザー情報の変更
  public completeNewPassword(user, password): Observable<any> {
    return from(Auth.completeNewPassword(user, password));
  }

  /**
   * ログイン
   * ログインしただけだとパスワード変更requiredがtrueになっている可能性がある。
   * この場合ログインしたことにはならないのでsetAuthenticatedはできない。
   * signIn呼び出し元のコンポーネントがsetAuthenticatedする。
   * @param email
   * @param password
   * @returns
   */
  public signIn(email: string, password: string): Observable<any> {
    return from(Auth.signIn(email, password));
  }

  /**
   * ログアウト処理。ログイン画面に戻るかどうかを一応決められるようにしている。
   * @param navigateLogin ログイン画面に遷移するかどうか。値を入れない場合=undefined なのでfalseと一緒
   */
  public signOut(navigateLogin: boolean = true): void {
    // SSO時とそれ以外で分ける
    // SSOかどうかを取得する
    const usedSSO: boolean = this.httpService.usedSSO;
    from(Auth.signOut()).subscribe(
      () => {
        this.setNotAuthenticated();
        localStorage.clear();

        // sso未使用かつnavigateLogin == trueの時のみログイン画面に戻る。
        if (!usedSSO && navigateLogin) {
          this.router.navigate(['/login']);
        }
      },
      (error) => console.log(error),
    );
  }
}
