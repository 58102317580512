<app-toolbar class="toolbar-only"></app-toolbar>
<!-- ローディングバー、ログイン処理が長いので、目印 -->
<app-progress-bar class="progress-bar" [isLoading]="nowLogin" *ngIf="nowLogin"></app-progress-bar>

<div class="d-flex flex-column justify-content-center align-items-center login-margin">
  <form
    class="d-flex flex-column justify-content-center align-items-center w-100"
    (ngSubmit)="onSubmitLogin(loginForm.value)"
    [formGroup]="loginForm"
  >
    <div class="text-center custom-form-title">
      <!-- Login -->
      {{ 'login.title' | translate }}
    </div>

    <div class="form-group">
      <label class="custom-form-label" for="userName">
        <!-- User Name -->
        {{ 'login.userName' | translate }}
      </label>
      <!-- <input class='form-control' type="email" formControlName="email" placeholder="Please enter"> -->
      <input
        class="form-control"
        type="text"
        name="userName"
        id="userName"
        formControlName="userName"
        placeholder="{{ 'login.placeHolder' | translate }}"
        autocomplete="username"
      />
    </div>

    <div class="form-group">
      <label class="custom-form-label" for="password">
        <!-- Password -->
        {{ 'login.password' | translate }}
      </label>
      <!-- <input class='form-control' type="password" formControlName="password" placeholder="Please enter"> -->
      <input
        class="form-control"
        type="password"
        name="password"
        id="password"
        formControlName="password"
        placeholder="{{ 'login.placeHolder' | translate }}"
        autocomplete="current-password"
      />
    </div>

    <!-- エラーメッセージがあれば表示する -->
    <div class="text-center alert" *ngIf="errorMessage">{{ errorMessage }}</div>

    <button class="btn-block" type="submit" [class.disabled]="loginForm.invalid || nowLogin">
      <!-- Login -->
      {{ 'login.button' | translate }}
    </button>

    <div class="link-text">
      <!-- <span (click)="onClickResetPassword()">Click here if you forgot your password</span> -->
      <span (click)="onClickResetPassword()">{{ 'login.forgotPassword' | translate }}</span>
    </div>
  </form>
</div>
