<!-- タイトル -->
<p class="d-flex flex-row justify-content-start align-items-center title-margin w-100">
  <span class="font-title">{{ 'rawCurrent.title' | translate }}</span>

  <!-- infoボタン -->
  <mat-icon appImgTooltip message="{{ 'rawCurrent.tooltipMessages' | translate }}" [path]="" [position]="'below'"
    >info
  </mat-icon>

  <span class="time-stamp ms-auto">
    {{ displayTimestamp | date: 'medium' : 'UTC+' + selectedTimestamp?.slice(-4) : localeID }}
  </span>
</p>
<!-- 画像コンテンツ -->
<div class="d-flex flex-column justify-content-center align-items-center w-100" *ngIf="!isLoading">
  <img src="{{ displayURL }}" [class]="displayImageStyle()" />
</div>

<!-- 通信中に表示するアイコン -->
<app-progress-spinner [isLoading]="isLoading" class="mt-spinner-lr" *ngIf="isLoading"></app-progress-spinner>

<!-- エラーメッセージ表示用の箱 -->
<p class="text-center" *ngIf="errorMessage">{{ errorMessage }}</p>
