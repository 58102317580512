<app-toolbar class="toolbar-only"></app-toolbar>
<!-- ローディングバー、通信中に出す -->
<app-progress-bar class="progress-bar" [isLoading]="nowSubmit" *ngIf="nowSubmit"></app-progress-bar>

<div class="d-flex flex-column justify-content-center align-items-center login-margin">
  <form
    class="d-flex flex-column justify-content-center align-items-center w-100"
    (ngSubmit)="onSubmitChangePassword(changePasswordForm.value)"
    [formGroup]="changePasswordForm"
  >
    <div class="text-center custom-form-title title-margin">{{ 'changePassword.title' | translate }}</div>
    <div class="text-center note">
      {{ 'changePassword.explanation0' | translate }}
      <br />
      {{ 'changePassword.explanation1' | translate }}
    </div>
    <!-- <div class="form-group my-5">
      <label class="custom-form-label">New Name: </label>
      <input class="form-control" type="text" formControlName="name">
    </div> -->
    <div class="form-group">
      <label class="custom-form-label" for="password">{{ 'changePassword.newPassword' | translate }}</label>
      <input
        class="form-control"
        type="password"
        name="password"
        id="password"
        formControlName="password"
        autocomplete="new-password"
        placeholder="{{ 'changePassword.placeHolder' | translate }}"
      />
    </div>

    <div class="form-group">
      <label class="custom-form-label" for="confirmPassword">{{ 'changePassword.reNewPassword' | translate }}</label>
      <input
        class="form-control"
        type="password"
        name="confirmPassword"
        id="confirmPassword"
        formControlName="confirmPassword"
        autocomplete="new-password"
        placeholder="{{ 'changePassword.placeHolder' | translate }}"
      />
      <!-- バリデーションアラート -->
      <!-- 表示領域は確保しておく -->
      <div class="val-alert-container">
        <p class="form-alert" *ngIf="isConfirmPasswordFilled() && changePasswordForm.errors?.passwordMismatch">
          {{ 'changePassword.passwordMissmatch' | translate }}
        </p>
      </div>
    </div>

    <!-- 注意もしくはエラーメッセージを表示する -->
    <div class="text-center note" *ngIf="!errorMessage">{{ 'changePassword.nextScreen' | translate }}</div>
    <div class="text-center alert" *ngIf="errorMessage">{{ errorMessage }}</div>
    <button class="btn-block" type="submit" [class.disabled]="changePasswordForm.invalid">
      {{ 'changePassword.changePassword' | translate }}
    </button>
  </form>

  <div class="link-text">
    <span class="login" (click)="onClickLogin()">{{ 'changePassword.returnToLogin' | translate }}</span>
  </div>
</div>
