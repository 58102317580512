// ---------------------------------------------------------------------
// <copyright file="anomaly-unit.ts" company="DMG MORI B.U.G. CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { AssetValue, Assets } from './machine';

/**
 * AnomalyIconの実態クラス。ロジックが複雑なので複数クラスを経由している\
 * AnomalyUnit(最外コンテナ) > AssetBox(大分類=shortKeyの箱) > AssetStatus(小分類=longKeyの箱) > AssetStatus(longKeyのstatus) という包含関係\
 * shortKeyというロジックが中に含まれており、扱いづらいが、解決策がない
 */
export class AnomalyUnit {
  assemblyUnitList: AssemblyUnit[] = [];

  constructor(assets: Assets) {
    // assetsでforループし、AssemblyUnitごとにオブジェクトを作る
    for (const assetKey in assets) {
      // alarmの場合は別処理
      if (assetKey === 'Alarm') {
        this.pushUnitofAlarm(assets[assetKey]);
        continue;
      }

      const unitName = assets[assetKey].AssemblyUnit;

      // this.assemblyUnitListからunitNameが一致するものを取り出し、assetをpushする
      const foundAssemblyUnit: AssemblyUnit = this.assemblyUnitList.find(
        (assemblyUnit) => assemblyUnit.unitName === unitName,
      );

      // filter後にオブジェクトが取なかった時(初めてのユニット名の時)の処理
      // undefinedが格納されている
      if (!foundAssemblyUnit) {
        // 新しいAssemblyUnitを作る
        const assemblyUnit = new AssemblyUnit(unitName);
        // assetを追加
        assemblyUnit.assets[assetKey] = assets[assetKey];
        // このユニットをthis.assemblyUnitListに追加
        this.assemblyUnitList.push(assemblyUnit);
      }
      // 取れた時の処理
      else {
        // この場合はユニットにassetを追加するだけ
        foundAssemblyUnit.assets[assetKey] = assets[assetKey];
      }
    }

    // assetsの分類ができたので、すべてのUnitでアイコン用のassetBoxesの作成をする
    this.assemblyUnitList.forEach((assemblyUnit) => assemblyUnit.genAssetBoxes());

    // Allアイコンをboxesにpushする
    this.pushUnitofAll();

    // アラームが最初に来てしまうので並び替える
  }

  /**
   * Allアイコン用に擬似的なAssetBoxを作り、this.boxesにpushする
   */
  private pushUnitofAll(): void {
    // Allアイコンは特別なアイコンであり、Assetsという概念とは関係がない
    // 先に擬似的なAssetBoxを作り、それをAssemblyUnitにpushすることでアイコンに必要なデータを揃える。

    // まず、AssetBoxの空箱を作る
    const shortKey: string = 'All';
    const longKey: string = 'All';
    const allIconAssetBox = new AssetBox(shortKey);

    // addAssetsScore用に仮のAssetValueを作る
    const allAssetValue: AssetValue = {
      LatestStatus: AnomalyStatus.all,
      AssetType: 'All',
      AssemblyUnit: 'All',
      DisplayName: 'All',
    };

    // AssetValueを使ってAssetBox内のAssetScoreを作る
    allIconAssetBox.addAssetScore(longKey, allAssetValue);

    // AssemblyUnitObjectを作成する
    const allUnitObj = new AssemblyUnit('All');
    // AssemblyUnitにAssetBoxを追加する
    allUnitObj.assetBoxes.push(allIconAssetBox);

    this.assemblyUnitList.push(allUnitObj);
  }

  /**
   * Alarmアイコン用に擬似的なAssetBoxを作り、this.boxesにpushする
   */
  private pushUnitofAlarm(alarmAsset: AssetValue): void {
    // Allアイコンは特別なアイコンであり、Assetsという概念とは関係がない
    // 先に擬似的なAssetBoxを作り、それをAssemblyUnitにpushすることでアイコンに必要なデータを揃える。

    // Statusがfalseなら処理を無視する
    if (alarmAsset.LatestStatus === AnomalyStatus.alarmFalse) return;

    // まず、AssetBoxの空箱を作る
    const shortKey: string = 'Alarm';
    const longKey: string = 'Alarm';
    const alarmIconAssetBox = new AssetBox(shortKey);

    // AlarmではAssetrValue形式のデータがAPIから渡されるので、それを使ってAssetBox内のAssetScoreを作る
    alarmIconAssetBox.addAssetScore(longKey, alarmAsset);

    // AssemblyUnitObjectを作成する
    const alarmUnitObj = new AssemblyUnit('Alarm');
    // AssemblyUnitにAssetBoxを追加する
    alarmUnitObj.assetBoxes.push(alarmIconAssetBox);

    this.assemblyUnitList.push(alarmUnitObj);
  }
}

/**
 * 構造物グループクラス
 * 構造物グループの例は工具主軸、下刃物台など。\
 * assetBoxesの作り方は2種類ある\
 * - assetsを先に格納し、genAssetBoxesメソッドを叩く
 * - AssetBox型オブジェクトを外で作り、assetBoxesに代入する
 */
export class AssemblyUnit {
  public unitName: string;
  public assets: Assets; // このユニットに該当するasset群。アイコンになるのはassetBoxesなので、一時情報と言える
  public assetBoxes: AssetBox[]; // 頭文字が同じ軸をまとめた箱のリスト
  readonly assetKeys = ['X', 'Y', 'Z', 'SP', 'A', 'B', 'C']; // assetのキーが増えたらここに追加。並び替えるとアイコンもその順になる

  constructor(unitName: string) {
    this.unitName = unitName;
    this.assets = new Assets();
    this.assetBoxes = [];
  }

  // AssetBoxの作成処理。assetsが揃ってからやること
  public genAssetBoxes() {
    // assetKeysを元に、各大分類(頭文字が同じ)ごとの空箱を作ってboxesに入れる
    this.assetKeys.forEach((key) => {
      this.assetBoxes.push(new AssetBox(key));
    });

    // 引数のstatusesのキーが小分類となっている
    // 小分類を大分類空箱に入れている
    const longKeys: string[] = Object.keys(this.assets);

    // 小分類キーでforEachし、boxesの中から入れる箱を選択
    longKeys.forEach((key) => {
      const box: AssetBox = this.assetBoxes.find((item) => {
        // shortKeyが一致しないものは無視
        if (item.shortkey !== this.shortKey(key)) {
          return false;
        }

        // 上記のifでfalseにならなかったboxは、このループでのlongKeyを入れる箱に該当する
        return true;
      });

      // 上記のfor文でtrueが返された箱に、AssetScoreをいれる
      if (box !== undefined) {
        box.addAssetScore(key, this.assets[key]);
        // boxの中身をlongKey(X1_1, X1_2などでソートする必要がある)
        box.units.sort(this.compareLongKey);
      }
    });

    // box.num==0のものをboxesから削除する
    this.assetBoxes = this.assetBoxes.filter((box) => box.num != 0);
  }

  /**
   * longKeyからshortKeyを判別するメソッド
   * @param key longKey(SP1, X1_1, Alarmなど)
   * @returns shortKey(SP, X, Alarmなど)
   */
  private shortKey(key: string): string {
    const singleKeys = ['X', 'Y', 'Z', 'A', 'B', 'C']; // assetのキーが増えたらここに追加
    const firstChar = key?.charAt(0);
    // 最初の文字が singleKeys に入っていたら、そのキーを返す
    if (!key) {
      // 空文字なら
      return '';
    }
    // X,Y,Z,A,B.Cの時
    if (singleKeys.indexOf(firstChar) >= 0) {
      return firstChar;
    }
    // 主軸(SP)の時
    if (key.length >= 2 && key.slice(0, 2) === 'SP') {
      return 'SP';
    }
    // いずれにも一致しない場合は空文字を返す
    return '';
  }

  /**
   * assetScore(小分類)をソートするための補助関数
   * @param assetScore1
   * @param assetScore2
   * @returns
   */
  private compareLongKey(assetScore1: AssetScore, assetScore2: AssetScore): number {
    if (assetScore1.assetKey > assetScore2.assetKey) {
      return 1;
    } else {
      return -1;
    }
  }
}

/////////// AssetBox以下とAssemblyUnit以上は切り離したオブジェクトと考えるといい /////////////////
/////////// AssetBox、AssetScoreはAssetValueがあれば成立するが、AssemblyUnitはAssetsがないと成立しない //////

/////////// AssetValueを使ってAssetBoxを作り、AssemblyUnitに渡す方法と、AssemblyUnit内のAssetsでAssetBoxを作る方法がある /////////

/**
 * shortKey(SP, X, Alarmなど)の箱
 * 内部のAssetScoreがlongKey(X1_1など)の箱
 */
export class AssetBox {
  shortkey: string;
  num: number;
  units: AssetScore[];

  constructor(shortKey: string) {
    this.shortkey = shortKey;
    this.num = 0;
    this.units = [];
  }
  addAssetScore(longKey: string, asset: AssetValue): void {
    this.units.push(new AssetScore(longKey, asset));
    this.num++;
  }
}

//////////////////// AssetScoreってAssetValueと一緒じゃね？ ///////////////////
/**
 * longKey(X1_1, Alarmなど)の箱
 * 各assetの異常値と異常値状態を保持
 * assetKeyがlongKeyに対応
 * scoreがなくなり、直接statusが入ることになる
 */
export class AssetScore {
  assetKey: string;
  status: AnomalyStatus;
  displayName: string;

  // 引数のscoreがstatusになるので変更ロジックが不要になるはず
  // このstatusはhttpで取得したmachineListの中に入っているstatus
  constructor(key: string, asset: AssetValue) {
    this.assetKey = key;
    this.status = asset.LatestStatus;
    this.displayName = asset.DisplayName;
  }
}

/**
 * 異常値の状態定義
 */
export enum AnomalyStatus {
  normal, // 正常
  caution, // 警告
  alert, // 異常
  alarm, // アラーム用
  alarmFalse, // HttpMachineのAlarmがFalse
  all, // Allアイコン用
}

/**
 * 文字列のstatusをenumのAnomalyStatusにマップするオブジェクト
 * APIから取得したステータスは文字列である
 */
export const statusMap = {
  Normal: AnomalyStatus.normal,
  Caution: AnomalyStatus.caution,
  Alert: AnomalyStatus.alert,
  Alarm: AnomalyStatus.alarm,
  All: AnomalyStatus.all,
};

/**
 * booleanのAlarm.LatestStatusをenumのAnomalyStatusに変えるマップ
 */
export const alarmMap = {
  true: AnomalyStatus.alarm,
  false: AnomalyStatus.alarmFalse,
};
