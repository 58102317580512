// ---------------------------------------------------------------------
// <copyright file=|`"role.guard.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CognitoService } from './../service/cognito.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private cognito: CognitoService,
  ) {}

  canActivate(
    // 管理者以外にAccountComponentを表示させない。plantに直接誘導する。
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.cognito.isAdminObservable().pipe(
      map((isAdmin: boolean) => {
        if (isAdmin) {
          return true; // 管理者の場合、アクセス許可
        } else {
          // 非管理者の場合、PlantComponentにリダイレクト
          return this.router.createUrlTree(['/plant']); // リダイレクト先を設定
        }
      }),
    );
  }
}
