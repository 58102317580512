// ---------------------------------------------------------------------
// <copyright file="machine.service.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  HttpMachineList,
  IntermediateMachineList,
  Machine,
  PostMachineComment,
  PostMachineCommentUserAccessibility,
} from '../model/machine';
import { HttpAlarm } from '../model/alarm';
import { HttpService } from './http.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * accountID、plantIDに対する、契約済み機械の情報を管理するサービス。
 * 機械リスト、選択済み機械、選択済み軸を管理する。machineListコンポーネント、machineInfoコンポーネントの子コンポーネントが使用する。
 */
@Injectable({
  providedIn: 'root',
  deps: [HttpService, TranslateService],
})
export class MachineService {
  constructor(
    private httpService: HttpService,
    private translateService: TranslateService,
  ) {}

  /**
   * TranslateServiceでポジションのラベル(位置、回転時間、角度など)を取得するためのキーを返す
   */
  public getPositonKeyforTranslate(assetKey: string, machine: Machine): string {
    const assetType: string = machine.Assets[assetKey].AssetType;
    const translateKey: string = 'position.' + assetType;

    return translateKey;
  }

  // 以降は各コンポーネントが画像や、それに付随する情報を取得する時のメソッド。
  // URLについて\
  // APIのURLはpathが3単語以上になるものも多く可視性のため - で区切ることにしている

  public fetchMachineList(body): Observable<Machine[]> {
    return this.httpService.httpPostRequest<HttpMachineList>('/machine-list', body).pipe(
      // stateサービスにデータを渡してから、machineListだけのobservableを返す
      map((response: HttpMachineList) => {
        const response_instance = new IntermediateMachineList(response);
        const machineList = response_instance.MachineList;
        return machineList;
      }),
    );
  }
  /**
   * 機械コメント登録メソッド
   */
  public postMachineComment(body): Observable<PostMachineComment> {
    return this.httpService.httpPostRequest<PostMachineComment>('/machine-comment', body);
  }
  /**
   * 機械コメント表示切替メソッド
   */
  public postMachineCommentUserAccessiblity(body): Observable<PostMachineCommentUserAccessibility> {
    return this.httpService.httpPostRequest<PostMachineCommentUserAccessibility>(
      '/machine-comment-user-accessibility',
      body,
    );
  }
  /**
   * アラーム取得メソッド
   */
  public fetchAlarm(ID: string, year?: string, month?: string): Observable<HttpAlarm> {
    // アラーム
    if (year && month) {
      // 期間を指定する場合
      return this.httpService.httpGetRequest<HttpAlarm>('/alarms/' + ID + '/?year=' + year + '&month=' + month);
    } else {
      return this.httpService.httpGetRequest<HttpAlarm>('/alarms/' + ID);
    }
  }

  /**
   * タイムスタンプリストとポジション幅を取得するメソッド
   */
  public fetchTimestampListandPosition(machineID: string, assetKey: string): Observable<HttpTimePosition> {
    return this.httpService.httpGetRequest<HttpTimePosition>(
      '/timestamp-list-position-step/' + machineID + '?assetkey=' + assetKey,
    );
  }

  /**
   * 全軸要約画像取得メソッド
   */
  public fetchAllAssetsDiagnosisImages(machineID: string): Observable<HttpImageInfoList> {
    const lang: string = this.translateService.currentLang ? this.translateService.currentLang : 'ja';
    return this.httpService.httpGetRequest<HttpImageInfoList>(
      '/all-assets-image-info-list/' + machineID + '?lang=' + lang,
    );
  }

  /**
   * 単一軸の最新画像取得メソッド
   */
  public fetchLatestImage(machineID: string, assetKey: string): Observable<HttpImageURL> {
    return this.httpService.httpGetRequest<HttpImageURL>('/latest-image/' + machineID + '?assetkey=' + assetKey);
  }

  /**
   * 単一軸の全時間画像取得メソッド
   */
  public fetchAllTimeDiagnosisImage(machineID: string, assetKey: string) {
    return this.httpService.httpGetRequest<HttpImageURLs>('/all-time-image/' + machineID + '?assetkey=' + assetKey);
  }

  /**
   * 単一軸の指定した時間の画像取得メソッド
   */
  public fetchPastTimeImage(machineID: string, assetKey: string, timeStamp: string) {
    // タイムスタンプはURL用にエンコードする (+ があるため)
    timeStamp = encodeURIComponent(timeStamp);

    return this.httpService.httpGetRequest<HttpImageURL>(
      '/past-image/' + machineID + '?assetkey=' + assetKey + '&timestamp=' + timeStamp,
    );
  }

  /**
   * 単一軸の指定したポジションの異常度グラフ取得メソッド
   */
  public fetchPositionAdjustedAnomalyGraph(
    machineID: string,
    assetKey: string,
    position: string,
  ): Observable<HttpImageURL> {
    return this.httpService.httpGetRequest<HttpImageURL>(
      '/position-adjusted-image/' + machineID + '?assetkey=' + assetKey + '&position=' + position,
    );
  }

  /**
   * 単一軸の指定した時間の生データ画像取得メソッド
   */
  public fetchRawCurrentImage(machineID: string, assetKey: string, timeStamp: string): Observable<HttpImageURL> {
    // タイムスタンプはURL用にエンコードする (+ があるため)
    timeStamp = encodeURIComponent(timeStamp);

    return this.httpService.httpGetRequest<HttpImageURL>(
      '/raw-current-image/' + machineID + '?assetkey=' + assetKey + '&timestamp=' + timeStamp,
    );
  }

  /**
   * 単一軸の指定した時間、ポジションのFFT画像取得メソッド
   */
  public fetchFrequencyCharacteristicImage(
    machineID: string,
    assetKey: string,
    timeStamp: string,
    position: string,
  ): Observable<HttpImageURL> {
    // タイムスタンプはURL用にエンコードする (+ があるため)
    timeStamp = encodeURIComponent(timeStamp);

    return this.httpService.httpGetRequest<HttpImageURL>(
      '/frequency-characteristic-image/' +
        machineID +
        '?assetkey=' +
        assetKey +
        '&position=' +
        position +
        '&timestamp=' +
        timeStamp,
    );
  }
}

/**
 * 取得日時とポジション格納したHTTPレスポンスの型
 */
export interface HttpTimePosition {
  Result: boolean;
  Alarm: string;
  ErrorMessage: string;
  TimestampList: string[];
  PositionStep: number;
}

/**
 * 画像のURLを格納したHTTPレスポンスの型
 */
export interface HttpImageURL {
  Result: boolean;
  Alarm: string;
  ErrorMessage: string;
  URL: string;
}

/**
 * 画像のURLのリストを格納したHTTPレスポンスの型
 */
export interface HttpImageURLs {
  Result: boolean;
  Alarm: string;
  ErrorMessage: string;
  URLs: string[];
}

/**
 * 画像の情報(AssetKey, URL, AssetType)を格納したHTTPレスポンスの型
 */
export interface HttpImageInfoList {
  Result: boolean;
  Alarm: string;
  ErrorMessage: string;
  ImageInfoList: ImageInfo[];
}

/**
 * AssetKey(軸名)、URL、AssetTypeをまとめた型.
 * Infoとしてまとめて呼ぶ
 */
export interface ImageInfo {
  AssetKey: string;
  URL: string;
  AssemblyUnit: string;
  DisplayName: string;
}
