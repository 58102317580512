// ---------------------------------------------------------------------
// <copyright file=storage.service.ts company=WALC Inc.
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Injectable } from '@angular/core';
import { Account } from '../model/account';
import { Plant } from '../model/plant';
import { Machine } from '../model/machine';
import { CommonProperties } from '../model/common-properties';

/**
 * 永続データの管理をするサービス\
 * localStrorageとのやりとりを行い、コンポーネントの初期化に必要なデータを管理する。
 * また、アプリ起動中に各コンポーネントで状態を共有するためのObservableもこのサービスで使用する。
 * 他のサービスはステートレスとし、このサービスをinjectionして使用する
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  // changePasswordで一時的に使うuser情報
  // resetPasswordでも使うかも
  private user$: any;
  get user() {
    return this.user$;
  }
  set user(user: any) {
    this.user$ = user;
  }

  constructor() {}

  // ゲッタとセッタで書いてもいい

  public getAccount(): Account {
    return JSON.parse(localStorage.getItem('account'));
  }

  public setAccount(account: Account): void {
    localStorage.setItem('account', JSON.stringify(account));
  }

  public getAccountID(): string {
    return localStorage.getItem('accountid');
  }

  public setAccountID(accountID: string): void {
    localStorage.setItem('accountid', accountID);
  }

  public getPlant(): Plant {
    return JSON.parse(localStorage.getItem('plant'));
  }

  public setPlant(plant: Plant): void {
    localStorage.setItem('plant', JSON.stringify(plant));
  }

  public getPlantID(): string {
    return localStorage.getItem('plantid');
  }

  public setPlantID(plantID: string): void {
    localStorage.setItem('plantid', plantID);
  }

  public getMachine(): Machine {
    return JSON.parse(localStorage.getItem('machine'));
  }

  public setMachine(machine: Machine): void {
    localStorage.setItem('machine', JSON.stringify(machine));
  }

  public getMachineID(): string {
    return localStorage.getItem('machineid');
  }

  public setMachineID(machineID: string): void {
    localStorage.setItem('machineid', machineID);
  }

  public getAsset(): string {
    return localStorage.getItem('asset');
  }

  public setAsset(asset: string): void {
    localStorage.setItem('asset', asset);
  }

  /**
   * コンポーネント初期化データ群を返す。
   * localStorageに存在しないプロパティはnullになる。
   * @returns initProps
   */
  public getCommonProperties(): CommonProperties {
    const commonProperties = new CommonProperties(
      this.getAccount(),
      this.getPlant(),
      this.getMachine(),
      this.getAsset(),
    );

    return commonProperties;
  }

  // 機械詳細画面系のコンポーネントでMachineListAPIを叩く際に使用するhttpBodyを返すメソッド
  // 使う頻度が多いのとbodyに変更があった時のためにここにまとめる
  public getBodyforMachineList() {
    // null時のハンドリング方法が未確定
    const body = {
      AccountID: this.getAccount()?.AccountID,
      PlantID: this.getPlant()?.PlantID,
    };

    return body;
  }
}
