// ---------------------------------------------------------------------
// <copyright file="app-routing.module.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MachineListComponent } from './components/machine-list/machine-list.component';
import { LoginComponent } from './components/login/login.component';
import { BaseComponent } from './components/base/base.component';
import { AccountComponent } from './components/account/account.component';
import { PlantComponent } from './components/plant/plant.component';
import { AdvertisementComponent } from './components/advertisement/advertisement.component';
import { AllAssetsDiagnosisComponent } from './components/all-assets-diagnosis/all-assets-diagnosis.component';
import { SingleAssetDiagnosisComponent } from './components/single-asset-diagnosis/single-asset-diagnosis.component';
import { MachineAlarmComponent } from './components/machine-alarm/machine-alarm.component';
import { AuthGuard } from './guard/auth.guard';
import { RoleGuard } from './guard/role.guard';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SubmitSuccessComponent } from './components/submit-success/submit-success.component';
import { ErrorComponent } from './components/error/error.component';
import { MachineCommentComponent } from './components/machine-comment/machine-comment.component';
import { AdminTopComponent } from './components/admin-top/admin-top.component';

/**
 * ver.0.4.0
 * advertisementにログアウト機能がついているため、base下に置いている。
 * 開発メモ
 * 同じコンポーネントへ再ルーティングする際、そのコンポーネントと配下のコンポーネントは再度Initされる。
 */
const routes: Routes = [
  { path: '', component: BaseComponent, canActivate: [AuthGuard] },
  {
    path: 'admin-top',
    component: AdminTopComponent,
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'machine-comment',
    component: MachineCommentComponent,
    canActivate: [AuthGuard, RoleGuard],
  },
  { path: 'plant', component: PlantComponent, canActivate: [AuthGuard] },
  {
    path: 'dashboard',
    component: MachineListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'allassets',
    component: AllAssetsDiagnosisComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'singleasset',
    component: SingleAssetDiagnosisComponent,
    canActivate: [AuthGuard],
  },
  { path: 'alarm', component: MachineAlarmComponent, canActivate: [AuthGuard] },
  {
    path: 'advertisement',
    component: AdvertisementComponent,
    canActivate: [AuthGuard, RoleGuard],
  },
  { path: 'error', component: ErrorComponent },
  { path: 'login', component: LoginComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'success', component: SubmitSuccessComponent },
  // ワイルドカード、notFoundなど。絶対に一番最後に書くこと。
  { path: '**', redirectTo: 'error', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'ignore' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
