// ---------------------------------------------------------------------
// <copyright file=modal.service.ts company=WALC Inc.
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalContent } from '../model/modal';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalContent: ModalContent;
  private resultSubject = new Subject<boolean>();
  result$ = this.resultSubject.asObservable();

  setContent(modalContent: ModalContent) {
    this.modalContent = modalContent;
  }

  getModalID() {
    return this.modalContent.ModalID;
  }

  getMessage() {
    return this.modalContent.Message;
  }

  getInfo() {
    return this.modalContent.Info;
  }

  sendResult(result: boolean) {
    this.resultSubject.next(result);
  }
}
