<app-toolbar class="toolbar-only"></app-toolbar>
<!-- ローディングバー -->
<app-progress-bar class="progress-bar" [isLoading]="isLoading" *ngIf="isLoading"></app-progress-bar>

<!-- お客様選択 -->
<div class="d-flex flex-column justify-content-center align-items-center">
  <h2>
    <label for="select-box">お客様を選択してください</label><br />
    <select id="select-box" [(ngModel)]="selectedAccount" (ngModelChange)="onSelectAccount()">
      <option *ngFor="let acc of accountList" [ngValue]="acc">
        {{ acc.AccountName }}
      </option>
    </select>
    <p></p>
  </h2>
</div>

<!-- Plantごとの全機械の表示 -->
<div class="comment-editor d-flex flex-column">
  <tr *ngFor="let plantItem of plantMachineComments">
    <div *ngFor="let machines of plantItem | keyvalue">
      <h2>{{ machines.key }}</h2>
      <div *ngFor="let mc of machines.value">
        <!-- 機番の選択 -->
        <details class="machine-list-item">
          <summary class="summary-area">
            <table class="machineid-table">
              <tr>
                <td class="col-name">
                  <span *ngIf="mc.Name">{{ mc.Name }}</span>
                </td>
                <td class="col-id">{{ mc.MachineID }}</td>
                <td class="col-author">{{ mc.MachineComment.Author }}</td>
                <td class="col-ts">
                  {{ convertDate(mc.MachineComment.Timestamp) | date: 'medium' : 'JST' : 'ja' }}
                </td>
              </tr>
            </table>
            <!-- 既にコメントがある場合 -->
            <div
              *ngIf="mc.MachineComment.Comment"
              class="current-text-box"
              [class.user-accessibility]="mc.MachineComment.UserAccessibility"
              id="latest-comment"
            >
              {{ mc.MachineComment.Comment }}
            </div>
          </summary>
          <span *ngIf="mc.MachineComment.Comment">
            <p *ngIf="mc.MachineComment.UserAccessibility; else elseAccessiblilty">機械選択画面に表示中</p>
            <ng-template #elseAccessiblilty>機械選択画面には非表示</ng-template>
          </span>

          <!-- コメント削除画面 -->
          <div class="submit-button-area">
            <form
              *ngIf="mc.MachineComment.Comment"
              #currentMachineCommentForm="ngForm"
              (ngSubmit)="sendModalChangeAccessibility(mc.MachineComment.UserAccessibility, mc.MachineID, machines.key)"
            >
              <input
                type="submit"
                class="submit-button"
                value="表示切替"
                [disabled]="currentMachineCommentForm.invalid"
              />
            </form>
          </div>
          <!-- コメント入力画面 -->
          <form
            #machineCommentForm="ngForm"
            (ngSubmit)="sendModalUpdateComment(commentInput.value, mc.MachineID, machines.key)"
            for="commentInput"
          >
            <p></p>
            <textarea
              #commentInput
              class="input-text-box"
              id="commentInput"
              rows="4"
              cols="60"
              maxlength="400"
              required
              minlength="2"
              name="comment"
              ngModel
            ></textarea>
            <label for="commentInput">コメントを入力してください</label>
            <div class="submit-button-area">
              <input type="submit" class="submit-button" value="登録" [disabled]="machineCommentForm.invalid" />
            </div>
          </form>
        </details>
      </div>
    </div>
  </tr>
</div>

<!-- モーダル画面 -->
<div *ngIf="isModalVisible">
  <app-post-validation></app-post-validation>
</div>
