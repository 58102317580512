// ---------------------------------------------------------------------
// <copyright file=past-anomaly-image.component.ts company=WALC Inc.
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit, Input } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { parseDate } from 'src/app/model/timestamp-converter';
import { HttpImageURL } from 'src/app/service/machine.service';
import { Machine } from 'src/app/model/machine';
import { MachineService } from 'src/app/service/machine.service';
import { notFoundImageURL } from 'src/app/static/image';
import { tooltipImgPath } from './tooltip-props.json';
import { TranslateService } from '@ngx-translate/core';

/**
 * 指定したタイムスタンプの診断結果ヒートマップを表示するコンポーネント
 */
@Component({
  selector: 'app-past-anomaly-image',
  templateUrl: './past-anomaly-image.component.html',
  styleUrls: ['../../style/image-style.css', './past-anomaly-image.component.css'],
})
export class PastAnomalyImageComponent implements OnInit {
  @Input() selectedMachine: Machine;
  @Input() selectedAssetKey: string;
  @Input() selectedTimestamp: string;

  public displayURL: string = undefined; // 表示用URL
  public displayTimestamp: Date = undefined; // 画面表示用のタイムスタンプ
  public errorMessage: string = undefined;

  // TranslateServiceから取得する値たち
  public localeID: string = '';
  public tooltipMessages: string[] = [];
  public tooltipImgPath: string = tooltipImgPath;

  // onDestroy時にsubscribe解除をするためのオブジェクト。subscribe前にpipe内で takeUntil(this.unsubscribe$) とすれば良い。
  private unsubscribe$ = new Subject();

  // ローディングスピナー表示用のフラグ
  public isLoading: boolean = false; // 初期状態は非表示

  constructor(
    private machineService: MachineService,
    private translateService: TranslateService,
  ) {}

  /**
   * AllSampleAnomalyImageコンポーネント上でのTimestampの変化を、SingleAssetDiganosisコンポーネント経由で検知し、画像の取得を行う。
   */
  ngOnChanges(changes: SimpleChanges): void {
    // コンポーネント初期化時のonChanges時は処理をしないようにする
    if (changes.selectedMachine || changes.selectedAssetKey || changes.selectedTimestamp?.currentValue) {
      this.setImageURL(this.selectedMachine?.MachineID, this.selectedAssetKey, this.selectedTimestamp);
      this.displayTimestamp = parseDate(this.selectedTimestamp);
    }
  }

  ngOnInit(): void {
    // localeIDをngx-tranlateから読み取る
    // stereamなので言語の変化に応じて自動で更新される
    this.translateService
      .stream('localeID')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (localeID: string) => {
          this.localeID = localeID;
        },
        (error) => {
          console.log(error);
        },
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  /**
   * 電流の生データ画像を、APIから取得し、displayURLに格納するメソッド
   * @param machineID 機番
   * @param assetKey 軸名
   * @param timeStamp AllSampleAnomalyImageコンポーネントで選択されたタイムスタンプ
   */
  private setImageURL(machineID: string, assetKey: string, timeStamp: string): void {
    this.isLoading = true; // ローディングスピナー表示

    this.machineService
      .fetchPastTimeImage(machineID, assetKey, timeStamp)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: HttpImageURL) => {
          this.displayURL = response.URL || notFoundImageURL;
          // 正しく処理できたのでerrorMessageを削除する
          this.errorMessage = undefined;
          // ローディングスピナーを非表示に
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          // エラーメッセージを画面に表示するために格納
          this.errorMessage = error.message;
          this.displayURL = notFoundImageURL;
          // ローディングスピナーを非表示に
          this.isLoading = false;
        },
      );
  }

  /**
   * 表示画像のCSSクラスを返すメソッド
   * not-foundかどうかで判断する。
   * @returns CSSクラスのstring
   */
  public displayImageStyle(): string {
    if (this.displayURL === notFoundImageURL) {
      return 'img-not-found';
    } else {
      return 'img-found-90';
    }
  }
}
