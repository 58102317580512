<app-floor-header
  [selectedAccount]="commonProperties.account"
  [selectedPlant]="commonProperties.plant"
  [isLoading]="isLoading"
></app-floor-header>

<!-- リストの各項目をコンポーネントにしてもいいが、ngForで回せちゃうので、今の所しなくても困らない -->
<div class="d-flex flex-column machine-list-container">
  <!-- ここからヘッダ -->
  <div class="d-flex machine-list-common machine-list-header">
    <div class="col-name d-flex align-items-center header-text col-padding-x">
      {{ 'machineList.machineName' | translate }}
      <span class="d-inline custom-sort-btn-container">
        <button *ngIf="sortStatus?.sortKey === 1" class="custom-sort-bottun" (click)="sortName()">
          {{ sortStatus?.sortMark }}
        </button>
        <button *ngIf="sortStatus?.sortKey !== 1" class="custom-sort-bottun" (click)="sortName()">△</button>
      </span>
    </div>

    <!-- 縦線 -->
    <div class="vertical-line vertical-line-model"></div>

    <div class="col-model align-items-center header-text col-padding-x machine-model">
      {{ 'machineList.machineModel' | translate }}
      <span class="d-inline custom-sort-btn-container">
        <button *ngIf="sortStatus?.sortKey === 3" class="custom-sort-bottun" (click)="sortID()">
          {{ sortStatus?.sortMark }}
        </button>
        <button *ngIf="sortStatus?.sortKey !== 3" class="custom-sort-bottun" (click)="sortID()">△</button>
      </span>
    </div>

    <!-- 縦線 -->
    <div class="vertical-line vertical-line-id"></div>

    <div class="col-id align-items-center header-text col-padding-x machine-id">
      {{ 'machineList.machineID' | translate }}
      <span class="d-inline custom-sort-btn-container">
        <button *ngIf="sortStatus?.sortKey === 3" class="custom-sort-bottun" (click)="sortID()">
          {{ sortStatus?.sortMark }}
        </button>
        <button *ngIf="sortStatus?.sortKey !== 3" class="custom-sort-bottun" (click)="sortID()">△</button>
      </span>
    </div>

    <!-- 縦線 -->
    <div class="vertical-line"></div>

    <div class="col-ts d-flex align-items-center header-text-sm col-padding-x">
      {{ 'machineList.lastDate' | translate }}
      <span class="d-inline custom-sort-btn-container">
        <button *ngIf="sortStatus?.sortKey === 4" class="custom-sort-bottun" (click)="sortDate()">
          {{ sortStatus?.sortMark }}
        </button>
        <button *ngIf="sortStatus?.sortKey !== 4" class="custom-sort-bottun" (click)="sortDate()">△</button>
      </span>
    </div>

    <!-- 縦線 -->
    <div class="vertical-line"></div>

    <div class="col-icon d-flex align-items-center header-text col-padding-x">
      {{ 'machineList.status' | translate }}
      <span class="d-inline custom-sort-btn-container">
        <button *ngIf="sortStatus?.sortKey === 2" class="custom-sort-bottun" (click)="sortAnomaly()">
          {{ sortStatus?.sortMark }}
        </button>
        <button *ngIf="sortStatus?.sortKey !== 2" class="custom-sort-bottun" (click)="sortAnomaly()">△</button>
      </span>
    </div>
  </div>
  <!-- ここまでヘッダ -->

  <!-- ここからボディ -->
  <div
    class="d-flex machine-list-common machine-list-item"
    *ngFor="let machine of machineList | slice: begin : begin + length"
    (click)="selectMachine(machine)"
  >
    <!-- 機械名 -->
    <div class="d-flex align-items-center col-name col-padding-x custom-overflow-wrap body-text">
      {{ machine.Name }}
    </div>
    <!-- 機種 -->
    <div class="align-items-center col-model col-padding-x custom-overflow-wrap body-text-md machine-model">
      {{ machine.MachineModel }}
    </div>

    <!-- 機番 -->
    <div class="align-items-center col-id col-padding-x custom-overflow-wrap body-text-md machine-id">
      {{ machine.MachineID }}
    </div>

    <!-- タイムスタンプが正しく取得できている時の表示 -->
    <div
      class="d-flex align-items-center col-ts col-padding-x custom-overflow-wrap body-text-sm"
      *ngIf="formatDateFromISO(machine.LatestTimestamp); else nullTS"
    >
      <!-- date -->
      {{
        formatDateFromISO(machine.LatestTimestamp)
          | date: 'mediumDate' : 'UTC+' + machine.LatestTimestamp.slice(-4) : localeID
      }}<br />
      {{
        formatDateFromISO(machine.LatestTimestamp)
          | date: 'shortTime' : 'UTC+' + machine.LatestTimestamp.slice(-4) : localeID
      }}
    </div>
    <!-- 正しく取得できなかった場合 -->
    <ng-template #nullTS>
      <div class="d-flex align-items-center col-ts col-padding-x custom-overflow-wrap body-text-sm">N/A</div>
    </ng-template>

    <!-- 異常度アイコン -->
    <div class="d-flex col-icon justify-content-start">
      <app-anomaly-icon [machine]="machine" [mode]="'list'"></app-anomaly-icon>
    </div>

    <!-- コメントの仕切り線。画面サイズによってはdisplay: noneにする -->
    <hr class="split-line" *ngIf="machine.MachineComment?.UserAccessibility && machine.MachineComment?.Comment" />

    <!-- 機械コメント -->
    <!-- 画面サイズによって占有領域が異なり、wrapされるかどうかが決まる -->
    <div
      class="d-flex flex-column justify-content-start align-items-start col-comment"
      *ngIf="machine.MachineComment?.UserAccessibility && machine.MachineComment?.Comment"
      (click)="onClickComment($event)"
    >
      <!-- このspanはサイズによって表示の仕方変わる？ -->
      <span class="comment-title">
        {{ 'machineList.commentAuthor' | translate }} &nbsp;
        {{
          formatDateFromISO(machine.MachineComment?.Timestamp)
            | date: 'medium' : 'UTC+' + machine.MachineComment?.Timestamp.slice(-4) : localeID
        }}
      </span>

      <!-- コメント本体 -->
      <div class="d-flex comment-body">
        {{ machine.MachineComment?.Comment }}
      </div>
    </div>
  </div>
  <!-- ここまでボディ -->

  <app-pagination [length]="length" [maxLength]="machineList?.length" (event)="changePage($event)"></app-pagination>
</div>
