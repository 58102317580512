// ---------------------------------------------------------------------
// <copyright file=machine-comment.component.ts company=WALC Inc.
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Account } from 'src/app/model/account';
import { ModalContent } from 'src/app/model/modal';
import { Plant } from 'src/app/model/plant';
import { FloorService } from 'src/app/service/floor.service';
import { MachineService } from 'src/app/service/machine.service';
import { ModalService } from 'src/app/service/modal.service';
import { RouteProcessService } from 'src/app/service/route-process.service';
import { Machine, PostMachineComment, PostMachineCommentUserAccessibility } from '../../model/machine';
import { safeDateFromISO } from 'src/app/model/timestamp-converter';
import { PlantMachines } from 'src/app/model/plant-machines';

// MachineComment更新APIは処理によって叩くものが異なる。その識別子。
enum apiIdentifier {
  UpdateComment,
  ChangeAccessibility,
}

/**
 * 機械コメントの登録、管理を行う管理者画面
 */
@Component({
  selector: 'app-machine-comment',
  templateUrl: './machine-comment.component.html',
  styleUrls: ['./machine-comment.component.css'],
})
export class MachineCommentComponent implements OnInit {
  // ローディングバー表示用のフラグ。floor-header経由でprogress-barに渡す
  public isLoading: boolean = false; // 初期状態は非表示

  // 画面に表示するaccountList
  public accountList: Account[] = undefined;
  public selectedAccount: Account = undefined;

  // 工場リスト格納用変数
  public plantList: Plant[] = undefined;
  // 工場ごとに表示する機械リスト
  public plantMachineComments: PlantMachines[] = [];

  // onDestroy時にsubscribe解除をするためのオブジェクト。subscribe前にpipe内で takeUntil(this.unsubscribe$) とすれば良い。
  private unsubscribe$ = new Subject();

  // post確認画面モーダル表示用
  public isModalVisible: boolean = false;

  constructor(
    private floorService: FloorService,
    private machineService: MachineService,
    private routeProcessService: RouteProcessService,
    public modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true; // HTTP通信開始時にローディングバーを表示

    // APIからアカウントリストを取得する
    this.floorService
      .fetchAccountList()
      .pipe(
        takeUntil(this.unsubscribe$), // onDestroy時に購読を解除するトリガー
      )
      .subscribe(
        (accountList: Account[]) => {
          this.accountList = accountList;
          //初期値
          this.selectedAccount = accountList[0];
          // plantリストを取得し、後続処理を行う
          this.getPlantList(this.selectedAccount);
        },
        (error) => {
          console.log(error);
          this.isLoading = false; // ローディングバー非表示
          this.routeProcessService.navigateError();
        },
      );

    // モーダル画面で更新かキャンセルが選択された時に動く
    this.modalService.result$
      .pipe(
        takeUntil(this.unsubscribe$), // onDestroy時に購読を解除するトリガー
      )
      .subscribe(
        (result: boolean) => {
          if (result) {
            this.switchPostMethod(this.modalService.getModalID(), this.modalService.getInfo());
          }
          this.isModalVisible = false;
        },
        (error) => {
          console.log(error);
          this.isModalVisible = false;
        },
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  /**
   * 引数のアカウントが持つ工場のリストを取得する。
   * 初期化時とアカウント変更時に叩く.
   * 内部で機械リスト取得のsubscribeもしており、ネストが発生してしまっている
   * @param account
   */
  public getPlantList(account: Account): void {
    this.isLoading = true; // HTTP通信開始時にローディングバーを表示

    this.floorService
      .fetchPlantList(account.AccountID)
      .pipe(
        takeUntil(this.unsubscribe$), // onDestroy時に購読を解除するトリガー
      )
      .subscribe(
        (plantList: Plant[]) => {
          this.plantList = plantList;

          this.plantMachineComments = plantList.map((plant: Plant) => ({
            [plant.PlantName]: [],
          })); // plantで初期化

          plantList.map((plant: Plant) => this.updateMachineList(plant)); // 上のplantMachineCommentsを変更する
          this.isLoading = false; // ローディングバー非表示
        },
        (error) => {
          console.log(error);
          this.isLoading = false; // ローディングバー非表示
          this.routeProcessService.navigateError();
        },
      );
  }

  /**
   * 引数の向上に対応した機械リストを取得し、表示する変数の更新を行う
   * ここがsubscribeネストの終わり。
   * @param plant
   */
  private updateMachineList(plant: Plant): void {
    // HTTPリクエストのリクエストBodyを作成
    const body = {
      AccountID: plant.AccountID,
      PlantID: plant.PlantID,
    };

    // MachineListをAPIから取得
    this.machineService
      .fetchMachineList(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (machineList: Machine[]) => {
          // 変数名これでいいんですかね？
          //
          const mcObj = this.plantMachineComments.find((obj) => obj[plant.PlantName] !== undefined);
          if (mcObj) {
            mcObj[plant.PlantName] = machineList; // 上書き
          }
        },
        (error) => {
          console.log(error);
          this.isLoading = false; // HTTP通信完了時にローディングバーを非表示
        },
      );
  }

  /**
   * 機械コメントをPostする。表示用変数の更新もする。
   * @param body
   * @param selectedPlant
   */
  private postMachineComment(body: PostMachineComment, selectedPlant: Plant): void {
    this.isLoading = true;

    // コメント投稿
    this.machineService
      .postMachineComment(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        // 投稿失敗の時はエラーに遷移する
        () => {
          this.updateMachineList(selectedPlant); //機械のみを取得する方法がないので工場ごと更新
          this.isLoading = false; // HTTP通信完了時にローディングバーを非表示
        },
        (error) => {
          console.log(error);
          this.isLoading = false; // HTTP通信完了時にローディングバーを非表示
        },
      );
  }

  /**
   * コメントにユーザがアクセス(取得)できるかのboolを更新する。
   * 表示用変数の更新もする
   * @param body
   * @param selectedPlant
   */
  private postMachineCommentUserAccessibility(body: PostMachineCommentUserAccessibility, selectedPlant: Plant): void {
    this.isLoading = true;

    this.machineService
      .postMachineCommentUserAccessiblity(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        // 変更失敗の時はエラーに遷移する
        () => {
          this.updateMachineList(selectedPlant); //機械のみを取得する方法がないので工場ごと更新
          this.isLoading = false; // HTTP通信完了時にローディングバーを非表示
        },
        (error) => {
          console.log(error);
          this.isLoading = false; // HTTP通信完了時にローディングバーを非表示
        },
      );
  }

  /**
   * モーダルでコメント更新の可否の確認後、可の時に叩くAPIを判定するメソッド
   * @param modalID
   * @param httpInfo
   */
  public switchPostMethod(modalID: apiIdentifier, httpInfo: any) {
    switch (modalID) {
      case apiIdentifier.UpdateComment:
        httpInfo = this.modalService.getInfo();
        this.postMachineComment(httpInfo.Body, httpInfo.Plant);
        break;
      case apiIdentifier.ChangeAccessibility:
        httpInfo = this.modalService.getInfo();
        this.postMachineCommentUserAccessibility(httpInfo.Body, httpInfo.Plant);
        break;
      default:
        break;
    }
  }

  /**
   * 「コメント登録」の時に叩かれるメソッド
   * @param MachineComment
   * @param MachineID
   * @param plantName
   */
  public sendModalUpdateComment(MachineComment: string, MachineID: string, plantName: string) {
    // 作成中のコメントと工場の情報
    const body: PostMachineComment = { Comment: MachineComment, MachineID: MachineID };
    const selectedPlant = this.plantList.find((obj) => obj.PlantName === plantName); // コメントを登録しているplant取得
    const newMachineComment = { Plant: selectedPlant, Body: body };
    const modalID: apiIdentifier = apiIdentifier.UpdateComment;

    // 上の情報をまとめる
    const modalContent: ModalContent = {
      ModalID: modalID,
      Message:
        '機番:<br />' +
        MachineID +
        '<br />作成したコメント:<br />' +
        MachineComment +
        '<br /><h3>作成したコメントに更新しますか？</h3>',
      Info: newMachineComment,
    };

    // モーダル使用
    this.modalService.setContent(modalContent);
    this.isModalVisible = true;
  }

  /**
   * 「コメントにユーザがアクセス(取得)できるかのboolを更新する」時に実際に叩かれるメソッド
   * @param currentAccessibility
   * @param MachineID
   * @param plantName
   */
  public sendModalChangeAccessibility(currentAccessibility: boolean, MachineID: string, plantName: string) {
    // 作成中のコメントと工場の情報
    const body: PostMachineCommentUserAccessibility = {
      UserAccessibility: !currentAccessibility,
      MachineID: MachineID,
    };
    const selectedPlant = this.plantList.find((obj) => obj.PlantName === plantName); // コメントを登録しているplant取得
    const newMachineCommentUserAccessibility = { Plant: selectedPlant, Body: body };
    const modalID: apiIdentifier = apiIdentifier.ChangeAccessibility;
    const message = currentAccessibility ? '非表示にしますか' : '表示させますか';

    // 上の情報をまとめる
    const modalContent: ModalContent = {
      ModalID: modalID,
      Message: '機番:<br />' + MachineID + '<br /><h3>コメントを' + message + '？</h3>',
      Info: newMachineCommentUserAccessibility,
    };

    // モーダル使用
    this.modalService.setContent(modalContent);
    this.isModalVisible = true;
  }

  /**
   * ISO日付をDate型に変換する。\
   * HTMLから叩く経由メソッド。
   * @param strDate
   * @returns
   */
  public convertDate(strDate?: string): Date {
    return safeDateFromISO(strDate);
  }

  /**
   * 画面でアカウントを選択した時に叩かれるトリガー
   */
  public onSelectAccount(): void {
    this.getPlantList(this.selectedAccount);
  }
}
