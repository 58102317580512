// ---------------------------------------------------------------------
// <copyright file="auth.guard.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CognitoService } from './../service/cognito.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private cognito: CognitoService,
  ) {}

  canActivate(
    /*
    cognitoサービスからログイン状態を取得し、非ログイン状態の場合はLoginComponentへ遷移する。
    */
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.cognito.isAuthenticated().pipe(
      tap((loggedIn: boolean) => {
        if (!loggedIn) {
          localStorage.clear();
          this.router.navigate(['login']);
        }
      }),
    );
  }
}
