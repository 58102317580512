// ---------------------------------------------------------------------
// <copyright file="machine.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { alarmMap, statusMap } from './anomaly-unit';
import { AnomalyStatus } from './anomaly-unit';

/**
 * APIから取得したbodyの型。
 */
export interface HttpMachineList {
  MachineList: HttpMachine[];
  Count: number;
  Result: boolean;
  Alarm: string;
  ErrorMessage: string;
}

/**
 * APIから取ったMachineListの型。
 * 実際に画面で使うMachineと少し異なるので、区別するために作った。
 * InterMadiateMachineListに通すことでMachineに変換される。
 */
export interface HttpMachine {
  MachineID: string;
  MachineModel: string;
  Name: string;
  LatestTimestamp: string;
  Assets: HttpAssets; // これをAnomalyStatus型に変換するメソッドを設けるべきだった。
  MachineComment: MachineComment;
}

/**
 * APIから取ったAssetsの型。
 * 実際に画面で使うAssetsと少し異なるので、区別するために作った。
 * InterMadiateMachineListに通すことでAssetsに変換される。
 */
export interface HttpAssets {
  [key: string]: {
    LatestStatus: any; // 軸の時はstring, alarmはbool
    AssetType: string | null; // alarmのみnull
    AssemblyUnit: string;
    DisplayName: string;
  };
}

/**
 * MachineListの変換の都合で作ることになったクラス。どうリファクタリングしよう
 */
export class IntermediateMachineList {
  MachineList: Machine[] = [];
  // このコンストラクタどこまで必要だろうか
  constructor(httpMachines: HttpMachineList) {
    if (!httpMachines.Result) this.MachineList = [];

    // for文でhttpMachinesの各HttpMachineを、Machineにマッピングする
    for (const httpMachine of httpMachines.MachineList) {
      // Machineオブジェクトを生成 -> 登録を繰り返す
      const newMachine = new Machine();

      // まず変更の必要のない属性をnewMachineに入れる。
      // プリミティブデータの代入を繰り返しているが今回はしょうがないとする。
      newMachine.MachineID = httpMachine.MachineID;
      newMachine.MachineModel = httpMachine.MachineModel;
      newMachine.Name = httpMachine.Name;
      newMachine.LatestTimestamp = httpMachine.LatestTimestamp;
      newMachine.MachineComment = httpMachine.MachineComment;

      // assetKeyでforループし、Assetsへの格納処理
      // MaxAnomalyも計算する
      const anomalyStatuses: AnomalyStatus[] = []; // MaxAnomaly計算用配列
      for (const assetKey in httpMachine.Assets) {
        // newMachine.Assetsの一属性を作成し、格納
        newMachine.setAsset(assetKey, httpMachine.Assets);

        // Alarmとそれ以外で挙動が異なる
        if (assetKey !== 'Alarm') {
          anomalyStatuses.push(statusMap[httpMachine.Assets[assetKey].LatestStatus]); // "alert" などのstringをenumerateに変換したはず。
        }
      }
      // MaxAnomaly計算
      newMachine.MaxAnomaly = Math.max(...anomalyStatuses);

      // 格納
      this.MachineList.push(newMachine);
    }
  }
}

/**
 * 実際に画面で使うMachineクラス
 */
export class Machine {
  MachineID: string;
  MachineModel: string;
  Name: string;
  LatestTimestamp: string;
  Assets: Assets;
  MaxAnomaly?: number;
  MachineComment: MachineComment;

  constructor() {
    this.Assets = new Assets();
  }

  /**
   * プリミティブデータを引数にAssetの一つを作成する。
   * Assets型がキーが不明な都合でメソッドを定義できないためMachine内に設ける
   * AssetValueオブジェクトでもできるようにしたい。
   * @param assetKey
   * @param latestStatus
   * @param assetType
   */
  public setAsset(assetKey: string, assets: HttpAssets): void {
    const assetValue = new AssetValue();

    // アラームかどうかでMapがことなる
    if (assetKey !== 'Alarm') {
      assetValue.LatestStatus = statusMap[assets[assetKey].LatestStatus];
    } else {
      assetValue.LatestStatus = alarmMap[assets[assetKey].LatestStatus];
    }
    // AssetTypeはどのassetKeyでも共通の書き方
    assetValue.AssetType = assets[assetKey].AssetType;
    assetValue.AssemblyUnit = assets[assetKey].AssemblyUnit;
    assetValue.DisplayName = assets[assetKey].DisplayName;

    // 自身のAssetsに格納
    this.Assets[assetKey] = assetValue;
    return;
  }

  /**
   * AssetValueを引数に、Assetsの一つを作成する。
   * Assets型がキーが不明な都合でメソッドを定義できないためMachine内に設ける
   * AssetValueオブジェクトでもできるようにしたい。
   * @param assetKey
   * @param assetValue
   */
  public setAssetbyObject(assetKey: string, assetValue: AssetValue): void {
    // 自身のAssetsに格納
    this.Assets[assetKey] = assetValue;

    return;
  }
}

/**
 * 実際に画面で使うMachineクラスのAssetsクラス
 */
export class Assets {
  [key: string]: AssetValue;

  constructor() {}
}

export class AssetValue {
  LatestStatus: AnomalyStatus;
  AssetType: string;
  AssemblyUnit: string;
  DisplayName: string;

  constructor() {}
}

/**
 * 機械コメント用モデル。
 * Machineモデル内で使用する。
 */
export interface MachineComment {
  Comment: string;
  Author: string;
  Timestamp: string;
  UserAccessibility: boolean;
}

/**
 * HTTP POST用のMachineComentモデル。
 * MachineCommentAPIへ機械コメントの登録を使う。
 */
export interface PostMachineComment {
  MachineID: string;
  Comment: string;
}

/**
 * HTTP POST用のMachineComentモデル。
 * MachineCommentUserAccessibilityAPIへコメント表示の切り替え登録を行う。
 */
export interface PostMachineCommentUserAccessibility {
  MachineID: string;
  UserAccessibility: boolean;
}
