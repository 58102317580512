import { Component, OnInit } from '@angular/core';
import { CognitoService } from 'src/app/service/cognito.service';
import { RouteProcessService } from 'src/app/service/route-process.service';

/**
 * コンポーネントでエラーが発生した時に遷移する画面
 */
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css', '../../style/form-style.css'],
})
export class ErrorComponent implements OnInit {
  constructor(
    private routeProcessService: RouteProcessService,
    private cognitoService: CognitoService,
  ) {
    // この画面に来た段階でログアウトはする。
    // ログアウトボタンを押さずに閉じた時に状態遷移に問題が生じる可能性があるため。
    this.cognitoService.signOut(false);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public onClickLogout(): void {
    // ログアウト後にログアウトボタンを押しても問題はない
    this.routeProcessService.navigateLogin();
  }
}
