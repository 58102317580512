// ---------------------------------------------------------------------
// <copyright file=img-tooltip.directive.ts company=WALC Inc.
// (C) 2024 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, HostListener, Injector, Input } from '@angular/core';
import { ImgTooltipPosition } from '../model/img-tooltip-position';
import { ImgTooltipArgs, ImgTooltipComponent } from '../component-parts/img-tooltip/img-tooltip.component';

// Injection用の値のインポート。循環インポートを避けるため別ファイルにしている
import { TOOLTIP_OVERLAY_DATA } from '../static/tooltip-utils';

/**
 * 画像付きTooltipを表示させるディレクティブ。
 * ImgTooltipコンポーネントを表示させる。
 */
@Directive({
  selector: '[appImgTooltip]',
})
export class ImgTooltipDirective {
  // ツールチップで表示するメッセージ
  // 改行を入れたい場合、string配列を渡すと要素ごとに改行されるような仕様にする
  @Input() message?: string | string[];
  // 画像を挿入したい場合、assetsからのパスを指定する
  @Input() path?: string;
  // ツールチップをホバーした場所から見てどこに配置するか（上下左右、追加も可能）
  @Input() position?: ImgTooltipPosition;

  private overlayRef?: OverlayRef;

  // injectorとoverlayはDI
  constructor(
    private injector: Injector,
    private overlay: Overlay,
  ) {}

  // hoverしたイベントを捕捉
  @HostListener('mouseenter', ['$event'])
  onMouseenter($event: MouseEvent) {
    this.createTooltip($event);
  }

  // hoverが外れたイベントを捕捉
  @HostListener('mouseleave', ['$event'])
  onMouseleave() {
    if (this.overlayRef == null) {
      return;
    }

    // ツールチップ消す
    this.overlayRef.detach();
  }

  /**
   * Tooltip作成処理
   * @param $event
   */
  private createTooltip($event: MouseEvent) {
    // 先にcreateだけして、configは後で入れる
    this.overlayRef = this.overlay.create();

    // 表示用Componentに渡すデータの作成
    const args: ImgTooltipArgs = {
      message: this.message,
      path: this.path,
    };

    // Injectorを作成して渡すデータをuseValueで設定する
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: TOOLTIP_OVERLAY_DATA,
          useValue: args,
        },
      ],
    });

    // 呼び出し元が渡したpositionをConnectedPosition型に変換するMAP
    const positionMap: Map<ImgTooltipPosition, ConnectedPosition[]> = new Map<
      ImgTooltipPosition,
      ConnectedPosition[]
    >();
    positionMap.set('above', [{ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' }]);
    positionMap.set('below', [{ originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' }]);
    positionMap.set('left', [{ originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' }]);
    positionMap.set('right', [{ originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center' }]);

    // positionのconfig作成と適用
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo($event.target as Element)
      .withPositions(positionMap.get(this.position));

    this.overlayRef.updatePositionStrategy(positionStrategy);

    // 作成したInjectorを渡す
    // CustomTooltipComponentが、これ以降で作成するツールチップ表示用Componentです
    const componentPortal: ComponentPortal<ImgTooltipComponent> = new ComponentPortal(
      ImgTooltipComponent,
      null,
      injector,
    );

    // overlayの表示
    this.overlayRef.attach<ImgTooltipComponent>(componentPortal);
  }
}
