// ---------------------------------------------------------------------
// <copyright file=|`"reset-password.component.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CognitoService } from '../../service/cognito.service';
import { RouteProcessService } from 'src/app/service/route-process.service';
import { passwordMatchValidator } from 'src/app/model/form-validator';

/**
 * ユーザがパスワードをリセットする際の画面。
 */
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css', '../../style/form-style.css'],
})
export class ResetPasswordComponent implements OnInit {
  @Input() user;
  // @Output() event = new EventEmitter<{Status: string; User: string; ErrorMessage: string}>();
  public username: string = undefined;
  public emailForm: FormGroup = undefined;
  public resetPasswordForm: FormGroup = undefined;
  public errorMessage: string = undefined;
  public nowSubmit: boolean = false; // 通信中かのフラグ

  // onDestroy時にsubscribe解除をするためのオブジェクト。subscribe前にpipe内で takeUntil(this.unsubscribe$) とすれば良い。
  private unsubscribe$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private cognito: CognitoService,
    private routeProcessService: RouteProcessService,
  ) {}

  ngOnInit(): void {
    this.emailForm = this.fb.group({
      username: ['', Validators.required],
    });

    // htmlのinputタグの formControlName属性 と紐付けられる
    this.resetPasswordForm = this.fb.group({
      code: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });

    this.resetPasswordForm.setValidators(passwordMatchValidator('password', 'confirmPassword'));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  /**
   * confirmPasswordの入力監視メソッド
   * HTMLのアラート表示に使う
   * @returns
   */
  public isConfirmPasswordFilled(): boolean {
    return this.resetPasswordForm.get('confirmPassword').value !== '';
  }

  /**
   * ログイン画面へ戻る時の処理
   */
  public onClickLogin(): void {
    this.routeProcessService.navigateLogin();
  }

  /**
   * パスワードを変更したいユーザのユーザネームを入力し、送信ボタンを押した際に実行されるメソッド
   * @param value usernameが格納されたイベント変数
   */
  public onSubmitForgetPassword(value: any): void {
    this.username = value.username;
    this.cognito.forgetPassword(this.username);
  }

  /**
   * 変更したパスワードをcognitoに送信する際に実行されるメソッド
   * @param value 確認コード、変更したパスワードが格納されたイベント変数
   */
  public onSubmitResetPassword(value: any): void {
    this.nowSubmit = true;

    const code = value.code;
    const password = value.password;
    // let status: string;

    this.cognito
      .forgetPasswordSubmit(this.username, code, password)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (result) => {
          // UXの向上のためsuccessコンポーネントに移る
          this.nowSubmit = false;

          this.routeProcessService.navigateSuccess();
        },
        (error) => {
          // 通信中フラグを戻す
          this.nowSubmit = false;

          switch (error.code) {
            case 'CodeMismatchException':
              // 無効なコードが入力された場合に起こる。
              this.errorMessage = 'コードが無効です';
              console.log(error);
              break;
            case 'ExpiredCodeException':
              // コードが期限切れ（1時間をオーバー）した場合に起こる。
              this.errorMessage = 'コードの期限が切れています。';
              console.log(error);
              break;
            case 'InvalidParameterException':
              // ユーザープールのポリシーで設定したパスワードの強度を満たさない場合に起こる。
              this.errorMessage = 'パスワードが短いか数字、大文字、小文字のいずれかを含んでいません。';
              console.log(error);
              break;
            default:
              // その他のエラー
              console.log(error);
              break;
          }
        },
      );
  }
}
