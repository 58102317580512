// ---------------------------------------------------------------------
// <copyright file=img-tooltip.component.ts company=WALC Inc.
// (C) 2024 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Component, Inject, OnInit } from '@angular/core';
import { TOOLTIP_OVERLAY_DATA } from 'src/app/static/tooltip-utils';

/**
 * ディレクティブから渡される引数の型定義。
 * 呼び出し元コンポーネント -> ディレクティブ -> このコンポーネント の順で渡される
 */
export type ImgTooltipArgs = {
  message?: string | string[];
  path?: string;
};

/**
 * ポップアップ用のTooltipコンポーネント\
 * ImgTooltpディレクティブ経由で表示される。
 */
@Component({
  selector: 'app-img-tooltip',
  templateUrl: './img-tooltip.component.html',
  styleUrls: ['./img-tooltip.component.css'],
})
export class ImgTooltipComponent implements OnInit {
  // tooltipに表示する本文
  messages?: string[];

  // Injector参照用のトークンを指定することで、InjectorのuseValueを取ることができる
  constructor(@Inject(TOOLTIP_OVERLAY_DATA) public data: ImgTooltipArgs) {
    // messageは文字列か文字列配列のどちらかの可能性があるため、文字列配列で統一させる
    let messageArray: string[] = [];

    if (data.message != null) {
      // Translateパイプを使うと文字列配列を結合した一個の文字列になるので分割する
      if (typeof data.message === 'string') {
        messageArray = data.message.split('\n');
        // messageArrayには配列の要素の先頭に "," がつくので削除する
        messageArray = messageArray.map((messageLine: string) => {
          return messageLine[0] === ',' ? messageLine.substring(1) : messageLine;
        });
      } else {
        messageArray = data.message;
      }

      // 格納
      this.messages = messageArray;
    }
  }

  ngOnInit() {}

  /**
   * コンポーネント内の最外のコンテナのスタイルを返す。
   * HTMLから呼ばれる。
   * @returns
   */
  public createStyle() {
    // ディレクティブでできる設定が多かったのでwidthのハンドリングのみとなった。
    // この700pxは表示コンテナの上限サイズ
    return `
      z-index: 99;
      width: 700px;
    `;
  }
}
