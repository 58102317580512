// ---------------------------------------------------------------------
// <copyright file="keywordFinder.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
/**
 * キーワードを保持し、そのキーワードを含む文字列か判定する。
 */
export class KeywordFinder {
    keywords: string[] = [];
    /**
     * キーワードの追加
     * @param keyword キーワード
     */
    addKeyword( keyword: string ): void {
      if (keyword){
        if ( this.keywords.indexOf( keyword ) < 0 ) {
          this.keywords.push( keyword );
        }
      }
    }
    /**
     * キーワードのクリア
     */
    clearKeywords(): void {
      this.keywords = [];
    }
    /**
     * 保持している複数のキーワードのいずれかが、targetの文字列に
     * 含まれる場合は true を返す。
     * @param target 探索対象の文字列
     * @returns キーワードを含む場合は ture , キーワードを含まない場合は false を返す。
     */
    findKeywords( target: string ): boolean {
      for ( const keyword of this.keywords ) {
        if ( target.indexOf( keyword ) >= 0 ) {
          return true;
        }
      }
      return false;
    }
  }
