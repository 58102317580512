<div class="d-flex flex-column machine-info-toolbar">
  <div class="d-flex custom-row justify-content-start align-items-center toolbar-bottum-border">
    <app-machine-list-sidebar
      [machineList]="machineList"
      [selectedMachine]="selectedMachine"
      (machineEvent)="receiveMachine($event)"
    >
    </app-machine-list-sidebar>

    <span class="machine-text" *ngIf="selectedAssetKey !== 'All' && selectedAssetKey !== 'Alarm'"
      >{{ selectedMachine?.Name }} :
      {{ 'allAssetsDiagnosis' + '.' + selectedMachine?.Assets[selectedAssetKey]?.AssemblyUnit | translate }}
      {{ selectedMachine?.Assets[selectedAssetKey]?.DisplayName }}
    </span>
    <!-- Allの時 -->
    <span class="machine-text" *ngIf="selectedAssetKey === 'All'"
      >{{ selectedMachine?.Name }} : {{ 'allAssetsDiagnosis.All' | translate }}
    </span>
    <!-- Alarmの時 -->
    <span class="machine-text" *ngIf="selectedAssetKey === 'Alarm'"
      >{{ selectedMachine?.Name }} : {{ 'allAssetsDiagnosis.Alarm' | translate }}
    </span>
    <!--  -->
    <div class="d-flex align-items-center machine-model ms-auto">
      {{ selectedMachine?.MachineModel }}
    </div>
  </div>

  <div class="d-flex flex-row icon-padding justify-content-center custom-bottum-border">
    <app-anomaly-icon
      [machine]="selectedMachine"
      [selectedAssetKey]="selectedAssetKey"
      (assetKeyEvent)="receiveAssetKey($event)"
      [mode]="'info'"
    >
    </app-anomaly-icon>
  </div>
</div>
