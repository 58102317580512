// ---------------------------------------------------------------------
// <copyright file=locale.ts company=WALC Inc.
// (C) 2024 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

export class Locale {
  code: string = '';
  text: string = '';
  timeZone?: string;
}
