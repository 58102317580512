// ---------------------------------------------------------------------
// <copyright file=|`"plant.component.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Account } from 'src/app/model/account';
import { Plant } from 'src/app/model/plant';
import { StorageService } from 'src/app/service/storage.service';
import { RouteProcessService } from 'src/app/service/route-process.service';
import { FloorService } from 'src/app/service/floor.service';
import { CommonProperties } from 'src/app/model/common-properties';

/**
 * 工場の一覧(plantList)を表示するコンポーネント\
 * floorServiceのselectedAccountが保有している工場の一覧を表示する
 */
@Component({
  selector: 'app-plant',
  templateUrl: './plant.component.html',
  styleUrls: ['./plant.component.css', '../../style/account-plant-style.css'],
})
export class PlantComponent implements OnInit {
  // storageServiceから取得するプロパティ。初期化、子コンポーネントへ渡すデータのまとめに使う
  public commonProperties: CommonProperties = undefined;

  // 工場リスト格納用変数
  public plantList: Plant[] = undefined;

  // onDestroy時にsubscribe解除をするためのオブジェクト。subscribe前にpipe内で takeUntil(this.unsubscribe$) とすれば良い。
  private unsubscribe$ = new Subject();

  // ローディングバー表示用のフラグ。
  public isLoading: boolean = false; // 初期状態は非表示

  constructor(
    private floorService: FloorService,
    private storageService: StorageService,
    private routeProcessService: RouteProcessService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true; // ローディングバー表示

    // 初期化データの取得
    this.commonProperties = this.storageService.getCommonProperties();

    // storageServiceに存在しない値はnullとなる
    // nullならば適切にルーティングする(もしくはこの画面の場合はAPIを叩く)
    if (!this.commonProperties.account) {
      this.floorService
        .fetchAccount()
        .pipe(
          takeUntil(this.unsubscribe$), // onDestroy時に購読を解除するトリガー
        )
        .subscribe(
          (account: Account) => {
            // localStorageにaccountを保存する
            this.storageService.setAccount(account);
            this.commonProperties.account = account;
            this.isLoading = false; // ローディングバー非表示
          },
          (error) => {
            console.log(error);
            this.isLoading = false; // ローディングバー非表示
            this.routeProcessService.navigateError();
          },
        );
    }

    // plantListをAPIから取得する
    this.floorService
      .fetchPlantList(this.commonProperties.account.AccountID)
      .pipe(
        takeUntil(this.unsubscribe$), // onDestroy時に購読を解除するトリガー
      )
      .subscribe(
        (plantList: Plant[]) => {
          this.plantList = plantList;
          this.isLoading = false; // ローディングバー非表示
        },
        (error) => {
          console.log(error);
          this.isLoading = false; // ローディングバー非表示
          this.routeProcessService.navigateError();
        },
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }

  /**
   * 画面でplantを選択した時の処理
   * @param plant
   */
  public onClickSelectPlant(plant: Plant): void {
    this.routeProcessService.navigateMachineList(plant);
  }
}
