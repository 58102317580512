// ---------------------------------------------------------------------
// <copyright file="cognito.service.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { User } from '../model/user-info';

/**
 * コンポーネントテスト用のcognitoサービスモック
 */
@Injectable({
  providedIn: 'root',
})
export class CognitoServiceMock {
  private loggedInSubject = new BehaviorSubject<boolean>(false); // コンストラクタで初期化すること
  public readonly loggedInStream = this.loggedInSubject.asObservable(); // 外部から参照するobservable

  constructor() {}

  /**
   * user情報のObservableを返す。
   */
  public userObservable(): Observable<User> {
    const user = new User();

    user.setUser({
      'custom:accountID': '000001',
      'custom:role': 'User',
      name: 'Guest',
      email: 'hoge@walc.co.jp',
    });

    return of(user);
  }

  /////////////////// ログインログアウトの状態変更 ///////////////////////////

  /**
   * ログアウト -> ログイン時の状態変化をまとめる。
   * loginしているかどうかのstreamを更新するだけなので、プライバシー情報にpublicメソッドを適用しているわけではない。
   */
  public setAuthenticated(): void {
    this.loggedInSubject.next(true);
  }

  // ログイン -> ログアウト時の状態変化をまとめる
  public setNotAuthenticated(): void {
    this.loggedInSubject.next(false);
  }

  /////////////////////////////// 便利なObservableを返す系 //////////////////////////////

  /**
   * ログイン状態のObservableを返すメソッド
   * コンポーネントが扱いやすくするためにObservableに変換している。
   * 外部サービスがログイン状態を取得するなどsubscribeが使いづらい場合は Auth.currentAuthenticatedUser().then() でPromiseのまま処理できるべき。
   *
   * async pipeで取ると無限実行されるので、コンポーネント側に中間observable入れること！
   * @returns
   */
  public isAuthenticated(): Observable<boolean> {
    return of(true);
  }

  /**
   * role guard用
   * Observablerで非同期でroleを確認するメソッド。
   * guardは非同期で処理しないと、urlpathに直接アクセスした際にcognitoServiceで不整合が起きてしまう。
   *
   * async pipeで取ると無限実行されるので、コンポーネント側に中間observable入れること！
   * @returns
   */
  public isAdminObservable(): Observable<boolean> {
    return of(true);
  }

  /////////////////// 画面処理をcognitoに転送する系 //////////////////////////

  // Send confirmation code to user's email
  public forgetPassword(username: string): Observable<any> {
    return of(null);
  }

  // Collect confirmation code and new password, then
  public forgetPasswordSubmit(username: string, code: string, newPassword: string): Observable<any> {
    return of(null);
  }

  // // 認証コードの確認
  public verifyUserAttributeSubmit(user, verificationCode): Observable<any> {
    return of(null);
  }

  // // 認証コードの再送信
  public verifyUserAttribute(user): void {}

  // // 初回ログイン時のパスワードとユーザー情報の変更
  public completeNewPassword(user, password): Observable<any> {
    return of(null);
  }

  /**
   * ログイン
   * ログインしただけだとパスワード変更requiredがtrueになっている可能性がある。
   * この場合ログインしたことにはならないのでsetAuthenticatedはできない。
   * signIn呼び出し元のコンポーネントがsetAuthnticatedする。
   * @param email
   * @param password
   * @returns
   */
  public signIn(email: string, password: string): Observable<any> {
    return of(null);
  }

  /**
   * ログアウト処理。ログイン画面に戻るかどうかを一応決められるようにしている。
   * @param navigateLogin ログイン画面に遷移するかどうか。値を入れない場合=undefined なのでfalseと一緒
   */
  public signOut(navigateLogin: boolean = true): void {}
}
