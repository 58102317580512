// ---------------------------------------------------------------------
// <copyright file=common-properties.ts company=WALC Inc.
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Account } from './account';
import { Machine } from './machine';
import { Plant } from './plant';

/**
 * 各コンポーネントが使う共通データをまとめたクラス。
 * onInit時や子コンポーネントに渡すデータで頻繁に使うものがまとめられている。
 */
export class CommonProperties {
  private account$: Account;
  private plant$: Plant;
  private machine$: Machine;
  private assetKey$: string;

  get account(): Account {
    return this.account$;
  }
  get plant(): Plant {
    return this.plant$;
  }
  get machine(): Machine {
    return this.machine$;
  }
  get assetKey(): string {
    return this.assetKey$;
  }

  set account(account: Account) {
    this.account$ = account;
  }
  set plant(plant: Plant) {
    this.plant$ = plant;
  }
  set machine(machine: Machine) {
    this.machine$ = machine;
  }
  set assetKey(assetKey: string) {
    this.assetKey$ = assetKey;
  }

  constructor(
    account: Account,
    plant: Plant,
    machine: Machine,
    assetKey: string,
  ) {
    this.account = account;
    this.plant = plant;
    this.machine = machine;
    this.assetKey = assetKey;
  }
}
