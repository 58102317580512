// ---------------------------------------------------------------------
// <copyright file="alarm-filter.components.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
// alarmのフィルターコンポーネント
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { KeywordFinder } from '../../model/keywordFinder';

/**
 * MachineAlarmコンポーネントでフィルタリングを担当するコンポーネント
 */
@Component({
  selector: 'app-alarm-filter',
  templateUrl: './alarm-filter.component.html',
  styleUrls: ['./alarm-filter.component.css'],
})
export class AlarmFilterComponent implements OnInit {
  @Output() event = new EventEmitter<[KeywordFinder, KeywordFinder]>();
  includeFilter = new KeywordFinder();
  excludeFilter = new KeywordFinder();
  includeWord: string = undefined;
  excludeWord: string = undefined;

  constructor() {}

  ngOnInit(): void {
    this.includeFilter.keywords = [];
    this.excludeFilter.keywords = [];
  }

  // キーワードをフォームから追加
  public filter(): void {
    this.includeFilter.addKeyword(this.includeWord);
    this.excludeFilter.addKeyword(this.excludeWord);
    this.includeWord = '';
    this.excludeWord = '';
    this.event.emit([this.includeFilter, this.excludeFilter]);
  }
  // キーワードを消去
  public clear(): void {
    this.includeFilter.clearKeywords();
    this.excludeFilter.clearKeywords();
    this.event.emit([this.includeFilter, this.excludeFilter]);
  }
}
