<!-- Footer -->
<footer class="page-footer font-small">
  <!-- Copyright -->
  <div class="footer-copyright py-3 flex">
    <span class="copyright">
      Copyright ©
      <a href="https://www.dmgmori.co.jp/"> DMG MORI. CO., LTD.</a>
      All rights reserved.
    </span>
    <span class="version">version:{{ displayString + version }}</span>
  </div>
  <!-- Copyright -->
</footer>
