<!-- boxとflexの仕様の関係でうまく表示できない時があったので、conatinerを二つ用意して対処している -->
<div [style]="createStyle()">
  <div class="container">
    <ng-container *ngIf="messages">
      <div *ngFor="let message of messages">
        {{ message }}
      </div>
    </ng-container>
    <img class="img" [src]="data.path" *ngIf="data.path" />
  </div>
</div>
