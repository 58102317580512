// ---------------------------------------------------------------------
// <copyright file=change-password.component.ts company=WALC Inc.
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CognitoService } from '../../service/cognito.service';
import { RouteProcessService } from 'src/app/service/route-process.service';
import { StorageService } from 'src/app/service/storage.service';
import { passwordMatchValidator } from 'src/app/model/form-validator';

/**
 * 初回ログイン後に遷移するパスワード変更画面。
 */
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css', '../../style/form-style.css'],
})
export class ChangePasswordComponent implements OnInit {
  public user: any = undefined; // cognitoになげるuser変数。loginコンポーネントからstorageService経由で渡される。
  public changePasswordForm: FormGroup = undefined;
  public errorMessage: string = undefined;
  public nowSubmit: boolean = false; // 通信中かのフラグ

  // onDestroy時にsubscribe解除をするためのオブジェクト。subscribe前にpipe内で takeUntil(this.unsubscribe$) とすれば良い。
  private unsubscribe$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private cognito: CognitoService,
    private routeProcessService: RouteProcessService,
    private storageService: StorageService,
  ) {}

  ngOnInit(): void {
    // storageサービスにアクセスし、userがなければloginに遷移する
    this.user = this.storageService.user;
    // userがstorageServiceにないときはnullが帰るので、ログイン画面に戻る
    // if (!this.user) this.routeProcessService.navigateLogin();

    // htmlのinputタグの formControlName属性 と紐付けられる
    this.changePasswordForm = this.fb.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });

    // カスタムのフォームバリデーションを設定
    this.changePasswordForm.setValidators(passwordMatchValidator('password', 'confirmPassword'));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }

  /**
   * confirmPasswordの入力監視メソッド
   * HTMLのアラート表示に使う
   * @returns
   */
  public isConfirmPasswordFilled(): boolean {
    return this.changePasswordForm.get('confirmPassword').value !== '';
  }

  /**
   * ログイン画面に戻る時の処理\
   * この場合はログアウトが適切？
   */
  public onClickLogin(): void {
    this.routeProcessService.logout();
  }

  /**
   * 初回ログイン時のパスワード変更をcognitoに送信する時の処理
   * @param value 変更された名前、パスワードのオブジェクト
   */
  public onSubmitChangePassword(value: any): void {
    this.nowSubmit = true;
    const password = value.password;

    this.cognito
      .completeNewPassword(this.user, password)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (result) => {
          // 正常系が帰ってきた場合はそのままログインできる
          // もしかしたらログインに戻らないといけないかも
          this.errorMessage = undefined;
          this.nowSubmit = false;
          this.routeProcessService.navigateTop();
        },
        (error) => {
          // status = Status.changePassword;
          switch (error.code) {
            case 'InvalidPasswordException':
              // ユーザープールのポリシーで設定したパスワードの強度を満たさない場合に起こる。
              this.errorMessage = 'パスワードが短いか数字、大文字、小文字のいずれかを含んでいません。';
              console.log(error);
              this.nowSubmit = false;
              break;
            default:
              // その他のエラー
              this.errorMessage = error.message;
              console.log(error);
              this.nowSubmit = false;
              break;
          }
        },
      );
  }
}
