<!-- タイトルとカラーバーを置く箱 -->
<div class="d-flex flex-row justify-content-between align-items-start">
  <!-- titleはassemblyUnit -->
  <div class="text-left font-title">{{ 'allAssetsDiagnosis' + '.' + title | translate }}</div>
  <img src="{{ colorbarURL }}" class="colorbar" *ngIf="!isLoading" />
</div>

<!-- 画像を並べる箱 -->
<div class="d-flex flex-row flex-wrap justify-content-start" *ngIf="!isLoading">
  <!-- 1つの画像を入れる箱。画像名と画像本体を縦置きする -->
  <div
    class="d-flex flex-column justify-content-center align-items-center cursor-pointer img-box"
    (click)="selectImage(conditionSummaryImage)"
    *ngFor="let conditionSummaryImage of conditionSummaryImages"
  >
    <p [class]="conditionSummaryImage.colorStyle + ' ' + 'img-title d-flex justify-content-center align-items-center'">
      {{ conditionSummaryImage.displayName }}
    </p>
    <img
      src="{{ conditionSummaryImage.URL }}"
      [class]="displayImageStyle(conditionSummaryImage.URL)"
      title="{{ conditionSummaryImage.assetKey }}"
    />
  </div>
</div>
<!-- 通信中に表示するアイコン -->
<app-progress-spinner [isLoading]="isLoading"></app-progress-spinner>
