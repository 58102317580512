// ---------------------------------------------------------------------
// <copyright file=|`"pagination.component.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

/**
 * MachineListコンポーネントのページネーションを行うコンポーネント
 */
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css'],
})
export class PaginationComponent implements OnInit {
  readonly button = 'button';
  readonly span = 'span';
  public begin = 0;
  public page = 1;

  @Input() length: number;
  @Input() maxLength: number;
  @Output() event = new EventEmitter<{ begin: number }>();

  constructor() {}

  ngOnInit(): void {}

  /**
   * よくわかっていないので後で確認
   * @param page
   */
  public pager(page: number): void {
    this.begin = (page - 1) * this.length;
    this.page = page;
    this.event.emit({
      begin: this.begin,
    });
  }

  /**
   * 最大のページ番号(=ページ数)を返すメソッド
   * @returns 最大のページ番号
   */
  public maxPage(): number {
    return Math.ceil(this.maxLength / this.length);
  }

  /**
   * 次のページへ遷移するメソッド
   */
  public nextPage(): void {
    this.begin += this.length;
    this.page++;
    this.event.emit({
      begin: this.begin,
    });
  }

  /**
   * 前のページへ遷移するメソッド
   */
  public previousPage(): void {
    this.begin -= this.length;
    this.page--;
    this.event.emit({
      begin: this.begin,
    });
  }

  /**
   * 次のページへ遷移するボタンのCSSスタイルを返すメソッド
   * @returns 次のページへ遷移するボタンのCSSスタイル
   */
  public nextPageStyle(): boolean {
    if (this.page >= this.maxPage()) {
      return true;
    }
    return false;
  }

  /**
   * 前のページへ遷移するボタンのCSSスタイルを返すメソッド
   * @returns 前のページへ遷移するボタンのCSSスタイル
   */
  public previousPageStyle(): boolean {
    if (this.page <= 1) {
      return true;
    }
    return false;
  }

  /**
   * 最初のページへ遷移するボタンのCSSスタイルを返すメソッド
   * @returns 最初のページへ遷移するボタンのCSSスタイル
   */
  public beginPage(): string {
    if (this.page > 2) {
      return 'page-link button';
    }
    return 'hide';
  }

  /**
   * 最後のページへ遷移するボタンのCSSスタイルを返すメソッド
   * @returns 最後のページへ遷移するボタンのCSSスタイル
   */
  public endPage(): string {
    const maxPage = this.maxPage();
    if (this.page < maxPage - 1) {
      return 'page-link button';
    }
    return 'hide';
  }

  /**
   * 現在選択中のページへ遷移するボタンのCSSスタイルを返すメソッド
   * @returns 現在選択中のページへ遷移するボタンのCSSスタイル
   */
  public selectedPage(page: number): string {
    const maxPage = this.maxPage();
    if (page > 0 && page <= maxPage) {
      if (page === this.page) {
        return 'page-link select';
      }
      return 'page-link button';
    }
    return 'hide';
  }
}
