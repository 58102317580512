// ---------------------------------------------------------------------
// <copyright file="login.component.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CognitoService } from '../../service/cognito.service';
import { RouteProcessService } from 'src/app/service/route-process.service';

/**
 * LoginUserコンポーネント、ResetPasswordコンポーネントなど、ログイン処理に関わるコンポーネントの親コンポーネント\
 * ルーティングすべきだと思うが, ngIfで書かれているため、修正が必要。
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css', '../../style/form-style.css'],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup = undefined;
  public errorMessage: string = undefined;
  public nowLogin: boolean = undefined; // ログイン処理中のフラグ

  // onDestroy時にsubscribe解除をするためのオブジェクト。subscribe前にpipe内で takeUntil(this.unsubscribe$) とすれば良い。
  private unsubscribe$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private routeProcessService: RouteProcessService,
    private cognito: CognitoService,
  ) {}

  get userName(): AbstractControl {
    return this.loginForm.get('userName');
  }
  get password(): AbstractControl {
    return this.loginForm.get('password');
  }

  ngOnInit(): void {
    // htmlのinputタグの formControlName属性 と紐付けられる
    this.loginForm = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  /**
   * パスワードリセット画面へ遷移する時の処理
   */
  public onClickResetPassword(): void {
    this.routeProcessService.navigateResetPassword();
  }

  /**
   * ログインボタン押下時の処理
   * @param value email, パスワードをまとめたオブジェクト
   */
  public onSubmitLogin(value: any): void {
    this.nowLogin = true; // ログイン処理中のフラグを立て、ボタンが連打されないようにする

    const userName = value.userName;
    const password = value.password;

    this.cognito
      .signIn(userName, password)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (result) => {
          this.errorMessage = undefined;
          // パスワード変更済みの場合challengeNameは存在しないが、undefinedになるだけでエラーにはならない
          if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
            const user = result; // storageServiceであつかうuserであり、Userクラスではない。
            this.routeProcessService.navigateChangePassword(user);
          } else {
            this.cognito.setAuthenticated();
            this.routeProcessService.navigateTop();
          }
        },
        (error) => {
          // status = Status.login;
          switch (error.code) {
            case 'NotAuthorizedException':
              // 誤ったパスワードを入力した場合に起こる。
              // 注) パスワードを間違え続けた場合にも起こり、 error.message が 'Password attempts exceeded' になる。
              // （エラーコードとして LimitExceededException が返ってくると思ったらそうではなかった）
              this.errorMessage = 'User Name or Password is wrong';
              console.log(error);
              break;
            case 'UserNotFoundException':
              // PASSWORD_VERIFIER は通るものの username が Cognito ユーザープールに存在しない場合に起こる。
              this.errorMessage = 'This User Name is not registerd';
              console.log(error);
              break;
            default:
              // その他のエラー
              this.errorMessage = error.message;
              console.log(error);
              break;
          }
          // エラー時はログアウトする
          this.cognito.signOut();
        },
      )
      .add(() => {
        this.nowLogin = false; // フラグを戻す
      });
  }
}
