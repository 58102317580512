// ---------------------------------------------------------------------
// <copyright file="sort-status.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
/**
 * 表のソート状態を保持するクラス
 */
export class SortStatus {
    sortMark: string; // ソートボタンに表示するマーク
    sortOrder: SortOrder; // ソート順
    sortKey: SortKey; // ソートするkey
    constructor(){}
}

export enum SortOrder {
    ascend,     // 昇順
    descend     // 降順
}

export enum SortKey {
    undefined,
    name,
    anomaly,
    ID,
    time,
}
