<!-- タイトル -->
<p class="d-flex flex-row justify-content-start align-items-center title-margin">
  <span class="font-title"
    >{{ 'pastAnomaly.title' | translate }}
    {{ displayTimestamp | date: 'medium' : 'UTC+' + selectedTimestamp?.slice(-4) : localeID }}
  </span>

  <!-- infoボタン -->
  <mat-icon
    appImgTooltip
    message="{{ 'pastAnomaly.tooltipMessages' | translate }}"
    [path]="tooltipImgPath"
    [position]="'below'"
    >info
  </mat-icon>
</p>
<!-- 画像コンテンツ -->
<div class="d-flex flex-column justify-content-center align-items-center" *ngIf="!isLoading">
  <img src="{{ displayURL }}" [class]="displayImageStyle()" />
</div>

<!-- 通信中に表示するアイコン -->
<app-progress-spinner [isLoading]="isLoading" *ngIf="isLoading" class="mt-spinner-m"></app-progress-spinner>

<!-- エラーメッセージ表示用の箱 -->
<p class="text-center" *ngIf="errorMessage">{{ errorMessage }}</p>
