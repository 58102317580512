<app-floor-header
  [selectedAccount]="commonProperties.account"
  [selectedPlant]="commonProperties.plant"
></app-floor-header>

<app-machine-info-header
  [machineList]="machineList"
  [selectedMachine]="commonProperties.machine"
  [selectedAssetKey]="commonProperties.assetKey"
  (machineEvent)="receiveMachine($event)"
  (assetKeyEvent)="receiveAssetKey($event)"
></app-machine-info-header>

<app-progress-bar class="progress-bar" [isLoading]="isLoading" *ngIf="isLoading"></app-progress-bar>

<!-- 全体の箱 -->
<div class="d-flex custom-mt all-container">
  <!-- 軸動作画像 -->
  <div
    class="d-flex flex-column justify-content-center custom-container"
    *ngIf="!isLoading && axisMotion?.URL !== notFoundURL"
  >
    <!-- タイトル -->
    <div class="text-left font-title">{{ 'allAssetsDiagnosis.AxisDirections' | translate }}</div>
    <!-- 画像 -->
    <img src="{{ axisMotion?.URL }}" class="align-self-center axis-motion" />
  </div>

  <ng-container *ngFor="let assemblyUnitImages of allAssemblyUnitImages?.allImageElemntsList">
    <app-summary-images-container
      class="d-flex flex-column justify-content-center custom-container"
      [conditionSummaryImages]="assemblyUnitImages.conditionSummaryImages"
      [colorbarURL]="colorbar?.URL"
      [title]="assemblyUnitImages.unitName"
      [isLoading]="isLoading"
      *ngIf="assemblyUnitImages.conditionSummaryImages.length"
    >
    </app-summary-images-container>
  </ng-container>

  <!-- 本当はngForで回すと綺麗 -->
  <!-- 送り軸コンテナ cssの整合性を取るためコンテナに入れる。 -->
  <!-- <app-summary-images-container
    class="d-flex flex-column justify-content-center custom-container"
    [formattedSummaryImageElements]="formattedSummaryImageInfo?.feedAxis"
    [colorbarURL]="colorbar?.URL"
    [title]="staticProperties.FeedAxis"
    [isLoading]="isLoading"
    *ngIf="formattedSummaryImageInfo?.feedAxis.length"
  >
  </app-summary-images-container> -->

  <!-- 回転軸と主軸をまとめるコンテナ -->
  <!-- <app-summary-images-container
    class="d-flex flex-column justify-content-center custom-container"
    [formattedSummaryImageElements]="formattedSummaryImageInfo?.rotatedAxis"
    [colorbarURL]="colorbar?.URL"
    [title]="staticProperties.RotaryAxis"
    [isLoading]="isLoading"
    *ngIf="formattedSummaryImageInfo?.rotatedAxis.length"
  >
  </app-summary-images-container> -->

  <!-- <app-summary-images-container
    class="d-flex flex-column justify-content-center custom-container"
    [formattedSummaryImageElements]="formattedSummaryImageInfo?.spindle"
    [colorbarURL]="colorbar?.URL"
    [title]="staticProperties.Spindle"
    [isLoading]="isLoading"
    *ngIf="formattedSummaryImageInfo?.spindle.length"
  >
  </app-summary-images-container> -->
</div>
