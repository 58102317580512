// ---------------------------------------------------------------------
// <copyright file=|`"floor.service.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Account, HttpAccount, HttpAccountList } from 'src/app/model/account';
import { HttpPlantList, Plant } from 'src/app/model/plant';
import { HttpService } from './http.service';

/**
 * Accountに関するデータ、Plantに関するデータを管理するサービス
 */
@Injectable({
  providedIn: 'root',
  deps: [HttpService],
})
export class FloorService {
  constructor(private httpService: HttpService) {}

  /**
   * アカウントリストAPIを叩き、整形したObservableを返すメソッド
   * @returns Account[]のObservable
   */
  public fetchAccountList(): Observable<Account[]> {
    return this.httpService.httpGetRequest<HttpAccountList>('/account-list').pipe(
      // エラー処理後、accountListだけのobservableを返す
      map((response: HttpAccountList) => {
        return response.AccountList;
      }),
    );
  }

  /**
   * アカウントAPIを叩き、整形したObservableを返すメソッド
   * @returns AccountのObservable
   */
  public fetchAccount(): Observable<Account> {
    return this.httpService.httpGetRequest<HttpAccount>('/account').pipe(
      // エラー処理後、accountだけのobservableを返す
      map((response: HttpAccount) => {
        return response.Account;
      }),
    );
  }

  /**
   * プラントリストAPIを叩き、整形したObservableを返すメソッド
   * @param accountID アカウントID。IDの権限管理はサーバ側で行う
   * @returns Plant[]のObservable
   */
  public fetchPlantList(accountID: string): Observable<Plant[]> {
    return this.httpService.httpGetRequest<HttpPlantList>('/plant-list' + '/' + accountID).pipe(
      // エラー処理後、plantListだけのobservableを返す
      map((response: HttpPlantList) => {
        return response.PlantList;
      }),
    );
  }
}
