<app-toolbar class="toolbar-only"></app-toolbar>

<!-- 遷移先画面のリスト -->
<div class="d-flex flex-column justify-content-center align-items-center">
  <!-- タイトル(見分けるため) -->
  <span class="title-text">{{ 'adminTop.title' | translate }}</span>
  <!-- リストの各項目 -->
  <!-- keyがpath、valueが画面表示文字列 -->
  <div class="row" *ngFor="let pathKeyValue of routeMap | keyvalue">
    <button class="btn-block" (click)="navigate(pathKeyValue.key)">
      {{ pathKeyValue.value }}
    </button>
  </div>
</div>
