// ---------------------------------------------------------------------
// <copyright file="footer.components.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit } from '@angular/core';
import { version } from '../../../../package.json';
import { environment } from '../../../environments/environment';

/**
 * フッターコンポーネント
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  version: string = version;
  displayString: string;
  isProduction: boolean = environment.production;

  constructor() {}

  ngOnInit(): void {
    if (this.isProduction) {
      this.displayString = '';
    } else {
      this.displayString = 'test';
    }
  }
}
