<!-- 最外の全軸コンテナ -->
<div [ngClass]="styleContainer()">
  <!-- AssemblyUnit -->
  <div *ngFor="let assemblyUnit of scores?.assemblyUnitList" class="d-flex flex-row">
    <!-- タイトルとアイコンを縦並びさせるための箱 -->
    <div [ngClass]="styleUnit()">
      <span [ngClass]="styleUnitTitle()">{{ 'allAssetsDiagnosis' + '.' + assemblyUnit.unitName | translate }}</span>
      <!-- 大系列(shortKey)の箱を並べる -->
      <!-- 大系列箱同士は横並び -->
      <div [ngClass]="styleAssetBox()">
        <div *ngFor="let assetBox of assemblyUnit?.assetBoxes" [ngClass]="styleAnomalyIconBox(assetBox.num)">
          <!-- 大系列の箱の中に小系列(longKey)のアイコンを並べる -->
          <ng-container *ngIf="assetBox.num != 0">
            <div
              *ngFor="let asset of assetBox.units"
              [ngClass]="styleAnomalyIcon(assetBox.num, asset) + ' ' + styleDisplayName(asset)"
              (click)="clickAsset(asset)"
            >
              {{ asset.displayName }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
