// ---------------------------------------------------------------------
// <copyright file="machine-list.component.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Machine } from '../../model/machine';
import { SortStatus, SortOrder, SortKey } from '../../model/sort-status';
import { MachineService } from '../../service/machine.service';
import { RouteProcessService } from 'src/app/service/route-process.service';
import { StorageService } from 'src/app/service/storage.service';
import { CommonProperties } from 'src/app/model/common-properties';
import { parseDate } from 'src/app/model/timestamp-converter';
import { TranslateService } from '@ngx-translate/core';

/**
 * 機械一覧画面を表示するコンポーネント\
 * anomaly-iconコンポーネント、paginationコンポーネントを内部に持っている。
 */
@Component({
  selector: 'app-machine-list',
  templateUrl: './machine-list.component.html',
  styleUrls: ['./machine-list.component.css'],
})
export class MachineListComponent implements OnInit {
  // 1ページに表示数するリスト項目数を決めている
  public begin = 0;
  public length = 10;

  // ソートステータスの初期値はinitialSortメソッドで固定に設定している。
  // テンプレート上でsortStatus == undefinedのエラーが出ないように注意すること。
  public sortStatus: SortStatus = undefined;

  // storageServiceから取得するプロパティ。初期化、子コンポーネントへ渡すデータのまとめに使う
  public commonProperties: CommonProperties = undefined;

  // 表示する機械リスト
  public machineList: Machine[] = undefined;

  // TranslateServiceから取得する値
  public localeID: string = '';

  // onDestroy時にsubscribe解除をするためのオブジェクト。subscribe前にpipe内で takeUntil(this.unsubscribe$) とすれば良い。
  private unsubscribe$ = new Subject();

  // ローディングバー表示用のフラグ。floor-header経由でprogress-barに渡す
  public isLoading: boolean = false; // 初期状態は非表示

  constructor(
    private machineService: MachineService,
    private router: Router,
    private routeProcessService: RouteProcessService,
    private storageService: StorageService,
    private translateService: TranslateService,
  ) {
    // このコンポーネントに繰り返しルーティングされた時、OnInitを叩き初期化させる処理。
    // toolbarコンポーネントで工場選択時にHttpエラーが起きると、その後画面を動かせなくなるのを防ぐために入れている。
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.isLoading = true; // HTTP通信開始時にローディングバーを表示

    // storageServiceから初期化項目の取得
    this.commonProperties = this.storageService.getCommonProperties();

    // storageServiceに存在しない値はnullとなる
    // account,plantのいずれかがnullだったら適切に画面遷移する
    if (!this.commonProperties.account || !this.commonProperties.plant) this.routeProcessService.navigateError();

    // machineListを取得するためのbody取得
    const httpBody = this.storageService.getBodyforMachineList(); // エラーハンドリングを決める

    // machineService経由で整形済みのmachineListを取得する
    this.machineService
      .fetchMachineList(httpBody)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (machineList: Machine[]) => {
          this.machineList = machineList;
          // 取得できていたら初期ソートを行う
          if (this.machineList.length) this.initialSort();
          this.isLoading = false; // HTTP通信完了時にローディングバーを非表示
        },
        (error) => {
          console.log(error);
          this.isLoading = false; // HTTP通信完了時にローディングバーを非表示
          this.routeProcessService.navigatePlantList();
        },
      );

    // translateServcieからlocaleIDを読み取る。
    // タイムスタンプの整形に使う
    this.translateService
      .stream('localeID')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (localeID: string) => {
          this.localeID = localeID;
        },
        (error) => {
          console.log(error);
        },
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  /**
   * リストクリック時の処理
   * @param machine 画面クリックで選択した機械のMachine型変数
   */
  public selectMachine(machine: Machine): void {
    this.routeProcessService.navigateAllAssetDiagnosis(machine);
  }

  /**
   * コメント領域のクリック時の処理。
   * 画面遷移をしないためイベント伝搬を止めている。
   * コメントはリストの子要素なので、こちらが先に実行される。
   * @param event
   */
  public onClickComment(event: any) {
    event.stopPropagation(); // クリックイベントの伝播を停止
  }

  /**
   * ページネーションメソッド
   * @param event ページ選択時のイベント変数
   */
  public changePage(event: any): void {
    this.begin = event.begin;
  }

  //////////////////////////////// 以降はソート用の処理 ////////////////////////////////////

  /**
   * 名前でソートするメソッド
   */
  public sortName(): void {
    this.sortStatus = this.changeSortKey(this.sortStatus, SortKey.name);
    [this.machineList, this.sortStatus] = this.sort(this.machineList, this.sortStatus);
  }

  /**
   * IDでソートするメソッド
   */
  public sortID(): void {
    this.sortStatus = this.changeSortKey(this.sortStatus, SortKey.ID);
    [this.machineList, this.sortStatus] = this.sort(this.machineList, this.sortStatus);
  }

  /**
   * 時間でソートするメソッド
   */
  public sortDate(): void {
    this.sortStatus = this.changeSortKey(this.sortStatus, SortKey.time);
    [this.machineList, this.sortStatus] = this.sort(this.machineList, this.sortStatus);
  }

  /**
   * 異常度でソートするメソッド
   */
  public sortAnomaly(): void {
    this.sortStatus = this.changeSortKey(this.sortStatus, SortKey.anomaly);
    [this.machineList, this.sortStatus] = this.sort(this.machineList, this.sortStatus);
  }

  /**
   * コンポーネント初期化時に異常度でソートするメソッド
   */
  // sortメソッドではdescendにしたいので、先に変更する。この処理はmachineListが更新された時にしか走らない
  private initialSort(): void {
    //
    this.sortStatus = {
      sortMark: '▼',
      sortOrder: SortOrder.descend,
      sortKey: SortKey.anomaly,
    };
    [this.machineList, this.sortStatus] = this.sort(this.machineList, this.sortStatus);
  }

  /**
   * sort~ メソッドが実際にソートをする際に使用するメソッド。
   * @param machineList ソート対象のMachineオブジェクトのリスト
   * @param sortStatus 現在のソートの状態
   * @returns ソート済みのMachineオブジェクトと引数のsortStatus
   */
  private sort(machineList: Machine[], sortStatus: SortStatus): [Machine[], SortStatus] {
    const key: keyof Machine = this.getSortKey(sortStatus);
    // 降順の場合
    if (sortStatus.sortOrder === SortOrder.descend) {
      machineList = machineList.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    } else {
      // 昇順の場合
      machineList = machineList.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    }
    sortStatus = this.setMark(sortStatus);
    return [machineList, sortStatus];
  }

  /**
   * Sortするカラムを文字列で取得するメソッド
   * @param sortStatus 現在のソートの状態
   * @returns ソートするカラムの文字列
   */
  private getSortKey(sortStatus: SortStatus): keyof Machine {
    // sortメソッドが使用する
    let key: keyof Machine;
    switch (sortStatus.sortKey) {
      case SortKey.name:
      default:
        key = 'Name';
        break;
      case SortKey.ID:
        key = 'MachineID';
        break;
      case SortKey.time:
        key = 'LatestTimestamp';
        break;
      case SortKey.anomaly:
        key = 'MaxAnomaly';
        break;
    }
    return key;
  }

  /**
   * ソートの実行メソッド。sort(カラム名)メソッドが叩く
   * @param sortStatus 現在のソート状態
   * @param sortKey ソートするカラム
   * @returns 新しいソートの状態
   */
  private changeSortKey(sortStatus: SortStatus, sortKey: SortKey): SortStatus {
    // sortKeyを変更する場合の処理
    // sort~ メソッドが使用する
    if (sortStatus.sortKey !== sortKey) {
      sortStatus.sortKey = sortKey; // sortKeyを変更
      sortStatus.sortOrder = SortOrder.ascend; // 昇順に設定
      return sortStatus;
    } else {
      return sortStatus;
    }
  }

  /**
   * ソート状態に合わせ、ソートアイコンのスタイルを返すメソッド
   * @param sortStatus 現在のソート状態
   * @returns 引数のsortStatusをそのまま返す
   */
  private setMark(sortStatus: SortStatus): SortStatus {
    if (sortStatus.sortOrder === SortOrder.descend) {
      sortStatus.sortOrder = SortOrder.ascend; // 次にソートした際の順序を設定
      sortStatus.sortMark = '▼'; // 表示するマークを設定
      return sortStatus;
    } else {
      sortStatus.sortOrder = SortOrder.descend; // 次にソートした際の順序を設定
      sortStatus.sortMark = '▲'; // 表示するマークを設定
      return sortStatus;
    }
  }

  /**
   * string型のタイムスタンプをDate型に治すメソッド
   * @param strDate string型のタイムスタンプ
   * @returns Date型のタイムスタンプ
   */
  public formatDateFromISO(isoDate?: string): Date {
    // Date型の日付に変換
    // AWS側でデータベースに問題があっても、契約済みの全ての機械のデータが返るようになっている。

    // 無効な文字列はformatDate側で削除される。
    const date = parseDate(isoDate);
    return date;
  }
}
