// ---------------------------------------------------------------------
// <copyright file=|`"base.component.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Account } from 'src/app/model/account';
import { CognitoService } from 'src/app/service/cognito.service';
// import { UserMaster } from 'src/app/model/user-info';
import { RouteProcessService } from 'src/app/service/route-process.service';
import { FloorService } from 'src/app/service/floor.service';
import { User } from 'src/app/model/user-info';

/**
 * ログイン後、ユーザの属性に合わせた画面遷移を担う、ルーティングコンポーネント\
 * 元々別の使い方をしていたものを書き換えて、ルーティング用のコンポーネントにした。
 */
@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css'],
})
export class BaseComponent implements OnInit, OnDestroy {
  // onDestroy時にsubscribe解除をするためのオブジェクト。subscribe前にpipe内で takeUntil(this.unsubscribe$) とすれば良い。
  private unsubscribe$ = new Subject();

  // ローディングバー表示用のフラグ。
  public isLoading: boolean = false; // 初期状態は非表示

  constructor(
    private cognitoService: CognitoService,
    private floorService: FloorService,
    private routeProcessService: RouteProcessService,
  ) {}

  /**
   * cognitoServiceからuserInfoをsubscribe後、routingメソッドを叩く。\
   * ログイン後、バックエンドからユーザ属性を取り出す前にroutingメソッドが実行されるとエラーが起きるため、subscribe内でroutingメソッドを実行している。\
   */
  ngOnInit(): void {
    this.isLoading = true; // ローディングバー表示

    // cognitoからユーザ属性をsubscribeし、ルーティングの振り分けをする。
    // ログインしていない場合、errorに飛ぶ
    this.cognitoService
      .userObservable()
      .pipe(
        takeUntil(this.unsubscribe$), // onDestroy時に購読を解除するトリガー
      )
      .subscribe(
        (user: User) => {
          this.routing(user);
        },
        (error) => {
          console.log(error);
          this.isLoading = false; // ローディングバー非表示
          this.routeProcessService.navigateError();
        },
      );
  }

  /**
   * ユーザ属性によって画面遷移を実行するメソッド\
   * 管理者権限がある場合は /account に遷移する。\
   * そうでない場合は アカウント情報(Account型)をAPIから取得し、/plant に遷移する。\
   * 取得情報に問題がある場合は、契約していないSSOユーザとみなし、 /adertisement に遷移する。\
   */
  private routing(user: User): void {
    // 管理者の場合
    if (user.isAdmin) {
      this.isLoading = false; // ローディングバー非表示
      this.routeProcessService.navigateAdminTop();
    }

    // ユーザの場合
    // これはplantComponentに移譲すべきかも？
    else {
      // accountをAPIから取得し、遷移する
      this.floorService
        .fetchAccount()
        .pipe(
          takeUntil(this.unsubscribe$), // onDestroy時に購読を解除するトリガー
        )
        .subscribe(
          (account: Account) => {
            this.isLoading = false; // ローディングバー非表示
            this.routeProcessService.navigatePlantList(account);
          },
          (error) => {
            console.log(error);
            this.isLoading = false; // ローディングバー非表示
            this.routeProcessService.navigateError();
          },
        );
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }
}
