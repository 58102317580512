<app-floor-header
  [selectedAccount]="commonProperties.account"
  [selectedPlant]="commonProperties.plant"
></app-floor-header>

<app-machine-info-header
  [machineList]="machineList"
  [selectedMachine]="commonProperties.machine"
  [selectedAssetKey]="commonProperties.assetKey"
  (machineEvent)="receiveMachine($event)"
  (assetKeyEvent)="receiveAssetKey($event)"
></app-machine-info-header>

<!-- フィルター領域のbox -->
<div class="d-flex flex-column filter justify-content-center">
  <!-- 年下セレクターのbox -->
  <div class="d-flex flex-row justify-content-center">
    <!-- year -->
    <div class="d-flex flex-column custom-box">
      <p class="m-0">Year</p>
      <mat-select id="year-select" class="" [(ngModel)]="year" (selectionChange)="setupMachineAlarm()">
        <mat-option id="year-option" value="y" *ngFor="let y of yearList">{{ y }}</mat-option>
      </mat-select>
    </div>

    <!-- month -->
    <div class="d-flex flex-column custom-box">
      <p class="m-0">Month</p>
      <mat-select id="month-select" class="" [(ngModel)]="month" (selectionChange)="setupMachineAlarm()">
        <mat-option id="month-option" value="m" *ngFor="let m of monthList">{{ m }}</mat-option>
      </mat-select>
    </div>
  </div>

  <app-alarm-filter (event)="receiveFilter($event)"></app-alarm-filter>
</div>

<!-- フィルター領域とコンテンツ領域を分ける -->
<hr class="margin-hr" />

<!-- コンテンツ領域 -->
<div *ngIf="machineAlarms?.length">
  <table class="table">
    <thead>
      <tr>
        <th class="tdshort">
          <h3>
            Time
            <button class="btn btn-outline-secondary btn-sm" (click)="sortdate(true)">↓</button>
            <button class="btn btn-outline-secondary btn-sm" (click)="sortdate(false)">↑</button>
          </h3>
        </th>
        <th class="tdshort">
          <h3>
            Alarm No
            <button class="btn btn-outline-secondary btn-sm" (click)="sortNo(true)">↓</button>
            <button class="btn btn-outline-secondary btn-sm" (click)="sortNo(false)">↑</button>
          </h3>
        </th>
        <th>
          <h3>Message/Original Message</h3>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let alarm of filteredAlarms">
        <td class="tdshort">
          <div>{{ alarm?.Time }}</div>
        </td>
        <td class="tdshort">
          {{ alarm?.AlarmNo }}
        </td>
        <td>
          {{ alarm?.AlarmMessage }}
          <br />
          {{ alarm?.AlarmContent }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="result !== undefined && !machineAlarms?.length" class="text-center font-alarm">No Alarms Found</div>
