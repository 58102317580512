// ---------------------------------------------------------------------
// <copyright file=formatted-summary-image-info.ts company=WALC Inc.
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

/**
 * all-assets-diagnosisでの画面表示用の「AssemblyUnitでまとめたConditionSummaryImage」クラス\
 * Moriの工作機械の構造物ユニットを全てまとめる
 */
export class AllAssemblyUnitImages {
  feedAxis: AssemblyUnitSummaryImages;
  rotatedAxis: AssemblyUnitSummaryImages;

  axisDirection: AssemblyUnitSummaryImages;
  table: AssemblyUnitSummaryImages;
  spindle: AssemblyUnitSummaryImages;
  toolSpindle: AssemblyUnitSummaryImages;
  spindle1: AssemblyUnitSummaryImages;
  spindle2: AssemblyUnitSummaryImages;
  turret: AssemblyUnitSummaryImages;
  turret1: AssemblyUnitSummaryImages;
  turret2: AssemblyUnitSummaryImages;
  workDischargeUnit: AssemblyUnitSummaryImages;
  tailstock: AssemblyUnitSummaryImages;
  steadyRest1: AssemblyUnitSummaryImages;
  steadyRest2: AssemblyUnitSummaryImages;
  steadyRest3: AssemblyUnitSummaryImages;

  other: AssemblyUnitSummaryImages;

  // AssemblyUnitSummaryImages[]の参照をリストで保持する
  // HTMLに表示する時の制御の都合で必要
  public readonly allImageElemntsList = [];

  // assmeblyUnit文字列と、このクラスのプロパティの対応付
  assemblyUnit2Property: { [key: string]: AssemblyUnitSummaryImages } = {};

  constructor() {
    // this.feedAxis = new AssemblyUnitSummaryImages('FeedAxis');
    // this.rotatedAxis = new AssemblyUnitSummaryImages('RotaryAxis');

    this.axisDirection = new AssemblyUnitSummaryImages('AxisDirection');
    this.table = new AssemblyUnitSummaryImages('Table');
    this.spindle = new AssemblyUnitSummaryImages('Spindle');
    this.toolSpindle = new AssemblyUnitSummaryImages('ToolSpindle');
    this.spindle1 = new AssemblyUnitSummaryImages('Spindle1');
    this.spindle2 = new AssemblyUnitSummaryImages('Spindle2');
    this.turret = new AssemblyUnitSummaryImages('Turret');
    this.turret1 = new AssemblyUnitSummaryImages('Turret1');
    this.turret2 = new AssemblyUnitSummaryImages('Turret2');
    this.workDischargeUnit = new AssemblyUnitSummaryImages('WorkDischargeUnit');
    this.tailstock = new AssemblyUnitSummaryImages('Tailstock');
    this.steadyRest1 = new AssemblyUnitSummaryImages('SteadyRest1');
    this.steadyRest2 = new AssemblyUnitSummaryImages('SteadyRest2');
    this.steadyRest3 = new AssemblyUnitSummaryImages('SteadyRest3');
    this.other = new AssemblyUnitSummaryImages('Other');

    this.allImageElemntsList = [
      this.axisDirection,
      this.table,
      this.spindle,
      this.toolSpindle,
      this.spindle1,
      this.spindle2,
      this.turret,
      this.turret1,
      this.turret2,
      this.workDischargeUnit,
      this.tailstock,
      this.steadyRest1,
      this.steadyRest2,
      this.steadyRest3,
      this.other,
    ];

    // assmeblyUnit文字列と、このクラスのプロパティの対応付
    this.assemblyUnit2Property = {
      AxisDirection: this.axisDirection,
      Table: this.table,
      Spindle: this.spindle,
      ToolSpindle: this.toolSpindle,
      Spindle1: this.spindle1,
      Spindle2: this.spindle2,
      Turret: this.turret,
      Turret1: this.turret1,
      Turret2: this.turret2,
      WorkDischargeUnit: this.workDischargeUnit,
      Tailstock: this.tailstock,
      SteadyRest1: this.steadyRest1,
      SteadyRest2: this.steadyRest2,
      SteadyRest3: this.steadyRest3,
      Other: this.other,
    };
  }

  public pushSummaryImageElement(element: ConditionSummaryImage) {
    if (this.assemblyUnit2Property[element.assemblyUnit]) {
      this.assemblyUnit2Property[element.assemblyUnit].conditionSummaryImages.push(element);
    } else {
      this.other.conditionSummaryImages.push(element);
    }
  }
}

// ユニット名とFormattedImageInfoElementを束ねたクラス
export class AssemblyUnitSummaryImages {
  unitName: string;
  conditionSummaryImages: ConditionSummaryImage[];

  constructor(unitName: string) {
    this.unitName = unitName;
    this.conditionSummaryImages = [];
  }
}

// 補助用のクラス
// Summaryのヒートマップタイムライン
export class ConditionSummaryImage {
  assetKey: string;
  URL: string;
  displayName: string;
  assemblyUnit: string;
  colorStyle: string; // 画像タイトルの色を変更するために、selectedMachineのlatestStatusを元にしたcssセレクタを入れる

  constructor() {}
}
