<!-- コンポーネント全体のコンテナ。縦配置にしている -->
<div class="d-flex flex-column justify-content-center custom-container">
  <!-- <p class="text-left font-title title-margin">Condition Timeline</p> -->
  <p class="d-flex flex-row justify-content-start align-items-center title-margin">
    <span class="font-title">{{ 'allSampleAnomaly.title' | translate }}</span>

    <!-- infoボタン -->
    <mat-icon
      appImgTooltip
      message="{{ 'allSampleAnomaly.tooltipMessages' | translate }}"
      [path]="tooltipImgPath"
      [position]="'below'"
      >info
    </mat-icon>
  </p>

  <!-- 画像とカラーバーを横置きするためのコンテナ -->
  <div class="d-flex flex-row justify-content-center align-items-center custom-container">
    <!-- 通信中に表示するアイコン -->
    <app-progress-spinner [isLoading]="isLoading" *ngIf="isLoading"></app-progress-spinner>

    <!-- 画像と重ねる線をまとめるコンテナ -->
    <div class="position-relative size-img-box" *ngIf="!isLoading">
      <img src="{{ displayURL }}" id="display-img" [class]="displayImageStyle()" />

      <!-- 診断時刻選択に基づき画像に重ねる横線 -->
      <ng-container *ngIf="displayURL && displayURL !== notFoundURL">
        <hr
          id="horizontal"
          class="position-absolute position-horizontal horizontal-line-border"
          [style.top.px]="horizontalLineStyleTop()"
          *ngIf="selectedTimestamp && timestampList?.length && isDisplayLine"
        />

        <!-- 軸位置選択に基づき画像に重ねる縦線 -->
        <hr
          id="vertical"
          class="position-absolute position-vertical vertical-line-border m-0"
          [style.left.px]="vertivalLineStyleLeft()"
          [style.height.px]="vertivalLineStyleHeight()"
          *ngIf="selectedPosition >= 0 && isDisplayLine"
        />
      </ng-container>
    </div>

    <!-- colorbar -->
    <img src="{{ colorbarURL }}" class="colorbar" *ngIf="!isLoading" />
  </div>
</div>
