<button id="machine-list-btn" class="hamburger-btn" mat-button [matMenuTriggerFor]="machinelist">
  <mat-icon class="">menu</mat-icon>
</button>

<mat-menu id="machine-list-panel" class="border-radius" #machinelist="matMenu">
  <div class="d-flex justify-content-center w-100" *ngFor="let machine of machineList">
    <button mat-menu-item (click)="selectMachine(machine)" [class]="style(machine)">
      {{ machine.Name }}
    </button>
  </div>
</mat-menu>
