// ---------------------------------------------------------------------
// <copyright file="machine.service.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  HttpMachine,
  HttpMachineList,
  IntermediateMachineList,
  Machine,
  PostMachineComment,
  PostMachineCommentUserAccessibility,
} from '../model/machine';
import { HttpAlarm } from '../model/alarm';
import { TranslateService } from '@ngx-translate/core';
import { AnomalyStatus } from '../model/anomaly-unit';

/**
 * accountID、plantIDに対する、契約済み機械の情報を管理するサービス。
 * 機械リスト、選択済み機械、選択済み軸を管理する。machineListコンポーネント、machineInfoコンポーネントの子コンポーネントが使用する。
 */
@Injectable({
  providedIn: 'root',
  deps: [TranslateService],
})
export class MachineServiceMock {
  constructor(private translateService: TranslateService) {}

  /**
   * TranslateServiceでポジションのラベル(位置、回転時間、角度など)を取得するためのキーを返す
   */
  public getPositonKeyforTranslate(assetKey: string, machine: Machine): string {
    const assetType: string = machine.Assets[assetKey].AssetType;
    const translateKey: string = 'position.' + assetType;

    return translateKey;
  }

  // 以降は各コンポーネントが画像や、それに付随する情報を取得する時のメソッド。
  // URLについて\
  // APIのURLはpathが3単語以上になるものも多く可視性のため - で区切ることにしている

  public fetchMachineList(body): Observable<Machine[]> {
    return of([testMachine]);
  }
  /**
   * 機械コメント登録メソッド
   */
  public postMachineComment(body): Observable<PostMachineComment> {
    return of(null);
  }
  /**
   * 機械コメント表示切替メソッド
   */
  public postMachineCommentUserAccessiblity(body): Observable<PostMachineCommentUserAccessibility> {
    return of(null);
  }
  /**
   * アラーム取得メソッド
   */
  public fetchAlarm(ID: string, year?: string, month?: string): Observable<HttpAlarm> {
    // アラーム
    return of(null);
  }

  /**
   * タイムスタンプリストとポジション幅を取得するメソッド
   */
  public fetchTimestampListandPosition(machineID: string, assetKey: string): Observable<HttpTimePosition> {
    const res = {
      Result: true,
      Alarm: '000',
      ErrorMessage: 'OK',
      TimestampList: ['20201111T041504+0900', '20201212T041504+0900', '20201231T041504+0900'],
      PositionStep: 1,
    };
    return of(res);
  }

  /**
   * 全軸要約画像取得メソッド
   */
  public fetchAllAssetsDiagnosisImages(machineID: string): Observable<HttpImageInfoList> {
    return of(null);
  }

  /**
   * 単一軸の最新画像取得メソッド
   */
  public fetchLatestImage(machineID: string, assetKey: string): Observable<HttpImageURL> {
    return of(imageRes);
  }

  /**
   * 単一軸の全時間画像取得メソッド
   */
  public fetchAllTimeDiagnosisImage(machineID: string, assetKey: string): Observable<HttpImageURLs> {
    return of(imagesRes);
  }

  /**
   * 単一軸の指定した時間の画像取得メソッド
   */
  public fetchPastTimeImage(machineID: string, assetKey: string, timeStamp: string): Observable<HttpImageURL> {
    return of(imageRes);
  }

  /**
   * 単一軸の指定したポジションの異常度グラフ取得メソッド
   */
  public fetchPositionAdjustedAnomalyGraph(
    machineID: string,
    assetKey: string,
    position: string,
  ): Observable<HttpImageURL> {
    return of(imageRes);
  }

  /**
   * 単一軸の指定した時間の生データ画像取得メソッド
   */
  public fetchRawCurrentImage(machineID: string, assetKey: string, timeStamp: string): Observable<HttpImageURL> {
    // タイムスタンプはURL用にエンコードする (+ があるため)
    return of(imageRes);
  }

  /**
   * 単一軸の指定した時間、ポジションのFFT画像取得メソッド
   */
  public fetchFrequencyCharacteristicImage(
    machineID: string,
    assetKey: string,
    timeStamp: string,
    position: string,
  ): Observable<HttpImageURL> {
    // タイムスタンプはURL用にエンコードする (+ があるため)
    return of(imageRes);
  }
}

/**
 * 取得日時とポジション格納したHTTPレスポンスの型
 */
export interface HttpTimePosition {
  Result: boolean;
  Alarm: string;
  ErrorMessage: string;
  TimestampList: string[];
  PositionStep: number;
}

/**
 * 画像のURLを格納したHTTPレスポンスの型
 */
export interface HttpImageURL {
  Result: boolean;
  Alarm: string;
  ErrorMessage: string;
  URL: string;
}

/**
 * 画像のURLのリストを格納したHTTPレスポンスの型
 */
export interface HttpImageURLs {
  Result: boolean;
  Alarm: string;
  ErrorMessage: string;
  URLs: string[];
}

/**
 * 画像の情報(AssetKey, URL, AssetType)を格納したHTTPレスポンスの型
 */
export interface HttpImageInfoList {
  Result: boolean;
  Alarm: string;
  ErrorMessage: string;
  ImageInfoList: ImageInfo[];
}

/**
 * AssetKey(軸名)、URL、AssetTypeをまとめた型.
 * Infoとしてまとめて呼ぶ
 */
export interface ImageInfo {
  AssetKey: string;
  URL: string;
  AssetType: string;
}

/**
 * テスト用machine Obj
 */
const testMachine: Machine = {
  MachineID: 'NHX40000000ret',
  MachineModel: 'NHX4000',
  Name: 'MC9YI',
  LatestTimestamp: '2023/07/31 09:59:31',
  MaxAnomaly: 1,
  MachineComment: {
    Comment: 'テストです',
    Author: 'yasugi',
    Timestamp: '20200822T041504+0900',
    UserAccessibility: true,
  },
  Assets: {
    Alarm: {
      LatestStatus: AnomalyStatus.alarmFalse,
      AssetType: null,
      AssemblyUnit: 'ToolSpindle',
      DisplayName: 'Y',
    },
    SP1: {
      LatestStatus: AnomalyStatus.normal,
      AssetType: 'Spindle',
      AssemblyUnit: 'ToolSpindle',
      DisplayName: 'Y',
    },
    X1_1: {
      LatestStatus: AnomalyStatus.normal,
      AssetType: 'FeedAxis',
      AssemblyUnit: 'ToolSpindle',
      DisplayName: 'Y',
    },
    Y1_1: {
      LatestStatus: AnomalyStatus.normal,
      AssetType: 'FeedAxis',
      AssemblyUnit: 'ToolSpindle',
      DisplayName: 'Y',
    },
    Z1_1: {
      LatestStatus: AnomalyStatus.normal,
      AssetType: 'FeedAxis',
      AssemblyUnit: 'ToolSpindle',
      DisplayName: 'Y',
    },
    X1_2: {
      LatestStatus: AnomalyStatus.caution,
      AssetType: 'FeedAxis',
      AssemblyUnit: 'ToolSpindle',
      DisplayName: 'Y',
    },
    Z1_2: {
      LatestStatus: AnomalyStatus.normal,
      AssetType: 'FeedAxis',
      AssemblyUnit: 'ToolSpindle',
      DisplayName: 'Y',
    },
    B1_1: {
      LatestStatus: AnomalyStatus.normal,
      AssetType: 'RotaryAxis',
      AssemblyUnit: 'ToolSpindle',
      DisplayName: 'Y',
    },
  },
  setAsset(): void {
    return;
  },
  setAssetbyObject(): void {
    return;
  },
};

/**
 * テスト用画像
 */
const imageRes: HttpImageURL = {
  Result: true,
  Alarm: '000',
  ErrorMessage: 'OK',
  URL: 'hogehoge',
};

const imagesRes: HttpImageURLs = {
  Result: true,
  Alarm: '000',
  ErrorMessage: 'OK',
  URLs: ['hogehoge', 'fugafuga'],
};
