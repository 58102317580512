// ---------------------------------------------------------------------
// <copyright file="frequency-characteristic-image.component.ts" company="WALC Inc."
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Machine } from 'src/app/model/machine';
import { parseDate } from 'src/app/model/timestamp-converter';
import { MachineService, HttpImageURL } from 'src/app/service/machine.service';
import { notFoundImageURL } from 'src/app/static/image';
import { tooltipImgPath } from './tooltip-props.json';
import { TranslateService } from '@ngx-translate/core';

/**
 * 指定した機械、軸、データ取得時刻(Timestamp)、軸の位置(position)に対応する、電流データの周波数特性(FFT)画像を表示するコンポーネント\
 * SingleAssetDiagnosisコンポーネント上に置かれる。
 */
@Component({
  selector: 'app-frequency-characteristic-image',
  templateUrl: './frequency-characteristic-image.component.html',
  styleUrls: ['../../style/image-style.css', './frequency-characteristic-image.component.css'],
})
export class FrequencyCharacteristicImageComponent implements OnInit {
  @Input() selectedMachine: Machine;
  @Input() selectedAssetKey: string;
  @Input() selectedTimestamp: string;
  @Input() selectedPosition: string;

  // TranslateServiceから取得する値たち
  public localeID: string = '';
  public positionLabel: string = 'Position'; // positionは軸によって time angleに変わる。

  public displayURL: string = notFoundImageURL; // 表示する画像のURL。表示の順序の関係でnotFoundImageで初期化する
  public displayTimestamp: Date = undefined; // 画面表示用のタイムスタンプ
  public errorMessage: string = undefined;

  // Infoのtooltipの表示内容を読み込む
  public tooltipMessages: string[] = [];
  public tooltipImgPath: string = tooltipImgPath;

  // onDestroy時にsubscribe解除をするためのオブジェクト。subscribe前にpipe内で takeUntil(this.unsubscribe$) とすれば良い。
  private unsubscribe$ = new Subject();

  // ローディングスピナー表示用のフラグ
  public isLoading: boolean = true; // 初期状態で表示させ、取得できたら止める

  constructor(
    private machineService: MachineService,
    private translateService: TranslateService,
  ) {}

  /**
   * 別コンポーネントでのTimestamp, Positionの変化を、SingleAssetDiganosisコンポーネント経由で検知し、画像の取得を行う。
   * 変化時に両方の値が存在する時のみ画像を取得する。
   */
  ngOnChanges(): void {
    // Inputの画像取得に必要な4種が全て揃っていたらfetchを行う。
    // 画面初期化時に順番にinputされてくるが、揃わなないうちに処理が始まるのを防ぐ
    if (
      this.selectedMachine &&
      this.selectedAssetKey &&
      this.selectedTimestamp &&
      (this.selectedPosition !== null || undefined)
    ) {
      this.setImageURL(
        this.selectedMachine?.MachineID,
        this.selectedAssetKey,
        this.selectedTimestamp,
        this.selectedPosition,
      );
      this.displayTimestamp = parseDate(this.selectedTimestamp);
      this.setPositionLabel(this.selectedAssetKey, this.selectedMachine);
    }
  }

  ngOnInit(): void {
    // localeIDをngx-tranlateから読み取る
    // stereamなので言語の変化に応じて自動で更新される
    this.translateService
      .stream('localeID')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (localeID: string) => {
          this.localeID = localeID;
        },
        (error) => {
          console.log(error);
          this.errorMessage = error.message;
        },
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  /**
   * ポジションラベル(position, time, angle)を切り替える
   * @param assetKey
   * @param machine
   */
  private setPositionLabel(assetKey: string, machine: Machine): void {
    // translateサービスにアクセスするキーの作成
    const translateKey = this.machineService.getPositonKeyforTranslate(assetKey, machine);

    // ポジションラベルを更新する
    // 言語切り替え時は自動で更新される。machineやasssetKey変更時はonChange経由で叩かれる。
    this.translateService
      .stream(translateKey)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (positionLabel: string) => {
          this.positionLabel = positionLabel;
        },
        (error) => {
          // この分岐はユニットテスト範囲外
          console.log(error);
          this.errorMessage = error.message;
        },
      );
  }

  /**
   * 引数に対応するFFT画像を、APIから取得し、displayURLに格納するメソッド
   * @param machineID 機番
   * @param assetKey 軸名
   * @param timestamp AllSampleAnomalyImageコンポーネントで選択されたタイムスタンプ
   * @param position AllSampleAnomalyImageコンポーネントで選択されたポジション
   */
  private setImageURL(machineID: string, assetKey: string, timestamp: string, position: string): void {
    // nullがある場合subscribeのエラーが発生し、notFound画像を生成する
    this.isLoading = true; // ローディングスピナー表示

    this.machineService
      .fetchFrequencyCharacteristicImage(machineID, assetKey, timestamp, position)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: HttpImageURL) => {
          this.displayURL = response.URL || notFoundImageURL;
          // 正常に処理できたのでerrorMessageを削除する
          this.errorMessage = undefined;
          this.isLoading = false; // ローディングスピナー非表示
        },
        (error) => {
          console.log(error);
          // エラーメッセージを画面に表示するために格納
          this.errorMessage = error.message;
          this.displayURL = notFoundImageURL;
          this.isLoading = false; // ローディングスピナー非表示
        },
      );
  }

  /**
   * 表示画像のCSSクラスを返すメソッド
   * not-foundかどうかで判断する。
   * @returns CSSクラスのstring
   */
  public displayImageStyle(): string {
    if (this.displayURL === notFoundImageURL) {
      return 'img-not-found';
    } else {
      return 'img-found-100';
    }
  }
}
