// ---------------------------------------------------------------------
// <copyright file=machine-info-parent.ts company=WALC Inc.
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

/**
 * 診断詳細、全軸要約、アラームなど、機械情報画面の親の識別子
 */
export enum MachineInfoParent {
  singleAsset,
  allAssets,
  alarm,
  default,
}
