// ---------------------------------------------------------------------
// <copyright file="app.module.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';

/* import AmplifyUIAngularModule  */
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { Amplify } from 'aws-amplify';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { ToolbarComponent } from './component-parts/toolbar/toolbar.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { FooterComponent } from './components/footer/footer.component';
import { MachineListComponent } from './components/machine-list/machine-list.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { MachineAlarmComponent } from './components/machine-alarm/machine-alarm.component';
import { AnomalyIconComponent } from './component-parts/anomaly-icon/anomaly-icon.component';
import { AlarmFilterComponent } from './component-parts/alarm-filter/alarm-filter.component';
import { LoginComponent } from './components/login/login.component';

import { AuthGuard } from './guard/auth.guard';
import { RoleGuard } from './guard/role.guard';

import { HttpService } from './service/http.service';
import { MachineService } from './service/machine.service';
import { CognitoService } from './service/cognito.service';
import { FloorService } from './service/floor.service';
import { RouteProcessService } from './service/route-process.service';

// Mock用サービス
import { HttpServiceMock } from './service-mock/http.service';
import { MachineServiceMock } from './service-mock/machine.service';
import { CognitoServiceMock } from './service-mock/cognito.service';
import { FloorServiceMock } from './service-mock/floor.service';
import { RouteProcessServiceMock } from './service-mock/route-process.service';

import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { BaseComponent } from './components/base/base.component';
import { PaginationComponent } from './component-parts/pagination/pagination.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AccountComponent } from './components/account/account.component';
import { PlantComponent } from './components/plant/plant.component';
import { AdvertisementComponent } from './components/advertisement/advertisement.component';
import { FloorHeaderComponent } from './component-parts/floor-header/floor-header.component';
import { LatestAnomalyImageComponent } from './component-parts/latest-anomaly-image/latest-anomaly-image.component';
import { AllSampleAnomalyImageComponent } from './component-parts/all-sample-anomaly-image/all-sample-anomaly-image.component';
import { PositionAdjustedAnomalyGraphComponent } from './component-parts/position-adjusted-anomaly-graph/position-adjusted-anomaly-graph.component';
import { RawCurrentImageComponent } from './component-parts/raw-current-image/raw-current-image.component';
import { SingleAssetDiagnosisComponent } from './components/single-asset-diagnosis/single-asset-diagnosis.component';
import { AllAssetsDiagnosisComponent } from './components/all-assets-diagnosis/all-assets-diagnosis.component';
import { FrequencyCharacteristicImageComponent } from './component-parts/frequency-characteristic-image/frequency-characteristic-image.component';
import { MachineListSidebarComponent } from './component-parts/machine-list-sidebar/machine-list-sidebar.component';
import { PastAnomalyImageComponent } from './component-parts/past-anomaly-image/past-anomaly-image.component';
import { ProgressSpinnerComponent } from './component-parts/progress-spinner/progress-spinner.component';
import { ProgressBarComponent } from './component-parts/progress-bar/progress-bar.component';
import { TimestampPositionSlidebarComponent } from './component-parts/timestamp-position-slidebar/timestamp-position-slidebar.component';
import { MachineInfoHeaderComponent } from './component-parts/machine-info-header/machine-info-header.component';
import { SummaryImagesContainerComponent } from './component-parts/summary-images-container/summary-images-container.component';
import { SubmitSuccessComponent } from './components/submit-success/submit-success.component';
import { ErrorComponent } from './components/error/error.component';
import { MachineCommentComponent } from './components/machine-comment/machine-comment.component';
import { AdminTopComponent } from './components/admin-top/admin-top.component';
import { PostValidationComponent } from './components/post-validation/post-validation.component';
import { ImgTooltipDirective } from './directive/img-tooltip.directive';
import { ImgTooltipComponent } from './component-parts/img-tooltip/img-tooltip.component';
import { registerLocaleData } from '@angular/common';
import localeJa from '@angular/common/locales/ja';
import localeDe from '@angular/common/locales/de';
import { LanguageButtonComponent } from './component-parts/language-button/language-button.component';

// Cognitoのconfig
Amplify.configure({
  Auth: {
    region: environment.region,
    userPoolId: environment.userPoolId,
    userPoolWebClientId: environment.userPoolWebClientId,
    authenticationFlowType: 'USER_SRP_AUTH',
    oauth: {
      domain: environment.userPoolDomain,
      scope: ['profile', 'openid'],
      redirectSignIn: environment.ssoCallBackUrl,
      redirectSignOut: environment.ssoSignOutUrl,
      responseType: 'token', // or 'code', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

// 使う可能性のあるロケールはここで読み込むこと。やらないとDateパイプの表示変更ができなくなる
registerLocaleData(localeJa);
registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    FooterComponent,
    MachineListComponent,
    MachineAlarmComponent,
    AnomalyIconComponent,
    AlarmFilterComponent,
    LoginComponent,
    ChangePasswordComponent,
    BaseComponent,
    PaginationComponent,
    ResetPasswordComponent,
    AccountComponent,
    PlantComponent,
    AdvertisementComponent,
    FloorHeaderComponent,
    LatestAnomalyImageComponent,
    AllSampleAnomalyImageComponent,
    PositionAdjustedAnomalyGraphComponent,
    RawCurrentImageComponent,
    SingleAssetDiagnosisComponent,
    AllAssetsDiagnosisComponent,
    FrequencyCharacteristicImageComponent,
    MachineListSidebarComponent,
    PastAnomalyImageComponent,
    ProgressSpinnerComponent,
    ProgressBarComponent,
    TimestampPositionSlidebarComponent,
    MachineInfoHeaderComponent,
    SummaryImagesContainerComponent,
    SubmitSuccessComponent,
    ErrorComponent,
    MachineCommentComponent,
    AdminTopComponent,
    PostValidationComponent,
    ImgTooltipDirective,
    ImgTooltipComponent,
    LanguageButtonComponent,
  ],
  imports: [
    HighchartsChartModule,
    ChartsModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AmplifyUIAngularModule,
    NgbModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatSliderModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    MatListModule,
    MatMenuModule,
    MatSelectModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    // no need to place any providers due to the `providedIn` flag...
    HttpService,
    MachineService,
    CognitoService,
    FloorService,
    RouteProcessService,
    HttpServiceMock,
    MachineServiceMock,
    CognitoServiceMock,
    FloorServiceMock,
    RouteProcessServiceMock,
    AuthGuard,
    RoleGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
