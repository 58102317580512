// ---------------------------------------------------------------------
// <copyright file=machine-list-sidebar.component.ts company=WALC Inc.
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Machine } from 'src/app/model/machine';

/**
 * 機械詳細画面のサイドバーコンポーネント
 * Inputで受け取ったMachineListを表示する
 */
@Component({
  selector: 'app-machine-list-sidebar',
  templateUrl: './machine-list-sidebar.component.html',
  styleUrls: ['./machine-list-sidebar.component.css'],
})
export class MachineListSidebarComponent implements OnInit {
  @Input() machineList: Machine[];
  @Input() selectedMachine: Machine;

  // 親(この場合machineInfoコンポーネント)にmachineの変化を伝える
  @Output() machineEvent = new EventEmitter<Machine>();

  constructor() {}

  ngOnChanges(): void {}

  ngOnInit(): void {}

  /**
   * 機械選択時に行う処理。
   */
  public selectMachine(machine: Machine) {
    // このメソッドは親に選択機械の情報を渡すのみ。
    // ルーティングは親とサービスで行う
    this.machineEvent.emit(machine);
  }

  public style(machine: Machine): string {
    // オブジェクトのまま比較すると、参照が異なるようでtrueにならない
    if (machine.MachineID === this.selectedMachine.MachineID) {
      // 厳密等価でもいける？
      return 'selected';
    } else {
      return '';
    }
  }
}
