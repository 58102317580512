// ---------------------------------------------------------------------
// <copyright file="floor-header.component.ts" company="WALC Inc."
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Account } from 'src/app/model/account';
import { Plant } from 'src/app/model/plant';
import { User } from 'src/app/model/user-info';
import { CognitoService } from 'src/app/service/cognito.service';
import { RouteProcessService } from 'src/app/service/route-process.service';

/**
 * MachineListコンポーネント、MachineInfoコンポーネントの上部に表示するヘッダコンポーネント\
 * ロゴ、アカウント名、工場名、ユーザ情報のプルダウンを載せている
 */
@Component({
  selector: 'app-floor-header',
  templateUrl: './floor-header.component.html',
  styleUrls: ['./floor-header.component.css'],
})
export class FloorHeaderComponent implements OnInit {
  @Input() selectedAccount: Account;
  @Input() selectedPlant: Plant;
  @Input() isLoading: boolean;

  // cognitoからsubscribeしたユーザ情報を格納する
  public user: User;

  // onDestroy時にsubscribe解除をするためのオブジェクト。subscribe前にpipe内で takeUntil(this.unsubscribe$) とすれば良い。
  private unsubscribe$ = new Subject();

  constructor(
    private routeProcessService: RouteProcessService,
    public cognito: CognitoService,
  ) {}

  ngOnInit(): void {
    // userObservableをSubscribeする。
    // ログインしていない場合エラーが出るが、ハンドリングは必要ない。
    this.cognito
      .userObservable()
      .pipe(takeUntil(this.unsubscribe$)) // onDestroy時に購読を解除するトリガー
      .subscribe(
        (user: User) => {
          this.user = user;
        },
        (error) => {
          console.log(error);
        },
      );
  }

  public onClickMenu(): void {
    this.routeProcessService.navigateMachineList();
  }

  /**
   * ログアウト時にrouteProcessService経由で処理を行う
   */
  public onClickLogout(): void {
    this.routeProcessService.logout();
  }
}
