// ---------------------------------------------------------------------
// <copyright file=admin-role.ts company=WALC Inc.
// (C) 2023 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { environment } from '../../environments/environment';

/**
 * adminRoleをenvironmentから取得し、リストに整形して返す。
 * 整形処理を各コンポーネント、サービスがしないようにここにまとめる。
 */
export const adminRole: string[] = environment.adminRole.split(',');
