// ---------------------------------------------------------------------
// <copyright file=language-button.component.ts company=WALC Inc.
// (C) 2024 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Locale } from 'src/app/model/locale';
import { locales } from './locales.json';
import { MatSelectChange } from '@angular/material/select';

/**
 * 言語切り替えボタンコンポーネント。\
 * toolbarに配置する。
 */
@Component({
  selector: 'app-language-button',
  templateUrl: './language-button.component.html',
  styleUrls: ['./language-button.component.css'],
})
export class LanguageButtonComponent implements OnInit {
  public currentLang: string;
  // translateServiveを使う場合はcurrentLangのみでいいが、ロケールオブジェクトにまとめた方が管理しやすい
  public currentLocale: Locale = new Locale();
  // 同階層のjsonのlocale情報を初期値で読み込んでおく
  public locales: Locale[] = locales;

  constructor(public translateService: TranslateService) {}

  ngOnInit(): void {
    // translateサービスから現在の言語(ルートコンポーネントで指定)を取り出す。
    // ルートコンポーネントでの指定はこのonInitより必ず先に走る
    this.currentLang = this.translateService.currentLang;
    // htmlとやりとり用のLocaleのset
    this.currentLocale = this.locales.find((locale: Locale) => locale.code === this.currentLang);
  }

  /**
   * 言語を切り替えた際に走るメソッド\
   * @param event MatSelectChangeイベント。valueにstring型で選択した言語の識別子(src/assets/i18n/xx.jsonのxxに該当)が格納されている。
   */
  public changeLang(event: MatSelectChange) {
    // currentLangはObserevableではないため、streamで更新できないのでここで直接更新する
    this.currentLang = event.value;
    this.currentLocale = this.locales.find((locale: Locale) => locale.code === this.currentLang);

    // 永続化のためlocalStorageも更新する
    localStorage.setItem('lang', this.currentLang);

    // translateサービスを更新。
    // Translateパイプやstreamを使用したコンポーネントが更新される。
    this.translateService.use(this.currentLang);
  }
}
