// ---------------------------------------------------------------------
// <copyright file="toolbar.component.ts" company="DMG MORI B.U.G.CO.,LTD."
// (C) 2021 DMG MORI B.U.G. CO.,LTD. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Account } from 'src/app/model/account';
import { Plant } from 'src/app/model/plant';
import { User } from 'src/app/model/user-info';
import { CognitoService } from 'src/app/service/cognito.service';
import { RouteProcessService } from 'src/app/service/route-process.service';

/**
 * 画面最上部のツールバーコンポーネント\
 *
 */
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css'],
})
export class ToolbarComponent implements OnInit {
  // Inputは必須ではない
  @Input() selectedAccount: Account = null;
  @Input() selectedPlant: Plant = null;

  // routerから取得するpath
  private currentUrl: string = undefined;

  // cognitoからsubscribeしたユーザ情報、ログイン情報を格納する
  public user: User;
  public loggedIn: boolean = false; // HTMLのバックボタン表示判定に使うのでpublic

  // ログアウト処理中にロードバーを出すためのトリガー
  public nowSignOutProcess = false;

  // onDestroy時にsubscribe解除をするためのオブジェクト。subscribe前にpipe内で takeUntil(this.unsubscribe$) とすれば良い。
  private unsubscribe$ = new Subject();

  constructor(
    private router: Router,
    private routeProcessService: RouteProcessService,
    public cognito: CognitoService,
  ) {}

  ngOnInit(): void {
    // URLを取得
    this.currentUrl = this.router.url;

    // userObservableをSubscribeする。
    // ログインしていない場合エラーが出るが、ハンドリングは必要ない。
    this.cognito
      .userObservable()
      .pipe(takeUntil(this.unsubscribe$)) // onDestroy時に購読を解除するトリガー
      .subscribe(
        (user: User) => {
          this.loggedIn = true;
          this.user = user;
        },
        (error) => {
          // エラーが来た場合はログインしていない。
          this.loggedIn = false;
          console.log(error);
        },
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(); // unsubscribe$に値を発行するとtakeUntilが起動し、購読を止める
  }

  /**
   * 戻るボタンのdisabledを指定
   */
  public isDisabledPreviousButton(): boolean {
    if (this.currentUrl?.includes('login')) {
      return true;
    }
    if (this.currentUrl?.includes('admin-top')) {
      return true;
    }
    if (this.currentUrl?.includes('plant') && !this.user?.isAdmin) {
      return true;
    }

    return false;
  }

  /**
   * 戻るボタン押下時に実行されるメソッドメソッド
   */
  public onClickPreviousPage(): void {
    switch (true) {
      case this.currentUrl.includes('account'):
        if (this.user.isAdmin) {
          this.routeProcessService.navigateAdminTop();
        }
        break;
      case this.currentUrl.includes('machine-comment'):
        if (this.user.isAdmin) {
          this.routeProcessService.navigateAdminTop();
        }
        break;
      case this.currentUrl.includes('plant'):
        if (this.canChangeAccount) {
          // 管理者のみAccountListに戻れる
          this.routeProcessService.navigateAccountList();
        }
        break;
      case this.currentUrl.includes('dashboard'):
        this.routeProcessService.navigatePlantList();
        break;
      case this.currentUrl.includes('singleasset'):
        this.routeProcessService.navigateMachineList();
        break;
      case this.currentUrl.includes('allassets'):
        this.routeProcessService.navigateMachineList();
        break;
      case this.currentUrl.includes('alarm'):
        this.routeProcessService.navigateMachineList();
        break;
      default:
        break;
    }
  }

  /**
   * アカウントリスト画面へ遷移するメソッド
   */
  public onClickAccountList(): void {
    // テンプレート上で管理者権限を見てはいるが、ここでも権限確認をする
    if (this.canChangeAccount()) {
      this.routeProcessService.navigateAccountList();
    }
  }

  /**
   * アカウントの変更が許可されているか(管理者かどうか)を確認するメソッド
   */
  public canChangeAccount(): boolean {
    if (this.user?.isAdmin) {
      return true;
    }
    return false;
  }

  /**
   * アカウントリスト画面へ遷移するメソッド
   */
  public onClickplantList(): void {
    // テンプレート上で管理者権限を見てはいるが、ここでも権限確認をする
    this.routeProcessService.navigatePlantList();
  }

  /**
   * Plantボタンを表示するかどうかを返すメソッド
   */
  public isVisiblePlantButton(): boolean {
    if (!this.loggedIn || !this.selectedAccount) {
      return false;
    } else return true;
  }

  /**
   * 選択した工場の機械一覧画面へ遷移するメソッド
   * 今のところ使わない
   */
  public onClickSelectPlant(plant: Plant): void {
    this.routeProcessService.navigateMachineList(plant);
  }

  /**
   * ログアウト時にrouteProcessService経由で処理を行う
   */
  public onClickLogout(): void {
    // ローディングバーは画面遷移で消えるが、明示的に消せてないのが気になる。
    // サービスとどう繋げるか...
    this.nowSignOutProcess = true;
    this.routeProcessService.logout();
  }
}
