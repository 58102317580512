<app-toolbar class="toolbar-only" [selectedAccount]="commonProperties.account"></app-toolbar>
<!-- ローディングバー -->
<app-progress-bar class="progress-bar" [isLoading]="isLoading" *ngIf="isLoading"></app-progress-bar>

<div class="d-flex flex-column justify-content-center align-items-center">
  <!-- タイトル(見分けるため) -->
  <span class="title-text">{{ 'plant.title' | translate }}</span>

  <!-- リスト各項目 -->
  <div class="row" *ngFor="let plant of plantList">
    <button class="btn-block" (click)="onClickSelectPlant(plant)">
      {{ plant.PlantName }}
    </button>
  </div>
</div>
